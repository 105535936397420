import { CheckboxField, LabelPositions } from "@aws-amplify/ui-react";

import "./Checkbox.scss";

export const ConnectOneCheckbox = ({
  label,
  name,
  value,
  handleChange,
  required = false,
  readOnly = false,
  alwaysEditable = false, // Used for cases where checkbox determines rest of readme
  labelPosition = "top",
}: {
  label: string;
  name: string;
  value: boolean;
  handleChange: any;
  required?: boolean;
  readOnly?: boolean;
  alwaysEditable?: boolean;
  labelPosition?: LabelPositions;
}) => {
  const isDisabled = () => {
    if (alwaysEditable) return false;

    return readOnly;
  };

  return (
    <div className="checkbox-container">
      <CheckboxField
        label={label}
        name={name}
        checked={value}
        value={`${value}`}
        onChange={handleChange}
        required={required}
        disabled={isDisabled()}
        size="large"
        labelPosition={labelPosition}
        data-testid={`${label}-checkbox`}
      />
    </div>
  );
};
