export const vehicleActivityReportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          Vehicle Activity report can be searched to know if communication was
          established with the Provider for any date range.
        </li>
        <li>Communication Report has two fields:</li>
        <ul>
          <li>
            <b>No Communication Established:</b> This indicates, that though the
            vehicle is successfully activated, there was no communication
            established between the vehicle and the provider for the chosen date
            range. In such a case, the <b>“Latest reported Activity”</b> column
            in the report provides the time stamp of the last vehicle trace
            received from the Provider outside the date range mentioned in the
            search options. This can also be verified with the time stamp on the
            latest trace in <b>“Vehicle Trace Report”</b>.
          </li>
          <li>
            <b>Communication Established</b>: This indicates that the activated
            vehicles have communication established in the specified date range.{" "}
          </li>
        </ul>
      </ul>
    </>
  );
};
