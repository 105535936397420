export const DrawControlConfig = {
  displayControlsDefault: false,
  controls: {
    polygon: true,
    line_string: true,
    trash: true,
    point: true,
  },
  userProperties: true,
  defaultMode: "simple_select",
};
