import { useState } from "react";

import { useIsMobile } from "../../../hooks";
import {
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneTextBox,
} from "../../../components";

import "./Messages.scss";

export const MessageEditForm = ({
  data,
  messageTypes,
  handleChange,
  handleGoBack,
  handleSubmit,
  isLoading,
}: {
  data: any;
  messageTypes: any;
  handleChange: any;
  handleGoBack: any;
  handleSubmit: any;
  isLoading: boolean;
}) => {
  const isMobile = useIsMobile();

  const {
    displayName,
    messageType,
    messageTitle,
    messageBody,
    button1,
    button2,
    autoClose,
    description,
    warnBeforeEdit,
  } = data;

  const [displayButton2, setDisplayButton2] = useState(button2.value !== null);

  const saveDisabled =
    !messageType.value ||
    !messageTitle.value ||
    !messageBody.value ||
    !button1.value ||
    !autoClose.value ||
    (displayButton2 && !button2.value);

  return (
    <div className="messages-edit-form-container">
      <div className="edit-form-headers">
        <span />
        <div
          className="x-container"
          onClick={handleGoBack}
          data-testid="close-button"
        >
          <span aria-hidden="true">&times;</span>
        </div>
        <h1>Update {displayName.value}</h1>
      </div>

      <div className="edit-form-inputs">
        <div className="type-dropdown-container">
          <ConnectOneDropdown
            label="Type"
            name="messageType"
            value={messageType.value}
            handleChange={handleChange}
            required={true}
            options={messageTypes}
          />
        </div>
        <div className="title-form-container">
          <ConnectOneInput
            label="Title"
            placeholder="Message Title"
            name="messageTitle"
            type="text"
            value={messageTitle.value}
            handleChange={handleChange}
            required={true}
          />
        </div>
        <div className="body-form-container">
          <ConnectOneTextBox
            label="Body"
            placeholder="Message Body"
            name="messageBody"
            type="textarea"
            value={messageBody.value}
            handleChange={handleChange}
            required={true}
          />
        </div>
        <div
          className={
            displayButton2
              ? "buttons-form-container"
              : "single-button-form-container"
          }
        >
          <ConnectOneInput
            label="Button 1"
            placeholder="Button 1"
            name="button1"
            type="text"
            value={button1.value}
            handleChange={handleChange}
            required={true}
          />
          {displayButton2 && (
            <ConnectOneInput
              label="Button 2"
              placeholder="Button 2"
              name="button2"
              type="text"
              value={button2.value}
              handleChange={handleChange}
              required={displayButton2}
            />
          )}
        </div>
        <div className="button-toggle-container">
          <ConnectOneButton
            label={`${displayButton2 ? "Remove" : "Add"} Button 2`}
            handleClick={() => {
              if (displayButton2) {
                if (
                  window.confirm(
                    "WARNING: By removing the second button only one centered button will display in the vehicle. If the second button was used for programmatic logic, that logic will no longer work.\nDo you want to continue?"
                  )
                ) {
                  setDisplayButton2(false);
                  handleChange({
                    target: { name: "button2", value: null },
                  });
                }
              } else {
                setDisplayButton2(true);
              }
            }}
            color={displayButton2 ? "red" : "blue"}
          />
        </div>
        <div className="auto-close-form-container">
          <ConnectOneInput
            label="Auto Close"
            placeholder="Auto Close"
            type="number"
            name="autoClose"
            min="0"
            value={autoClose.value}
            handleChange={handleChange}
            required={true}
          />
        </div>
        <div className="description-form-container">
          <ConnectOneTextBox
            label="Description"
            placeholder="Add Message Description Here..."
            name="description"
            type="text"
            value={description.value}
            handleChange={handleChange}
            rows={2}
          />
        </div>
      </div>

      <div className="button-container">
        {isMobile && (
          <div className="close-button">
            <ConnectOneButton label="Close" handleClick={handleGoBack} />
          </div>
        )}
        <div className="save-button">
          <ConnectOneButton
            label="Save Changes"
            handleClick={() => {
              // Check if warnBeforeEdit is true and show a confirmation dialog
              if (!!warnBeforeEdit?.value && warnBeforeEdit.value === true) {
                if (
                  window.confirm(
                    "WARNING: You are making a change that may impact programmatic logic used in the system.\nDo you want to continue?"
                  )
                ) {
                  handleSubmit();
                } else {
                  return;
                }
              } else {
                handleSubmit();
              }
            }}
            isLoading={isLoading}
            disabled={saveDisabled}
          />
        </div>
      </div>
    </div>
  );
};
