import "./Logo.scss";

const VerraLogoWithTextPNG = require("./VerraLogoWithText.png");

export const VerraLogoWithText = () => {
  return (
    <div className="logo" data-testid="verra-logo-with-text">
      <img src={VerraLogoWithTextPNG} alt="Verra-Mobility-Logo" />
      <h1>ConnectOne</h1>
    </div>
  );
};
