import axios from "axios";

import { getUserAccessToken, refreshCognitoToken } from "../../utils";

export const getVehicleMessagesAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/vehicleCommunications`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
        data: params,
    });
    return response.data;
  } catch (err) {
    console.log("Error getting data providers: ", err.message);
  }
};
