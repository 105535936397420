import { useVinLookup } from "../../../hooks";
import { VinLookupHeaders } from "../../../assets";
import {
  ConnectOneButton,
  ConnectOneClickableTable,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneModal,
} from "../..";

import "./VinLookup.scss";

export const VinLookup = ({
  handleSelect,
  handleClose,
  setVinByLookup,
}: {
  handleSelect: any;
  handleClose: any;
  setVinByLookup: any;
}) => {
  const {
    searchData,
    responseData,
    isViewing,
    isSearching,
    isLoading,
    sortedValue,
    sortAscending,
    handleGoBack,
    handleSorting,
    getSearchResponse,
    handleFilterInputChange,
  } = useVinLookup();

  return (
    <ConnectOneModal
      title={"VIN Lookup"}
      handleClose={isViewing ? handleGoBack : handleClose}
      userCanGoBack={isViewing}
    >
      {isSearching && (
        <div className="advanced-search-container">
          <h2>Vehicle Data</h2>
          <div className="inputs-container">
            {/* Nickname */}
            <ConnectOneInput
              handleChange={handleFilterInputChange}
              label={"Nickname"}
              type={"text"}
              name={"nickName"}
              placeholder={"Nickname"}
              value={searchData.nickName.value}
            />
            {/* Last 4 of VIN */}
            <ConnectOneInput
              handleChange={handleFilterInputChange}
              label={"Last 4 of VIN"}
              type={"text"}
              name={"vin"}
              placeholder={"Last 4 of VIN"}
              value={searchData.vin.value}
              maxLength={4}
            />
            {/* Plate */}
            <ConnectOneInput
              handleChange={handleFilterInputChange}
              label={"Plate"}
              type={"text"}
              name={"licensePlate"}
              placeholder={"Plate"}
              value={searchData.licensePlate.value}
            />
            {/* State */}
            <ConnectOneDropdown
              handleChange={handleFilterInputChange}
              label={"State"}
              name={"licensePlateState"}
              options={searchData.licensePlateState.options}
              value={searchData.licensePlateState.value}
            />
          </div>
          <div className="inputs-container second-row-inputs">
            {/* Make */}
            <ConnectOneInput
              handleChange={handleFilterInputChange}
              label={"Make"}
              name={"make"}
              placeholder={"Make"}
              type={"text"}
              value={searchData.make.value}
            />
            {/* Device Type */}
            <ConnectOneInput
              handleChange={handleFilterInputChange}
              label={"Device Type"}
              name={"deviceType"}
              placeholder={"Device Type"}
              type={"text"}
              value={searchData.deviceType.value}
            />
            {/* Status */}
            <ConnectOneDropdown
              handleChange={handleFilterInputChange}
              label={"Status"}
              name={"status"}
              options={searchData.status.options}
              value={searchData.status.value}
            />
            {/* Equity */}
            <ConnectOneDropdown
              handleChange={handleFilterInputChange}
              label={"Equity"}
              name={"equity"}
              options={searchData.equity.options}
              value={searchData.equity.value}
            />
          </div>

          <h2>Provider Data</h2>
          <div className="inputs-container">
            {/* Provider */}
            <ConnectOneDropdown
              handleChange={handleFilterInputChange}
              label={"Provider"}
              name={"provider"}
              options={searchData.provider.options}
              value={searchData.provider.value}
            />
          </div>

          <div className="advanced-search-submit">
            <ConnectOneButton
              label={"Search"}
              handleClick={getSearchResponse}
              disabled={false}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}

      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          headers={VinLookupHeaders}
          sortGridData={handleSorting}
          handleGoBack={handleGoBack}
          handleRowSelection={(row: any) => {
            handleSelect({ name: "vin", value: row["vin"] });
            setVinByLookup(true);
          }}
          tableHasHeaders={false}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
        />
      )}
    </ConnectOneModal>
  );
};
