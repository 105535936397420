import {
  ConnectOneModal,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
} from "../../../components";

import "./Vehicles.scss";

export const VehiclesAdvancedSearch = ({
  title,
  searchData,
  isLoading,
  handleChange,
  handleSubmit,
  handleClose,
}: {
  title: string;
  searchData: any;
  isLoading: boolean;
  handleChange: any;
  handleSubmit: any;
  handleClose: any;
}) => {
  return (
    <ConnectOneModal title={title} handleClose={handleClose}>
      <div className="advanced-search-container">
        <h2>Vehicle Data</h2>
        <div className="inputs-container">
          {/* Nickname */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Nickname"}
            type={"text"}
            name={"nickName"}
            placeholder={"Nickname"}
            value={searchData.nickName.value}
          />
          {/* Last 4 of VIN */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Last 4 of VIN"}
            type={"text"}
            name={"vin"}
            placeholder={"Last 4 of VIN"}
            value={searchData.vin.value}
            maxLength={4}
          />
          {/* Plate */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Plate"}
            type={"text"}
            name={"licensePlate"}
            placeholder={"Plate"}
            value={searchData.licensePlate.value}
          />
          {/* State */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"State"}
            name={"licensePlateState"}
            options={searchData.licensePlateState.options}
            value={searchData.licensePlateState.value}
          />
        </div>
        <div className="inputs-container second-row-inputs">
          {/* Make */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Make"}
            name={"make"}
            placeholder={"Make"}
            type={"text"}
            value={searchData.make.value}
          />
          {/* Device Type */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Device Type"}
            name={"deviceType"}
            placeholder={"Device Type"}
            type={"text"}
            value={searchData.deviceType.value}
          />
          {/* Status */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Status"}
            name={"status"}
            options={searchData.status.options}
            value={searchData.status.value}
          />
          {/* Equity */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Equity"}
            name={"equity"}
            options={searchData.equity.options}
            value={searchData.equity.value}
          />
        </div>

        <h2>Provider Data</h2>
        <div className="inputs-container">
          {/* Provider */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Provider"}
            name={"provider"}
            options={searchData.provider.options}
            value={searchData.provider.value}
          />
        </div>

        <div className="advanced-search-submit">
          <ConnectOneButton
            label={"Search"}
            handleClick={handleSubmit}
            disabled={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ConnectOneModal>
  );
};
