import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUserAccessToken } from "../utils";

type LocationHierarchy = {};

type EventActionsData = {
  label: string;
  name: string;
};
type LocationSubTypeData = {
  label: string;
  name: string;
  locationSubTypeIncludesRates: boolean;
};

export interface LocationHierarchyState {
  isLoading: boolean;
  hasError: boolean;
  locationHierarchyData: LocationHierarchy;
  eventActionsData: EventActionsData[];
  locationSubTypeData: LocationSubTypeData[];
}

const initialState: LocationHierarchyState = {
  isLoading: false,
  hasError: false,
  locationHierarchyData: {} as LocationHierarchy,
  eventActionsData: [] as EventActionsData[],
  locationSubTypeData: [] as LocationSubTypeData[],
};

export const getLocationHierarchy = createAsyncThunk(
  "locationHierarchy/getLocationHierarchy",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/LocationHierarchy`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const locationHierarchySlice = createSlice({
  name: "locationHierarchySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationHierarchy.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getLocationHierarchy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;

        state.locationHierarchyData = new Map(
          action.payload?.data.map((locationHierarchy: any) => [
            locationHierarchy.locationType,
            locationHierarchy,
          ])
        );

        state.eventActionsData = action.payload?.data.flatMap(
          (locationHierarchy: any) =>
            locationHierarchy?.subTypeEventActions?.flatMap(
              (subTypeEventActions: any) =>
                subTypeEventActions?.locationEventActions?.flatMap(
                  (eventAction: any) =>
                    eventAction?.actions?.map((action: any) => ({
                      value: action?.action,
                      label: action?.name,
                    }))
                )
            )
        );

        state.locationSubTypeData = action.payload?.data.flatMap(
          (locationHierarchy: any) => {
            return locationHierarchy?.subTypeEventActions?.flatMap(
              (locationSubType: any) => {
                return {
                  value: locationSubType?.locationSubType,
                  label: locationSubType?.locationSubTypeName,
                  locationSubTypeIncludesRates:
                    locationSubType?.locationSubTypeIncludesRates,
                };
              }
            );
          }
        );
      })
      .addCase(getLocationHierarchy.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default locationHierarchySlice.reducer;
