// Custom TripCard is a presentational component that will render a single trip and have an onClick event to view traces with custom range controls
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { ConnectOneInput } from "../inputs";

import "./Trips.scss";

export const CustomRangeTripCard = ({
  trip,
  customRange,
  setCustomRange,
  selectedTrip = undefined,
  onTripSelect = () => {},
}: {
  trip: any;
  customRange: {
    startDateTime: string;
    endDateTime: string;
    initialDateTime: string;
    minDateTime: string;
    maxDateTime: string;
  };
  setCustomRange: any;
  selectedTrip?: string;
  onTripSelect?: (trip: any) => void;
}) => {
  return (
    <div
      className={`custom-range-trip-card ${
        selectedTrip === trip.id ? "selected-trip-card" : ""
      }`}
      data-testid={`trip-card-${trip.id}`}
    >
      <h2>Custom Range</h2>
      <div className="custom-range-container">
        <div className="custom-range-time-inputs-container">
          <ConnectOneInput
            name="startTime"
            label="Start Time"
            type="datetime-local"
            value={customRange.startDateTime}
            handleChange={(e: any) =>
              setCustomRange({ ...customRange, startDateTime: e.target.value })
            }
            min={customRange.minDateTime}
            max={customRange.initialDateTime}
          />
          <ConnectOneInput
            name="endTime"
            label="End Time"
            type="datetime-local"
            value={customRange.endDateTime}
            handleChange={(e: any) =>
              setCustomRange({ ...customRange, endDateTime: e.target.value })
            }
            min={customRange.initialDateTime}
            max={customRange.maxDateTime}
          />
        </div>
        <button
          onClick={() => onTripSelect(trip)}
          data-testid={`view-traces-button-${trip.id}`}
          className="customRangeTrip-btn"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};
