import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUserAccessToken } from "../utils";

type ClassType = {
  label: string;
  value: string;
  name: string;
};

export interface ClassTypesState {
  isLoading: boolean;
  hasError: boolean;
  classTypesData: ClassType[];
}

const initialState: ClassTypesState = {
  isLoading: false,
  hasError: false,
  classTypesData: [],
};

export const getClassTypes = createAsyncThunk(
  "classTypes/getClassTypes",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/classTypes`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const classTypesSlice = createSlice({
  name: "classTypesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClassTypes.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getClassTypes.fulfilled, (state, action) => {
        state.classTypesData = action?.payload
          ?.map((e: any) => ({
            ...e,
            label: e.name,
            value: e._id,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getClassTypes.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default classTypesSlice.reducer;
