export const vehicleTracesReportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          Vehicle Trace report provides a complete list of all the GNSS (Global
          Navigation Satellite Systems) traces received by our ConnectOne system
          as recorded by the Provider that are sent from the Vehicle.
        </li>
        <li>
          The frequency at which vehicle traces are received depends on the
          <b>Tolling speed- Fast/Slow mode</b>
        </li>
        <ul>
          <li>
            <b>Fast Mode:</b>
            <ul>
              <li>
                If the vehicle is within 1.5mile radius of an Active geofence,
                tolling speed is switched to Fast mode.
              </li>
              <li>
                If Provider is Stellantis, the vehicle traces are received at
                every 3 seconds interval when in Fast mode.
              </li>
            </ul>
          </li>
          <li>
            <b>Slow Mode:</b>
            <ul>
              <li>
                If no Active geofences are located within 1.5mile radius of the
                Vehicle, then the tolling speed is switched to slow mode.
              </li>
              <li>
                If Provider is Stellantis, the vehicle traces are received at
                1min interval when in Slow Mode.
              </li>
            </ul>
          </li>
        </ul>
        <li>Stellantis Provider:</li>
        <ul>
          <li>
            We have two types of Devices currently supported by Stellantis :
            VP4r and R1
          </li>
        </ul>
        <li>Note:</li>
        <ul>
          <li>
            <b>GNSS</b> Vehicle Stream data consists of Device
            Name/Lat-Long/Horizontal
            Accuracy/Speedometer_KPH/Odometer/Course/Altitude/IgnitionStatus/PassengerPresent/ChasisType/TrailerType/TollingSpeed/TimeStamp/ConnectionStatus/FirmwareVersion
          </li>
          <li>
            Each Vehicle trace received is assigned with a unique “ID” in our
            ConnectOne system and has the data source as “Provider(Stellantis)”.
          </li>
          <li>
            If the consecutive vehicle trace received from the Provider has same
            Lat/Long co-ordinates, then the ConnectOne system discards the
            vehicle trace received and processes the next one. Furthermore, if
            there are multiple vehicle traces received with the same Lat/Long
            co-ordinates, only the latest vehicle trace for that Lat/Long
            co-ordinates received will be displayed on the map for the
            respective trip.
          </li>
          <li>
            Dead Reckoning is enabled in specific device types when Vehicle is
            unable to send GNSS data every 3 seconds. Dead Reckoning traces are
            inserted by our ConnectOne system and has DataSource as <b>“DRP”</b>
            .
          </li>
          <li>
            If “Store and Forward” is enabled, in case of lost connectivity
            between the vehicle and the Provider, the vehicle continues to store
            GNSS traces and is flushed out to the Provider at a later point when
            the connection is established. These traces with a latency of
            greater than 10seconds between two consecutive vehicle traces are
            highlighted in RED on the Vehicle trace report and also represented
            in red on the map.
          </li>
          <li>
            By clicking the button in “Action” column, you can view that
            respective trace on the map along with the rest of the trip in a new
            tab.
          </li>
        </ul>
      </ul>
    </>
  );
};
