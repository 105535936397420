import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";

import { getCognitoCredentials } from "./cognitoService";

export const getLambdaClient = async () => {
  const credentials = await getCognitoCredentials();

  return new LambdaClient({
    region: process.env.REACT_APP_region,
    credentials: credentials,
  });
};

export const invokeLambdaFunction = async ({
  functionName,
  username,
}: {
  functionName: string;
  username: string;
}) => {
  const client = await getLambdaClient();

  try {
    const params = {
      FunctionName: functionName,
      Payload: JSON.stringify({
        username: username,
      }),
    };
    return await client.send(new InvokeCommand(params));
  } catch (err) {
    console.log("Err while getting Lambda Functions", err);
    return null;
  }
};
