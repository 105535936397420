/* eslint-disable array-callback-return */
import { useSelector } from "react-redux";
import { TabItem } from "@aws-amplify/ui-react";

import {
  GeofenceDetailsEditForm,
  ConnectOneOverlay,
  RatesManagement,
  ConnectOneButton,
  ConnectOneTabs,
  DistanceRatesManagement,
} from "../../../components";
import { useIsMobile } from "../../../hooks";
import { handleViewOnGoogle } from "../../../utils";

import "./Locations.scss";

export const LocationsEditForm = ({
  geofence,
  isLoading,
  handleChange,
  handleGoBack,
  handleSubmit,
  handleInsertScheduleOrRate,
  handleScheduleOrRateChange,
  handleRemoveScheduleOrRate,
  handleScheduleSave,
  handleUndoScheduleChanges,
  handleRemoveExclusiveDate,
  handleViewOnMap = undefined,
}: {
  geofence: any;
  isLoading: boolean;
  handleChange: any;
  handleGoBack: any;
  handleSubmit: any;
  handleInsertScheduleOrRate: any;
  handleScheduleOrRateChange: any;
  handleRemoveScheduleOrRate: any;
  handleScheduleSave: any;
  handleUndoScheduleChanges: any;
  handleRemoveExclusiveDate: any;
  handleViewOnMap?: any;
}) => {
  const isMobile = useIsMobile();
  const { locationType, locationSubType } = geofence?.properties;

  // Split schedules into two objects, one with exclusive dates and one without
  const schedulesCount = geofence?.schedules?.filter(
    (schedule: any) => !schedule.exclusiveDates
  )?.length;
  const exclusiveDatesSchedulesCount = geofence?.schedules?.filter(
    (schedule: any) => schedule.exclusiveDates
  )?.length;

  // Get location hierarchy data from redux to determine dynamic visibility of inputs
  const locationHierarchy = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  // Find if the location subtype includes rates from the locationHierarchy
  const locationIncludesRates = locationHierarchy
    .get(locationType)
    ?.subTypeEventActions.find(
      (subType: any) => subType.locationSubType === locationSubType
    )?.locationSubTypeIncludesRates;

  // Pass rates to render the rates management component
  const ratesManagement = ({ scheduleType }: { scheduleType: string }) => {
    // If the location has entry points, render the distance rates management component
    if (!!geofence?.entryPoints?.length) {
      // Creating a map of entry points to schedules to improve performance
      const entryPointMap = new Map();
      // Storing the schedule id in order to update and delete
      geofence?.schedules?.forEach((schedule: any, scheduleIndex: number) => {
        const { startOfSegmentLocationId } = schedule;
        if (entryPointMap.has(startOfSegmentLocationId)) {
          const existingSchedule = entryPointMap.get(startOfSegmentLocationId);
          const mergedSchedules = [
            ...existingSchedule,
            { ...schedule, scheduleIndex },
          ];
          entryPointMap.set(startOfSegmentLocationId, mergedSchedules);
        } else {
          entryPointMap.set(startOfSegmentLocationId, [
            { ...schedule, scheduleIndex },
          ]);
        }
      });

      return (
        <div>
          {geofence?.entryPoints.map((entryPoint: any, index: number) => {
            return (
              <div key={`distance-schedule-edit-form-${index}`}>
                <DistanceRatesManagement
                  geofence={geofence}
                  entryPoint={entryPoint}
                  entryPointSchedules={entryPointMap.get(entryPoint.id)}
                  handleInsertScheduleOrRate={handleInsertScheduleOrRate}
                  handleScheduleOrRateChange={handleScheduleOrRateChange}
                  handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
                  handleScheduleSave={handleScheduleSave}
                  handleUndoScheduleChanges={handleUndoScheduleChanges}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <>
        <div>
          {geofence?.schedules?.map((schedule: any, index: number) => {
            // If scheduleType is exclusiveDates, only render schedules with exclusiveDates
            if (scheduleType === "exclusiveDates" && !schedule.exclusiveDates) {
              return null;
            }
            // If scheduleType is default, only render schedules without exclusiveDates
            if (scheduleType === "default" && schedule.exclusiveDates) {
              return null;
            }
            return (
              <div key={`schedule-edit-form-${index}`}>
                <RatesManagement
                  schedule={schedule}
                  index={index}
                  handleInsertScheduleOrRate={handleInsertScheduleOrRate}
                  handleScheduleOrRateChange={handleScheduleOrRateChange}
                  handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
                  handleRemoveExclusiveDate={handleRemoveExclusiveDate}
                  handleScheduleSave={handleScheduleSave}
                  handleUndoScheduleChanges={handleUndoScheduleChanges}
                />
              </div>
            );
          })}
        </div>
        <div className="button-container">
          <ConnectOneButton
            label="Add New Rate"
            handleClick={() =>
              handleInsertScheduleOrRate({
                objectType: "schedule",
                scheduleType,
              })
            }
          />
        </div>
      </>
    );
  };

  return (
    <>
      {!isMobile && <ConnectOneOverlay />}
      <div
        className={`edit-locations-container ${
          isMobile ? "mobile-edit-locations-container" : null
        }`}
        data-testid={`location-edit-form`}
      >
        <div className="edit-form-headers-container">
          <div
            className="x-container"
            onClick={() => handleGoBack("editing")}
            data-testid="search-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </div>
          <div className="edit-form-headers">
            <h1>{geofence.name}</h1>
            <div className="map-links">
              {handleViewOnMap && (
                <h2 onClick={() => handleViewOnMap(geofence?._id)}>
                  View on ConnectOne Map
                </h2>
              )}
              <h2
                onClick={() => handleViewOnGoogle(geofence?.properties?.center)}
              >
                View on Google Maps
              </h2>
            </div>
          </div>
          <span />
        </div>

        <div className="edit-forms">
          <ConnectOneTabs>
            <TabItem title="Details" onClick={handleUndoScheduleChanges}>
              <GeofenceDetailsEditForm
                geofence={geofence}
                isLoading={isLoading}
                handlePropertyChange={handleChange}
                handleSubmitChange={handleSubmit}
              />
            </TabItem>
            {/* If geofence type does not allow rates tab, do not render */}
            {locationIncludesRates && (
              <TabItem
                title={`Rates (${schedulesCount})`}
                onClick={handleUndoScheduleChanges}
              >
                <div className="rates-edit-container">
                  {ratesManagement({ scheduleType: "default" })}
                </div>
              </TabItem>
            )}
            {/* Only if rates are allowed and if the type is TOLL we should show exclusive dates rates */}
            {locationIncludesRates &&
              locationType === "TOLL" &&
              locationSubType === "POINT" && (
                <TabItem
                  title={`Exclusive Dates (${exclusiveDatesSchedulesCount})`}
                  onClick={handleUndoScheduleChanges}
                >
                  <div className="rates-edit-container">
                    {ratesManagement({ scheduleType: "exclusiveDates" })}
                  </div>
                </TabItem>
              )}
          </ConnectOneTabs>
        </div>
      </div>
    </>
  );
};
