import axios from "axios";

import { getUserAccessToken, refreshCognitoToken } from "../../utils";

export const getDataProvidersAPI = async () => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/dataProviders`,
      method: "GET",
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data.map((provider: any) => ({
      label: provider.name,
      value: provider.name,
    }));
  } catch (err) {
    console.log("Error getting data providers: ", err.message);
  }
};
