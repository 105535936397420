export const reportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          Agency Rates report provides the toll rates published by the toll
          agency for a specific plaza.
        </li>
        <li>
          These rates are categorized based on Distance/Point based tolls and is
          what the Agency charges for each toll transactions.
        </li>
        <li>
          This report shows Rates schedule and based on the no of passenger
          count(where HOT/HOV is applicable) specific rates are applied.
        </li>
      </ul>
      <p>Below are the rate categories we support in ConnectOne:</p>
      <table className="report-table">
        <tr>
          <th>Rate Type</th>
          <th>Description</th>
          <th>Notes</th>
        </tr>
        <tr>
          <td>Transponder Rate</td>
          <td>
            The agency published rate for toll transactions that are recorded on
            a transponder at a given toll location.
          </td>
          <td>
            Usually, the lowest rates available to the public. But this rate
            could be lower based on is additional discounts apply for home-base
            transponder, frequent user, veteran, EV etc.
          </td>
        </tr>
        <tr>
          <td>Plate Rate</td>
          <td>
            The agency published rate for toll transactions that are recorded on
            a vehicle plate using photo/video at a given toll location.
          </td>
          <td>
            This rate is higher than the transponder rates for the public, but
            some agencies may not have a plate rate as they don’t have
            video/photo toll capabilities.{" "}
          </td>
        </tr>
        <tr>
          <td>Cash Rate</td>
          <td>
            The agency published rate for toll transactions that a customer will
            need to pay at a given toll location, when there is no transponder
            or plate reads.
          </td>
          <td>
            Generally, the highest rate for toll transactions, but some agencies
            may not have this rate as they are ETC (electronic toll collection)
            agencies or ORT (open road tolling) with no cash booths.
          </td>
        </tr>
        <tr>
          <td>Negotiated Rate</td>
          <td>
            Special rates that Verra has negotiated with the Toll Agencies for
            all toll transactions from that agency.
          </td>
          <td></td>
        </tr>
        <tr>
          <td>HOV Rate</td>
          <td>
            The agency published rate for toll transactions that a customer will
            be charged for driving in a HOV Toll (HOT) / Express Lanes. In
            certain agencies, it could also be the discounted rate for toll
            transactions that a customer will be charged, when there are more
            than 1 passengers in the vehicle at a given toll location.
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Equity rate</td>
          <td>
            It is the discounted rate for toll transactions that a customer will
            be charged at a given toll location, when the customer is qualified
            and registered for Equity Program from a toll agency .
          </td>
          <td></td>
        </tr>
      </table>
    </>
  );
};
