import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";

import { ConnectOneButton } from "../buttons";
import { handleViewOnGoogle } from "../../utils";
import { vehicleTraceHeaderNames } from "../../assets";

import "./VehicleTrace.scss";

export const ConnectOneVehicleTraceModal = ({
  tracePoint,
  handleMouseDown,
}: {
  tracePoint: any;
  handleMouseDown: (e: React.MouseEvent) => void;
}) => {
  const formattedDate = new Date(tracePoint.timeStampLocal).toLocaleString(
    "en-US",
    { timeZone: tracePoint.timeZone }
  );

  // For each key value, need to format the data
  // This data should be copied to the users clipboard the same as it shows in the modal
  // The data should be formatted in a way that the user can paste it into a CSV file
  const formatDataToCopy = () => {
    let data = "";

    vehicleTraceHeaderNames.forEach((header: any) => {
      if (
        tracePoint[header.value] === undefined ||
        tracePoint[header.value] === null
      )
        return null;
      return (data += `${header.name}\t${tracePoint[header.value]}\n`);
    });

    navigator.clipboard.writeText(data);
  };

  return (
    <>
      <div className="popup-headers">
        <FontAwesomeIcon
          icon={faArrowsUpDownLeftRight}
          size="lg"
          className="drag-icon"
          onMouseDown={handleMouseDown}
          style={{ maxWidth: 20 }}
        />
        <h1>{formattedDate}</h1>
      </div>
      <div className="popup-grid-container">
        {vehicleTraceHeaderNames.map((header: any) => {
          if (
            tracePoint[header.value] === undefined ||
            tracePoint[header.value] === null
          )
            return null;
          return (
            <div className="grid-row">
              <p className="left-column">{header.name}</p>
              <p className="right-column">{`${tracePoint[header.value]}`}</p>
            </div>
          );
        })}
      </div>
      <div className="popup-buttons">
        <ConnectOneButton
          label="View on Google Maps"
          handleClick={() =>
            handleViewOnGoogle([tracePoint.longitude, tracePoint.latitude])
          }
        />
        <ConnectOneButton label="Copy Data" handleClick={formatDataToCopy} />
      </div>
    </>
  );
};
