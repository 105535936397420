import { useRef } from "react";

import { uploadToS3 } from "../../../utils";
import { ConnectOneButton } from "../button";

export const ConnectOneUploadButton = ({
  label,
  uploadBucket,
  fileType,
}: {
  label?: string;
  uploadBucket: string;
  fileType: string;
}) => {
  const inputFile = useRef(null);

  const handleUploadClick = () => {
    inputFile.current.click();
  };

  const onFileSelect = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      await uploadToS3({
        fileBody: file,
        fileName: file.name,
        bucketName: uploadBucket,
      });
      window.alert("Upload successful");
      event.target.files = null;
    }
  };

  return (
    <>
      {/* Ref a hidden input that opens the file explorer for the user */}
      <input
        type="file"
        id="fileUpload"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={onFileSelect}
        // Clear the target value so user can upload same file
        onClick={(event: any) => {
          event.target.value = null;
        }}
        accept={fileType}
      />
      <ConnectOneButton label={label} handleClick={handleUploadClick} />
    </>
  );
};
