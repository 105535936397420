import { Loader } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../hooks";

import "./Loader.scss";

export const ConnectOneLoader = () => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`${isMobile ? "mobile-loader" : null} loader`}
      data-testid="loader-overlay"
    >
      <Loader filledColor="#3956ff" />
    </div>
  );
};
