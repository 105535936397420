import { useCallback, useEffect, useState } from "react";
import { useControl } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
// @ts-ignore - This is a custom package that is not typed
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";

import {
  RotateButton,
  RotateScaleMode,
  GeneratePolyMode,
  ScaleButton,
  CircleButton,
} from "./customControls";
import { DrawControlConfig } from "./DrawControls.config";
import { DrawControlsStyles } from "./DrawControlsStyles";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

// Setting the modes available for the draw controls
let modes = {
  ...MapboxDraw.modes,
};

modes = {
  ...MapboxDrawGeodesic.enable(modes),
  // Include the custom modes
  RotateScaleMode: RotateScaleMode,
  GeneratePolyMode: GeneratePolyMode,
};

const draw = new MapboxDraw({
  ...DrawControlConfig,
  modes,
});

type DrawControlProps = {
  onUpdate: (data: any) => void;
  onSelect: (data: any) => void;
  onGenerate: (lon: number, lat: number) => void;
  onMeasure: (data: any) => void;
  onMeasureEnd: () => void;
  onGeofenceDelete: (features: any, message: string) => void;
  clearRulerLine: boolean;
  geofences: any;
  userIsReadOnly: boolean;
  resetView: boolean;
  setResetView: (value: boolean) => void;
  dynamicFillColorsByType: any[];
  dynamicFillColorsByStatus: any[];
  setMapZoom: (value: number) => void;
};

export const DrawControls = ({
  onUpdate,
  onSelect,
  onGenerate,
  onMeasure,
  onMeasureEnd,
  onGeofenceDelete,
  clearRulerLine,
  geofences,
  userIsReadOnly,
  resetView,
  setResetView,
  dynamicFillColorsByType,
  dynamicFillColorsByStatus,
  setMapZoom,
}: DrawControlProps): any => {
  // State to keep track of the map load status
  const [mapLoaded, setMapLoaded] = useState(false);

  // State to keep track of the line id for the ruler tool
  const [lineId, setLineId] = useState(null);
  const [linePoints, setLinePoints] = useState([]);

  // State to keep track of the current draw mode
  const [currentDrawMode, setCurrentDrawMode] = useState("simple_select");

  // Set the draw controls to the map
  // @ts-ignore - This is a custom package that is not typed
  draw.options.controls = DrawControlConfig.controls;

  // Set the dynamic colors for the geofences
  useEffect(() => {
    if (dynamicFillColorsByType.length && dynamicFillColorsByStatus.length) {
      // @ts-ignore - This is a custom package that is not typed
      draw.options.styles = DrawControlsStyles.concat([
        {
          id: "gl-draw-polygon-fill-inactive.cold",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
          ],
          paint: {
            "fill-color": [
              "match",
              ["get", "user_status"], // Check status of the geofence first, if it's inactive or removed use grey color
              ...dynamicFillColorsByStatus,
              [
                "match",
                ["get", "user_locationType"],
                ...dynamicFillColorsByType,
                "#3956ff", // default
              ],
            ],
            "fill-opacity": 0.4,
          },
          source: "mapbox-gl-draw-cold",
        },
        {
          id: "gl-draw-polygon-fill-inactive.hot",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
          ],
          paint: {
            "fill-color": [
              "match",
              ["get", "user_status"], // Check status of the geofence first, if it's inactive or removed use grey color
              ...dynamicFillColorsByStatus,
              [
                "match",
                ["get", "user_locationType"],
                ...dynamicFillColorsByType,
                "#3956ff", // default
              ],
            ],
            "fill-opacity": 0.4,
          },
          source: "mapbox-gl-draw-hot",
        },
      ]);
    }
  }, [dynamicFillColorsByType, dynamicFillColorsByStatus]);

  useEffect(() => {
    // If the map is loaded and we have geofences, draw them
    if (mapLoaded) {
      // If resetView is true, we want to trigger a redraw of the geofences
      if (geofences?.features || resetView) {
        draw.deleteAll(); // Clear the map of all geofences

        // If we have geofences, draw them
        if (geofences?.features) {
          draw.add(geofences); // Add the geofences to the map
        }

        setResetView(false); // Reset the view
        draw.changeMode("simple_select"); // Set the default mode to simple select

        // Find the tempGeofence if it exists
        // Set the tempGeofence as the selected feature in draw when user creates a new geofence but has not saved yet
        // Without this, the user would not be able to see the geofence they are creating
        // Cases such as cloning and polygon generation will trigger this
        const tempGeofence = geofences?.features?.find(
          (geofence: any) => geofence.isTemp
        );
        if (tempGeofence) {
          draw.changeMode("direct_select", {
            featureId: tempGeofence.id,
          });
        }
      } else {
        draw.deleteAll();
      }
    }
  }, [mapLoaded, geofences, userIsReadOnly, resetView, setResetView]);

  // Stop user from drawing more than 2 points for ruler use
  useEffect(() => {
    if (linePoints.length === 2) {
      draw.changeMode("simple_select");
    }
  }, [lineId, linePoints]);

  // Clear the ruler line when the user is done
  useEffect(() => {
    if (clearRulerLine) {
      draw.delete(lineId);
    }
  }, [clearRulerLine, lineId]);

  // Clear the line points when the user is done
  useEffect(() => {
    if (currentDrawMode !== "draw_line_string" && linePoints.length === 1) {
      onMeasureEnd();
      setLinePoints([]);
    }
    if (linePoints.length > 1) {
      setLinePoints([]);
    }
  }, [currentDrawMode, linePoints.length, onMeasureEnd]);

  // Create or update of a geofence
  const updateArea = useCallback(
    (e: any) => {
      if (e.features[0].geometry.type === "Polygon") {
        onUpdate(e);
      }

      if (e.features[0].geometry.type === "LineString") {
        onMeasure({
          startPoint: e.features[0].geometry.coordinates[0],
          endPoint: e.features[0].geometry.coordinates[1],
        });
      }
    },
    [onMeasure, onUpdate]
  );

  // Selection change to another geofence
  const selectionChange = useCallback(
    (e: any) => {
      if (e?.features[0]?.geometry?.type === "Polygon") {
        onSelect(e);
      }
    },
    [onSelect]
  );

  // Generate a polygon using a point on the map
  const generatePolygon = useCallback(
    (lon: number, lat: number) => onGenerate(lon, lat),
    [onGenerate]
  );

  // Utility that extracts the properties given from mapbox and renames them to the correct key
  const extractAndRenameProperties = (properties: any) => {
    const newObj: any = {};

    for (const key in properties) {
      if (key.startsWith("user_")) {
        const newKey = key?.replace("user_", "");
        newObj[newKey] = properties[key];
      }
    }

    return newObj;
  };

  useControl(({ map }) => {
    map.on("load", () => {
      setMapLoaded(true);
      if (!userIsReadOnly) {
        // Add our custom controls buttons on the map
        const rotateButton = new RotateButton(draw);
        const scaleButton = new ScaleButton(draw);
        const circleButton = new CircleButton(draw);

        map.addControl(rotateButton);
        map.addControl(scaleButton);
        map.addControl(circleButton);

        // Set title for user to hover and see what the tool is
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon")
          .setAttribute("title", "Polygon Tool");
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point")
          .setAttribute("title", "Generate Polygon Tool");
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash")
          .setAttribute("title", "Delete Tool");
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line")
          .setAttribute("title", "Measure Tool");

        // @ts-ignore - This is a custom package that is not typed
        document.getElementsByClassName("custom-ctrl-rotate")[0].style.display =
          "none";
        // @ts-ignore - This is a custom package that is not typed
        document.getElementsByClassName("custom-ctrl-scale")[0].style.display =
          "none";
      }
    });

    map.on("zoom", () => {
      const zoom = map.getZoom();
      setMapZoom(zoom);
    });

    // Event listeners for the draw controls
    map.on("draw.create", updateArea);
    map.on("draw.update", updateArea);
    map.on("draw.selectionchange", selectionChange);

    // Custom events when the user clicks and certain draw modes are active
    map.on("click", (e) => {
      if (draw.getMode() === "GeneratePolyMode") {
        generatePolygon(e.lngLat.lng, e.lngLat.lat);
        draw.changeMode("simple_select");
      }

      if (draw.getMode() === "draw_line_string") {
        setLinePoints((prevLinePoints) => [
          ...prevLinePoints,
          [{ lon: e.lngLat.lng, lat: e.lngLat.lat }],
        ]);
      }
    });

    map.on("draw.modechange", (e) => {
      setCurrentDrawMode(draw.getMode());

      if (draw.getMode() === "draw_point") {
        draw.changeMode("GeneratePolyMode");
        map.getCanvas().style.cursor = "crosshair";
      }
    });

    // Customizing the draw controls functionality
    if (userIsReadOnly) {
      // Hide map draw controls from read only user
      // @ts-ignore - This is a custom package that is not typed
      draw.options.controls = {};
      // Remove functionality for read only user, user should only be able to open the modal to view details of geofence
      // @ts-ignore - This is a custom package that is not typed
      modes.simple_select.clickOnFeature = (state: any, e: any) => {
        // Extract properties and remove the `user_` prefix that mapbox adds
        const properties = extractAndRenameProperties(
          e.featureTarget.properties
        );

        onSelect({
          features: [
            {
              ...e.featureTarget,
              properties: {
                ...e.featureTarget.properties,
                ...properties,
                schedules: JSON.parse(properties.schedules),
                eventActions: JSON.parse(properties.eventActions),
              },
              geometry: e.featureTarget._geometry,
            },
          ],
        });
      };
    } else {
      // @ts-ignore - This is a custom package that is not typed
      modes.simple_select.clickOnFeature = (state: any, e: any) => {
        // If the feature is locked, we want to select it and not allow the user to edit the shape
        if (e.featureTarget.properties.user_lockStatus) {
          // Extract properties and remove the `user_` prefix that mapbox adds
          const properties = extractAndRenameProperties(
            e.featureTarget.properties
          );

          return onSelect({
            features: [
              {
                ...e.featureTarget,
                properties: {
                  ...e.featureTarget.properties,
                  ...properties,
                  schedules: JSON.parse(properties.schedules),
                  eventActions: JSON.parse(properties.eventActions),
                },
                geometry: e.featureTarget._geometry,
              },
            ],
          });
        } else {
          return draw.changeMode("direct_select", {
            featureId: e.featureTarget.properties.id,
          });
        }
      };
    }

    // Custom functionality of mouse movement for the measure tool
    modes.draw_line_string.onMouseMove = (state, e) => {
      if (draw.getMode() === "draw_line_string") {
        // Keep old functionality
        state.line.updateCoordinate(
          state.currentVertexPosition,
          e.lngLat.lng,
          e.lngLat.lat
        );

        // Added functionality
        // If we already set the start point, we want to start tracking the users mouse movement
        if (state.currentVertexPosition === 1) {
          setLineId(state.line.id);
          onMeasure({
            startPoint: state.line.getCoordinate(0),
            endPoint: [e.lngLat.lng, e.lngLat.lat],
          });
        }
      }
    };

    // Custom trash functionality
    modes.direct_select.onTrash = (state) => {
      // If the polygon is only 3 points, we want to delete the entire feature
      if (state.feature.coordinates[0].length === 3) {
        onGeofenceDelete(
          { features: [state.feature] },
          `Removal of third point will delete this geofence. Confirm deletion of geofence: ${state.feature.properties.name}?`
        );
        return;
      }

      // Keep old functionality
      state.selectedCoordPaths
        .sort((a: string, b: string) =>
          b.localeCompare(a, "en", { numeric: true })
        )
        .forEach((id: string) => state.feature.removeCoordinate(id));
      state.selectedCoordPaths = [];

      // Need to update the feature if user needs to remove a point
      onUpdate({
        features: [
          {
            ...state.feature,
            type: "Feature",

            geometry: {
              coordinates: [
                [
                  ...state.feature.coordinates[0],
                  state.feature.coordinates[0][0],
                ],
              ],
              type: "Polygon",
            },
          },
        ],
      });

      if (state.feature.isValid() === false) {
        // @ts-ignore - This is a custom package that is not typed
        draw.deleteFeature([state.featureId]);
      }
    };

    return draw;
  });

  return null;
};
