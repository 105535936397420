import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { addDays, format } from "date-fns";

import { formatDateToAPI } from "../../../utils";
import { getVehicleStatusesAPI } from "../../../axios";
import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTable, ConnectOneTableControls } from "../../../components";
import { vehicleStatusesReportDescription } from "./VehicleStatusesReportDescription";

import "./VehicleStatuses.scss";

export const VehicleStatusesReport = () => {
  const isMobile = useIsMobile();
  const { classTypesData } = useSelector((state: any) => state.classTypes);
  const { vehicleStatusData } = useSelector((state: any) => state.vehicles);

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({ isMobile, reportType: "vehicleStatuses" });

  const classTypeMap = new Map(
    classTypesData.map((classType: { _id: string; name: string }) => [
      classType._id,
      classType.name,
    ])
  );

  const vehicleStatusesMap = new Map(
    vehicleStatusData.map((vehicleStatus: { _id: string; name: string }) => [
      vehicleStatus._id,
      vehicleStatus.name,
    ])
  );

  const [filterValues, setFilterValues] = useState({
    status: {
      type: "autocomplete",
      label: "Status",
      value: "",
      options: [] as any,
      placeholderText: "Select a Status",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Updated From",
      value: `${format(addDays(new Date(), -2), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Updated To",
      value: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
    },
  });

  useEffect(() => {
    setFilterValues((prevFilterValues: any) => ({
      ...prevFilterValues,
      status: {
        ...prevFilterValues.status,
        options: [{ label: "Any", value: "" }, ...vehicleStatusData],
      },
    }));
  }, [vehicleStatusData]);

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value
  );

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getVehicleStatusesAPI({
        status: !!filterValues.status.value
          ? filterValues.status.value
          : undefined,
        dateFrom: formatDateToAPI(filterValues.dateFrom.value),
        dateTo: formatDateToAPI(filterValues.dateTo.value),
      });
      setReportData(
        res.map((vehicle: any) => {
          const className = classTypeMap.get(vehicle.class);
          const statusName = vehicleStatusesMap.get(vehicle.status);
          return {
            ...vehicle,
            className,
            statusName,
          };
        })
      );
    } catch (err) {
      setReportData([] as any[]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="vehicle-statuses-report-description">
        <ConnectOneTableControls
          fullData={reportData}
          dataIsLoading={isLoading}
          totalPages={totalPages}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleFilterSubmit={getTableData}
          submitButtonDisabled={!submitButtonEnabled}
          fileName={exportFileName("VEHICLE_STATUS_REPORT", filterValues)}
          exportData={exportData}
          reportTitle="Vehicle Status Report"
          reportDescription={vehicleStatusesReportDescription()}
        />
        <ConnectOneTable
          data={dataToDisplay}
          headers={headers}
          caption="Vehicle Statuses"
          sortGridData={handleSorting}
          sortAscending={sortAscending}
          sortedValue={sortedValue}
        />
      </div>
    </>
  );
};
