import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faFloppyDisk,
  faRotateLeft,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { ConnectOneButton } from "../..";
import { useIsMobile } from "../../../hooks";
import { LockedIcon, UnlockedIcon } from "../../../assets";
import { GeofenceModalReadOnly } from "./GeofenceModalReadOnly";
import { GeofenceDetailsEditForm } from "./GeofenceDetailsEditForm";
import { LocationsEditForm } from "../../../pages";
import { handleViewOnGoogle } from "../../../utils";

import "./GeofenceModal.scss";

export const GeofenceModal = ({
  geofence = {},
  isLoading,
  userIsReadOnly,
  userIsAdmin,
  handlePropertyChange,
  handleSubmit,
  handleDelete,
  handleUndo,
  onClose,
  onEditViewClose,
  isSavingGeofence,
  changesMade,
  handleScheduleOrRateChange,
  handleInsertScheduleOrRate,
  handleRemoveScheduleOrRate,
  handleScheduleSave,
  handleUndoScheduleChanges,
  handleLockGeofence,
  handleGeofenceClone,
  handleRemoveExclusiveDate,
  handleGetAllEntryPoints,
}: {
  geofence?: any;
  isLoading: boolean;
  userIsReadOnly: boolean;
  userIsAdmin: boolean;
  handlePropertyChange: any;
  handleSubmit: any;
  handleDelete: any;
  handleUndo: any;
  onClose: any;
  onEditViewClose: any;
  isSavingGeofence: boolean;
  changesMade: boolean;
  handleScheduleOrRateChange: any;
  handleInsertScheduleOrRate: any;
  handleRemoveScheduleOrRate: any;
  handleScheduleSave: any;
  handleUndoScheduleChanges: any;
  handleLockGeofence: any;
  handleGeofenceClone: any;
  handleRemoveExclusiveDate: any;
  handleGetAllEntryPoints?: any;
}) => {
  const isMobile = useIsMobile();
  const [isEditing, setIsEditing] = useState(false);
  // If the geofence has no name, we assume it is being created
  const [isCreating, setIsCreating] = useState(!geofence?.name);

  // Handle validation for the submit button
  const requiredValues = ["name", "agencyId", "locationType"];
  const submitEnabled = requiredValues.every((value) => {
    return (
      geofence?.properties[value] && geofence?.properties[value]?.length > 0
    );
  });

  return (
    <div
      className={`${isMobile ? "mobile-geofence-modal" : "geofence-modal"} ${
        isCreating ? "creating-geofence-modal" : null
      }`}
      data-testid={`${geofence?.name || "null"}-modal`}>
      <div className="modal-headers-container">
        <div className="modal-headers">
          <h1
            style={{ height: !!geofence?.name && !isCreating ? null : "40px" }}>
            {geofence?.name ? geofence.name : "Create a New Geofence"}
          </h1>

          {!!geofence?.properties?.center?.length && !isCreating && (
            <h2
              onClick={() => handleViewOnGoogle(geofence?.properties?.center)}>
              View on Google Maps
            </h2>
          )}

          <div className="position-relative">
            {isMobile ? (
              <div
                className="x-container-mobile"
                onClick={() => onClose({ isCreating })}
                data-testid="search-modal-close-btn">
                <FontAwesomeIcon icon={faX} />
              </div>
            ) : (
              <>
                <div className="icons-container">
                  {userIsAdmin && !isCreating ? (
                    <div
                      className="lock-container"
                      onClick={() =>
                        handleLockGeofence(!geofence.properties.lockStatus)
                      }
                      data-testid="lock-icon">
                      {geofence.properties.lockStatus ? (
                        <LockedIcon
                          className="lock-icon"
                          title="This locations coordinates are locked from being edited"
                        />
                      ) : (
                        <UnlockedIcon
                          className="lock-icon"
                          title="Click here to lock the editing of this locations coordinates"
                        />
                      )}
                    </div>
                  ) : (
                    <span />
                  )}
                  <div
                    className="x-container"
                    onClick={() => onClose({ isCreating })}
                    data-testid="search-modal-close-btn">
                    <FontAwesomeIcon icon={faX} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="geofence-form">
        {isCreating ? (
          <GeofenceDetailsEditForm
            geofence={geofence}
            handlePropertyChange={handlePropertyChange}
            handleSubmitChange={handleSubmit}
            isCreating={isCreating}
            isLoading={isLoading}
          />
        ) : (
          <GeofenceModalReadOnly
            geofence={geofence}
            handleGetAllEntryPoints={handleGetAllEntryPoints}
            setIsEditing={setIsEditing}
          />
        )}
      </div>

      {isEditing && (
        <LocationsEditForm
          geofence={geofence}
          isLoading={isLoading}
          handleChange={handlePropertyChange}
          handleGoBack={() => {
            setIsEditing(false);
            onEditViewClose();
          }}
          handleSubmit={handleSubmit}
          handleInsertScheduleOrRate={handleInsertScheduleOrRate}
          handleScheduleOrRateChange={handleScheduleOrRateChange}
          handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
          handleRemoveExclusiveDate={handleRemoveExclusiveDate}
          handleScheduleSave={handleScheduleSave}
          handleUndoScheduleChanges={handleUndoScheduleChanges}
        />
      )}

      {!userIsReadOnly && (
        <>
          {isCreating ? (
            <>
              <div className="create-button-container">
                <ConnectOneButton
                  label="Cancel"
                  handleClick={() => handleUndo({ isCreating })}
                />
                <ConnectOneButton
                  label="Save Location"
                  disabled={!submitEnabled}
                  handleClick={handleSubmit}
                  isLoading={isSavingGeofence}
                  color="white"
                  backgroundColor="#2746f2"
                />
              </div>
            </>
          ) : (
            <>
              <div className="button-container">
                {userIsAdmin && !isMobile && (
                  <ConnectOneButton
                    label="Clone Location"
                    handleClick={async () => {
                      const isCloning = await handleGeofenceClone();
                      if (isCloning) setIsCreating(true);
                    }}
                    icon={faClone}
                  />
                )}
                {!isMobile && (
                  <ConnectOneButton
                    label="Undo Changes"
                    disabled={!changesMade}
                    handleClick={handleUndo}
                    icon={faRotateLeft}
                  />
                )}
              </div>
              <div className="button-container">
                <ConnectOneButton
                  label="Delete Location"
                  handleClick={handleDelete}
                  color={"red"}
                  icon={faTrash}
                />
                {!isMobile && (
                  <ConnectOneButton
                    label="Save Coordinates"
                    disabled={!changesMade}
                    handleClick={handleSubmit}
                    isLoading={isSavingGeofence}
                    icon={faFloppyDisk}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}

      {isMobile && (
        <p onClick={onClose} className="mobile-close-button">
          CLOSE
        </p>
      )}
    </div>
  );
};
