export const messagesFilterState = {
  name: {
    label: "Name",
    value: "",
    name: "name",
    placeholder: "Message Name",
  },
  provider: {
    label: "Provider",
    value: "",
    name: "provider",
  },
  type: {
    label: "Type",
    value: "",
    name: "type",
    type: "dropdown",
    options: [
      { value: "alert", label: "Alert" },
      { value: "caution", label: "Caution" },
      { value: "info", label: "Information" },
    ],
  },
};
