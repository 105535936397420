import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAdmin } from "../../../hooks";
import { AppDispatch } from "../../../store";
import { shapeCSVData } from "../../../utils";
import { VehiclesEditForm } from "./VehiclesEditForm";
import { VehiclesAdvancedSearch } from "./VehiclesAdvancedSearch";
import {
  getAllVehicles,
  getVehiclesByFilter,
  putVehicle,
  insertClassNames,
  insertStatusNames,
} from "../../../redux";
import {
  FileTypes,
  vehicleHeaders,
  vehicleEditState,
  vehicleFilterState,
} from "../../../assets";
import { getDataProvidersAPI } from "../../../axios";
import {
  ConnectOneLoader,
  ConnectOneClickableTable,
} from "../../../components";

import "./Vehicles.scss";

export const AdminControlVehicles = ({ user }: { user?: any }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [overwriteOptionValue, setOverwriteOptionValue] = useState("");
  const [userSearched, setUserSearched] = useState(false);
  const [exportData, setExportData] = useState({
    cellHeaders: null,
    cellData: null,
  });

  const {
    vehiclesData: vehicles,
    rawVehiclesData,
    isLoading: vehiclesLoading,
    statusesLoading,
    vehicleStatusData: vehicleStatuses,
    initialLoad,
  } = useSelector((state: any) => state.vehicles);
  const serviceTypes = useSelector((state: any) => state.services.servicesData);
  const fuelTypes = useSelector((state: any) => state.fuelTypes.fuelTypesData);
  const { classTypesData: classTypes, isLoading: classesLoading } = useSelector(
    (state: any) => state.classTypes
  );

  useEffect(() => {
    if (classTypes?.length) {
      vehicleFilterState.class.options = classTypes;
      vehicleEditState.class.options = [
        { label: "Select a class type", value: "", disabled: true },
        ...classTypes,
      ];
    }
    if (fuelTypes?.length) {
      vehicleFilterState.fuelType.options = fuelTypes;
      vehicleEditState.fuelType.options = [
        { label: "Select a fuel type", value: "", disabled: true },
        ...fuelTypes,
      ];
    }
    if (vehicleStatuses?.length) {
      vehicleFilterState.status.options = vehicleStatuses;
      vehicleEditState.status.options = vehicleStatuses;
    }
    if (serviceTypes?.length) {
      // @ts-ignore
      vehicleEditState.subscriptions = {
        label: "Services",
        name: "subscriptions",
        type: "checkboxes-object",
        value: [] as string[],
        options: serviceTypes.map((serviceType: any) => {
          return {
            label: serviceType.name,
            name: serviceType.name,
          };
        }),
      };
    }
  }, [classTypes, fuelTypes, serviceTypes, vehicleStatuses]);

  const getDataProviders = async () => {
    try {
      const dataProvidersOptions = await getDataProvidersAPI();
      vehicleFilterState.provider.options = dataProvidersOptions;
      vehicleEditState.provider.options = [
        { label: "Select a provider", value: "", disabled: true },
        ...dataProvidersOptions,
      ];
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDataProviders();
  }, []);

  // Any dropdown type should be given an Any option
  Object.entries(vehicleFilterState).forEach(
    ([key, value]: [key: string, value: any]) => {
      if (value.type === "dropdown") {
        // Do not add a second Any option
        if (value?.options[0] && value.options[0].value !== "") {
          value.options = [{ label: "Any", value: "" }, ...value.options];
        }
      }
    }
  );

  const {
    isSearching,
    isEditing,
    isLoading,
    searchData,
    responseData,
    editData,
    isViewing,
    isCreating,
    displayingFilteredData,
    sortedValue,
    sortAscending,
    handleAdd,
    setIsViewing,
    handleGoBack,
    handleFilterInputChange,
    handleEditInputChange,
    setIsLoading,
    setResponseData,
    setIsSearching,
    handleRowSelection,
    handleEditFormSubmit,
    handleCheckboxChange,
    handleResetSearch,
    handleSorting,
  } = useAdmin({
    filterState: vehicleFilterState,
    editState: vehicleEditState,
    handleSubmit: putVehicle,
    user: user,
  });

  useEffect(() => {
    getTableData({ initialLoad: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResponseData(vehicles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResponseData, vehicles]);

  // Insert string name values for user readability
  useEffect(() => {
    if (!vehiclesLoading && !classesLoading && !statusesLoading) {
      dispatch(insertClassNames({ classTypes }));
      dispatch(insertStatusNames({ vehicleStatuses }));
    }
  }, [
    classTypes,
    classesLoading,
    dispatch,
    statusesLoading,
    vehicleStatuses,
    vehiclesLoading,
  ]);

  useEffect(() => {
    const { cellHeaders, cellData } = shapeCSVData(
      vehicleHeaders,
      responseData.map((vehicle: any) => {
        return {
          ...vehicle,
          // Inserting nested object values
          receiveInfotainmentMessages:
            vehicle.communicationPreferences.receiveInfotainmentMessages,
        };
      })
    );

    setExportData({ cellHeaders, cellData });
  }, [responseData]);

  const getTableData = async ({
    initialLoad = false,
  }: {
    initialLoad?: boolean;
  }) => {
    setIsLoading(true);
    try {
      if (!initialLoad) {
        let params: any = {};
        Object.entries(searchData).forEach(
          ([key, value]: [key: string, value: any]) => {
            if (value.value) {
              params[key] = value.value;
            }
          }
        );
        // If params are empty, return all vehicles
        if (Object.keys(params).length === 0) {
          await dispatch(getAllVehicles({}));
        } else {
          await dispatch(getVehiclesByFilter(params));
          const vinFound = await dispatch(getVehiclesByFilter(params));
          setAutoCompleteValue(vinFound?.payload[0]?.vin);
          setOverwriteOptionValue("vin");
        }
      } else {
        setUserSearched(false);
        setSelectedOption(null);
        setAutoCompleteValue("");
        setOverwriteOptionValue("plate");
        await dispatch(getAllVehicles({}));
      }
      setIsSearching(false);
      setIsViewing(true);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  // AutoComplete Logic
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const onAutoCompleteSelect = (option: any) => {
    setAutoCompleteValue(option.label);
    setSelectedOption(option);
  };

  const handleSearch = () => {
    setUserSearched(true);
    if (selectedOption) {
      const selectedVehicle = [...rawVehiclesData].find(
        (record: any) => record._id === selectedOption._id
      );
      setAutoCompleteValue(selectedVehicle.vin);
      setOverwriteOptionValue("vin");
      if (selectedVehicle) {
        setResponseData([selectedVehicle]);
      }
    }
  };

  return (
    <div className="vehicles-page-container">
      {initialLoad && <ConnectOneLoader />}

      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          label={"Vehicles"}
          headers={vehicleHeaders}
          sortGridData={handleSorting}
          exportData={exportData}
          handleRowSelection={handleRowSelection}
          handleSearch={async () => {
            await getTableData({ initialLoad: true });
            handleSearch();
          }}
          handleReset={() => {
            getTableData({ initialLoad: true });
            handleResetSearch();
          }}
          resetButtonLabel={
            displayingFilteredData || userSearched
              ? "Clear Search"
              : "Refresh Data"
          }
          actionButtonLabel={"Add New Vehicle"}
          handleActionButton={handleAdd}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          uploadButtonLabel={"Upload Vehicles"}
          uploadBucket={`peasyvehicleprocessing-${process.env.REACT_APP_USER_BRANCH}`}
          uploadFileType={FileTypes["XLSX"]}
          vehicleLookUp={true}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
          onAutoCompleteSelect={onAutoCompleteSelect}
          description="List of all vehicles enrolled in ConnectOne."
          advancedSearchLabel={"Advanced"}
          handleAdvancedSearch={() => setIsSearching(true)}
          searchButtonDisabled={!autoCompleteValue}
          overwriteOptionValue={overwriteOptionValue}
        />
      )}

      {isSearching && (
        <VehiclesAdvancedSearch
          title="Advanced Search"
          searchData={searchData}
          isLoading={isLoading}
          handleChange={handleFilterInputChange}
          handleSubmit={getTableData}
          handleClose={() => setIsSearching(false)}
        />
      )}

      {isEditing && (
        <VehiclesEditForm
          data={editData}
          label={"Vehicle"}
          handleChange={handleEditInputChange}
          handleGoBack={handleGoBack}
          handleSubmit={handleEditFormSubmit}
          handleCheckbox={handleCheckboxChange}
          isLoading={isLoading}
          isCreating={isCreating}
          isReadOnly={false}
        />
      )}
    </div>
  );
};
