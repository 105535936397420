export const tollActivityReportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          Toll Activity report provides a list of all the toll transactions with
          Toll time/Charge type/Toll amount recorded.
        </li>
        <li>
          The report provides the toll rate that was charged for a vehicle based
          on the rates schedule (refer to Agency Report)/Passenger count for the
          specific toll plaza the vehicle traveled trough.
        </li>
        <li>
          The report also provides the status for these toll transactions. This
          tells us if the toll calculation was successful or failed as shown in
          the table below:
        </li>
      </ul>
      <table className="report-table">
        <tr>
          <th>Type</th>
          <th>Status </th>
          <th>Scenario </th>
        </tr>
        <tr>
          <td>Toll Point </td>
          <td>Completed </td>
          <td>Toll Calculated Successfully </td>
        </tr>
        <tr>
          <td>Toll Distance </td>
          <td>Incomplete </td>
          <td>If Entry Recorded and No Exit after 8hrs </td>
        </tr>
        <tr>
          <td>Toll Distance </td>
          <td>Incomplete </td>
          <td>If there is exit but no entry recorded </td>
        </tr>
        <tr>
          <td>Toll Distance </td>
          <td>Completed </td>
          <td>Toll Calculated Successfully </td>
        </tr>
        <tr>
          <td>Toll Distance </td>
          <td>InProgress </td>
          <td> If there is entry but no exit recorded/loss of connectivity</td>
        </tr>
        <tr>
          <td>Toll Distance </td>
          <td>Error </td>
          <td></td>
        </tr>
        <tr>
          <td>Toll Point </td>
          <td>Error </td>
          <td></td>
        </tr>
      </table>
    </>
  );
};
