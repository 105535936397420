// TripCard is a presentational component that will render a single trip and have an onClick event to view traces
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faMap,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import { getFriendlyTimezoneName } from "../../utils";

import "./Trips.scss";

export const TripCard = ({
  trip,
  dateRange = undefined,
  selectedTrip = undefined,
  customTitle = undefined,
  onTripSelect = () => {},
  index = undefined,
}: {
  trip: any;
  dateRange?: string;
  selectedTrip?: string;
  customTitle?: string;
  onTripSelect?: (trip: any) => void;
  index?: number;
}) => {
  // TODO: Check current trip
  return (
    <div
      className={`trip-card ${
        selectedTrip === trip.id ? "selected-trip-card" : ""
      }`}
      onClick={() => onTripSelect(trip)}
      data-testid={`trip-card-${trip.id}`}
    >
      {customTitle ? (
        <div className="position-relative">
          <span className="go-icon">
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ maxWidth: 14, height: 10 }}
            />
          </span>
          <h3>{customTitle}</h3>
        </div>
      ) : (
        <div className="position-relative">
          <span className="go-icon">
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ maxWidth: 14, height: 10 }}
            />
          </span>
          <h3>{trip.isActive ? "Current Trip" : `Trip #${index + 1}`}</h3>
          <span className="icon">
            <FontAwesomeIcon icon={faClock} style={{ maxWidth: 14 }} />
          </span>
          <span className="trip-date">
            {dateRange
              ? dateRange
              : `${trip.startTimeLocal} (${getFriendlyTimezoneName(
                  trip.startTimeZone,
                  trip.startTripDate,
                  "short"
                )}) - ${trip.endTimeLocal} (${getFriendlyTimezoneName(
                  trip.endTimeZone,
                  trip.endTripDate,
                  "short"
                )})`}
          </span>
          <div className="trip-distance">
            {!dateRange && (
              <>
                <span className="icon">
                  <FontAwesomeIcon icon={faMap} style={{ maxWidth: 14 }} />
                </span>
                {trip.distanceTraveled}{" "}
                {trip.distanceTraveled !== 1 ? "miles" : "mile"}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
