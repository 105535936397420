import { UserEditForm } from "./UserEditForm";
import { userHeaderNames } from "../../../assets";
import { useIsMobile, useUserManagement } from "../../../hooks";
import { ConnectOneClickableTable } from "../../../components";

import "./Users.scss";

export const UserManagementSettings = () => {
  const isMobile = useIsMobile();

  const {
    users,
    selectedUser,
    userTypes,
    isCreating,
    isEditing,
    sortedValue,
    sortAscending,
    handleAddUser,
    handleSorting,
    handleAddUserSelection,
    handleRowSelection,
    handleUserPropertyChange,
    handleUpdateUser,
    handleModalClose,
    handleUserStatusChange,
  } = useUserManagement();

  return (
    <div
      className={
        isMobile
          ? "mobile-user-management-container"
          : "user-management-container"
      }
      data-testid="user-management-container">
      <ConnectOneClickableTable
        data={users}
        label="User Management"
        headers={userHeaderNames}
        sortGridData={handleSorting}
        handleRowSelection={handleRowSelection}
        actionButtonLabel="Add New User"
        handleActionButton={handleAddUserSelection}
        tableBodyHeight="240px"
        sortedValue={sortedValue}
        sortAscending={sortAscending}
        tableHasHeaders={false}
        description="List of Users with their status and roles."
      />

      {isEditing && (
        <UserEditForm
          selectedUser={selectedUser}
          isCreating={isCreating}
          userTypes={userTypes}
          handleChange={handleUserPropertyChange}
          onAdd={handleAddUser}
          onUpdate={handleUpdateUser}
          onClose={handleModalClose}
          onUserStatusChange={handleUserStatusChange}
        />
      )}
    </div>
  );
};
