/* eslint-disable array-callback-return */
import { useSelector } from "react-redux";

import "./GeofenceModal.scss";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const GeofenceModalReadOnly = ({
  geofence,
  handleGetAllEntryPoints,
  setIsEditing,
}: {
  geofence: any;
  handleGetAllEntryPoints?: any;
  setIsEditing?: any;
}) => {
  const agencies = useSelector((state: any) => state.agencies.agenciesData);

  let agencyName = "";
  let agencyStatus = "";
  if (agencies.length) {
    const selectedAgency = agencies?.find(
      (agency: any) => agency._id === geofence.properties.agencyId
    );
    agencyName = selectedAgency?.name || geofence?.properties?.agencyName || "";
    agencyStatus = selectedAgency?.statusString || "";
  }

  const locationStatuses = useSelector(
    (state: any) => state.locationStatuses.locationStatusesData
  );

  const {
    eventActionsData: eventActions,
    locationSubTypeData: locationSubTypes,
  } = useSelector((state: any) => state.locationHierarchy);

  let statusName = null;
  if (locationStatuses?.length) {
    statusName = locationStatuses.find(
      (status: any) => status._id === geofence.properties.status
    )?.name;
  }

  const getActionTypeValue = (eventType: string) => {
    if (eventActions?.length) {
      return (
        eventActions?.find(
          (action: any) =>
            action.value ===
            geofence?.properties?.eventActions?.find(
              (event: any) => event.event === eventType
            )?.action
        )?.label || undefined
      );
    } else {
      return undefined;
    }
  };

  const enterAction = getActionTypeValue("ENTER");
  const exitAction = getActionTypeValue("EXIT");
  const locationSubType = locationSubTypes?.find(
    (subType: any) => subType.value === geofence.properties.locationSubType
  )?.label;

  const handleClick = () => {
    setIsEditing(true);
    if (geofence.properties?.locationSubType === "DISTANCEEXIT") {
      handleGetAllEntryPoints();
    }
  };

  return (
    <div
      className="read-only-geofence-modal"
      key={`readonly-modal-${geofence.name || "new-geofence"}`}
      data-testid="read-only-geofence-modal"
    >
      <div className="properties-card" key="properties-card">
        <div className="editButton">
          <span
            onClick={() => {
              handleClick();
            }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ maxWidth: 20 }} />
          </span>
        </div>
        {geofence?.properties.hasOwnProperty("plazaId") && (
          <p key={"plazaId"}>
            <strong>Plaza ID: </strong>
            {`${geofence?.properties?.plazaId}`}
          </p>
        )}

        {geofence?.properties.hasOwnProperty("roadName") && (
          <p key={"roadName"}>
            <strong>Road Name: </strong>
            {`${geofence?.properties?.roadName}`}
          </p>
        )}
        {!!agencyName && (
          <>
            <p key={"agency-name"}>
              <strong>Agency: </strong>
              {agencyName}
            </p>
            <p key={"agency-status"}>
              <strong>Agency Status: </strong>
              {agencyStatus}
            </p>
          </>
        )}
        {geofence?.properties.hasOwnProperty("locationType") && (
          <p key={"locationType"}>
            <strong>Type: </strong>
            {`${geofence?.properties?.locationType}`}
          </p>
        )}
        {geofence?.properties.hasOwnProperty("locationSubType") && (
          <p key={"locationSubType"}>
            <strong>Location Subtype: </strong>
            {locationSubType}
          </p>
        )}
        {!!statusName && (
          <p key={"status"}>
            <strong>Location Status: </strong>
            {statusName}
          </p>
        )}
        {!!enterAction && (
          <p key={"enter-action"}>
            <strong>Entry Action: </strong>
            {enterAction}
          </p>
        )}
        {!!exitAction && (
          <p key={"exit-action"}>
            <strong>Exit Action: </strong>
            {exitAction}
          </p>
        )}

        <p key={"verified"}>
          <strong>Verified Status: </strong>
          {geofence?.properties?.verified ? "Verified" : "Unverified"}
        </p>

        {!!geofence?.properties?.notes && (
          <p key={"notes"}>
            <strong>Notes: </strong>
            {geofence?.properties?.notes}
          </p>
        )}
      </div>
    </div>
  );
};
