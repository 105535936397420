// @ts-nocheck

// Based off https://github.com/drykovanov/mapbox-gl-draw-rotate-scale-rect-mode
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import doubleClickZoom from "@mapbox/mapbox-gl-draw/src/lib/double_click_zoom";
import createSupplementaryPoints from "@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points";
import * as CommonSelectors from "@mapbox/mapbox-gl-draw/src/lib/common_selectors";
import moveFeatures from "@mapbox/mapbox-gl-draw/src/lib/move_features";

import { point } from "@turf/helpers";
import bearing from "@turf/bearing";
import center from "@turf/center";
import midpoint from "@turf/midpoint";
import distance from "@turf/distance";
import destination from "@turf/destination";
import transformRotate from "@turf/transform-rotate";
import transformScale from "@turf/transform-scale";

export const RotateScaleMode = {};

export const RotateScaleCenter = {
  Center: 0, // rotate or scale around center of polygon
  Opposite: 1, // rotate or scale around opposite side of polygon
};

function parseRotateScaleCenter(
  value,
  defaultRotateScaleCenter = RotateScaleCenter.Center
) {
  if (value === undefined || value == null) return defaultRotateScaleCenter;

  if (
    value === RotateScaleCenter.Center ||
    value === RotateScaleCenter.Opposite
  )
    return value;

  if (value === "center") return RotateScaleCenter.Center;

  if (value === "opposite") return RotateScaleCenter.Opposite;

  throw Error("Invalid RotateScaleCenter: " + value);
}

RotateScaleMode.onSetup = function (opts) {
  const featureId = this.getSelected()[0]?.id || undefined;

  if (!featureId) {
    console.error(
      "This geofence is locked and cannot have its coordinates updated. Please unlock to continue updates."
    );
    return {};
  } else {
    const feature = this.getFeature(featureId);

    if (!feature) {
      throw new Error("You must provide a valid featureId to enter this mode");
    }

    if (feature.type !== Constants?.geojsonTypes?.POLYGON) {
      throw new TypeError("This mode can only handle polygons");
    }
    if (
      feature.coordinates === undefined ||
      feature.coordinates.length !== 1 ||
      feature.coordinates[0].length <= 2
    ) {
      throw new TypeError("This mode can only handle polygons");
    }

    const state = {
      featureId,
      feature,

      canTrash: opts.canTrash !== undefined ? opts.canTrash : true,

      canScale: opts.canScale !== undefined ? opts.canScale : true,
      canRotate: opts.canRotate !== undefined ? opts.canRotate : true,

      singleRotationPoint:
        opts.singleRotationPoint !== undefined
          ? opts.singleRotationPoint
          : false,
      rotationPointRadius:
        opts.rotationPointRadius !== undefined ? opts.rotationPointRadius : 1.0,

      rotatePivot: parseRotateScaleCenter(
        opts.rotatePivot,
        RotateScaleCenter.Center
      ),
      scaleCenter: parseRotateScaleCenter(
        opts.scaleCenter,
        RotateScaleCenter.Center
      ),

      canSelectFeatures:
        opts.canSelectFeatures !== undefined ? opts.canSelectFeatures : true,

      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
    };

    if (!(state.canRotate || state.canScale)) {
      console.warn("Non of canScale or canRotate is true");
    }

    this.setSelectedCoordinates(
      this.pathsToCoordinates(featureId, state.selectedCoordPaths)
    );
    this.setSelected(featureId);
    doubleClickZoom.disable(this);

    this.setActionableState({
      combineFeatures: false,
      uncombineFeatures: false,
      trash: state.canTrash,
    });

    return state;
  }
};

RotateScaleMode.toDisplayFeatures = function (state, geojson, push) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants?.activeStates?.ACTIVE;
    push(geojson);

    const suppPoints = createSupplementaryPoints(geojson, {
      map: this.map,
      midpoints: false,
      selectedPaths: state.selectedCoordPaths,
    });

    if (state.canScale) {
      this.computeBisectrix(suppPoints);
      if (suppPoints.length === 64) {
        [suppPoints[0]].forEach(push);
      } else {
        suppPoints.forEach(push);
      }
    }

    if (state.canRotate) {
      const rotPoints = this.createRotationPoints(state, geojson, suppPoints);
      rotPoints.forEach(push);
    }
  } else {
    geojson.properties.active = Constants?.activeStates?.INACTIVE;
    push(geojson);
  }

  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: state.canTrash,
  });
};

RotateScaleMode.onStop = function () {
  doubleClickZoom.enable(this);
  this.clearSelectedCoordinates();
};

RotateScaleMode.pathsToCoordinates = function (featureId, paths) {
  return paths.map((coord_path) => {
    return { feature_id: featureId, coord_path };
  });
};

RotateScaleMode.computeBisectrix = function (points) {
  for (let i1 = 0; i1 < points.length; i1++) {
    const i0 = (i1 - 1 + points.length) % points.length;
    const i2 = (i1 + 1) % points.length;

    const a1 = bearing(
      points[i0].geometry.coordinates,
      points[i1].geometry.coordinates
    );
    const a2 = bearing(
      points[i2].geometry.coordinates,
      points[i1].geometry.coordinates
    );

    let a = (a1 + a2) / 2.0;

    if (a < 0.0) a += 360;
    if (a > 360) a -= 360;

    points[i1].properties.heading = a;
  }
};

RotateScaleMode._createRotationPoint = function (
  rotationWidgets,
  featureId,
  v1,
  v2,
  rotCenter,
  radiusScale
) {
  const cR0 = midpoint(v1, v2).geometry.coordinates;
  const heading = bearing(rotCenter, cR0);
  const distance0 = distance(rotCenter, cR0);
  const distance1 = radiusScale * distance0;
  const cR1 = destination(rotCenter, distance1, heading, {}).geometry
    .coordinates;

  rotationWidgets.push({
    type: Constants?.geojsonTypes?.FEATURE,
    properties: {
      meta: Constants?.meta?.MIDPOINT,
      parent: featureId,
      lng: cR1[0],
      lat: cR1[1],
      coord_path: v1.properties.coord_path,
      heading: heading,
    },
    geometry: {
      type: Constants?.geojsonTypes?.POINT,
      coordinates: cR1,
    },
  });
};

RotateScaleMode.createRotationPoints = function (state, geojson, suppPoints) {
  const { type } = geojson.geometry;
  const featureId = geojson.properties && geojson.properties.id;

  let rotationWidgets = [];
  if (type !== Constants?.geojsonTypes?.POLYGON) {
    return;
  }

  const corners = suppPoints.slice(0);
  corners[corners.length] = corners[0];

  let v1 = null;

  const rotCenter = this.computeRotationCenter(state, geojson);

  if (state.singleRotationPoint) {
    this._createRotationPoint(
      rotationWidgets,
      featureId,
      corners[0],
      corners[1],
      rotCenter,
      state.rotationPointRadius
    );
  } else {
    corners.forEach((v2) => {
      if (v1 != null) {
        this._createRotationPoint(
          rotationWidgets,
          featureId,
          v1,
          v2,
          rotCenter,
          state.rotationPointRadius
        );
      }

      v1 = v2;
    });
  }

  return rotationWidgets;
};

RotateScaleMode.startDragging = function (state, e) {
  this.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

RotateScaleMode.stopDragging = function (state) {
  this.map.dragPan.enable();
  state.dragMoving = false;
  state.canDragMove = false;
  state.dragMoveLocation = null;
};

const isRotatePoint = CommonSelectors?.isOfMetaType(Constants?.meta?.MIDPOINT);
const isVertex = CommonSelectors?.isOfMetaType(Constants?.meta?.VERTEX);

RotateScaleMode.onTouchStart = RotateScaleMode.onMouseDown = function (
  state,
  e
) {
  if (isVertex(e)) return this.onVertex(state, e);
  if (isRotatePoint(e)) return this.onRotatePoint(state, e);
  if (CommonSelectors?.isActiveFeature(e)) return this.onFeature(state, e);
};

const RotateScaleModeOption = {
  Scale: 1,
  Rotate: 2,
};

RotateScaleMode.onVertex = function (state, e) {
  this.computeAxes(state, state.feature.toGeoJSON());

  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  state.selectedCoordPaths = [about.coord_path];
  state.rotateScaleMode = RotateScaleModeOption.Scale;
};

RotateScaleMode.onRotatePoint = function (state, e) {
  this.computeAxes(state, state.feature.toGeoJSON());

  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  state.selectedCoordPaths = [about.coord_path];
  state.rotateScaleMode = RotateScaleModeOption.Rotate;
};

RotateScaleMode.onFeature = function (state, e) {
  state.selectedCoordPaths = [];
  this.startDragging(state, e);
};

RotateScaleMode.coordinateIndex = function (coordPaths) {
  if (coordPaths.length >= 1) {
    const parts = coordPaths[0].split(".");
    return parseInt(parts[parts.length - 1]);
  } else {
    return 0;
  }
};

RotateScaleMode.computeRotationCenter = function (state, polygon) {
  const center0 = center(polygon);
  return center0;
};

RotateScaleMode.computeAxes = function (state, polygon) {
  const center0 = this.computeRotationCenter(state, polygon);
  const corners = polygon.geometry.coordinates[0].slice(0);

  const n = corners.length - 1;
  const iHalf = Math.floor(n / 2);

  const rotateCenters = [];
  const headings = [];

  for (let i1 = 0; i1 < n; i1++) {
    let i0 = i1 - 1;
    if (i0 < 0) i0 += n;

    const c0 = corners[i0];
    const c1 = corners[i1];
    const rotPoint = midpoint(point(c0), point(c1));

    let rotCenter = center0;
    if (RotateScaleCenter.Opposite === state.rotatePivot) {
      const i3 = (i1 + iHalf) % n; // opposite corner
      let i2 = i3 - 1;
      if (i2 < 0) i2 += n;

      const c2 = corners[i2];
      const c3 = corners[i3];
      rotCenter = midpoint(point(c2), point(c3));
    }

    rotateCenters[i1] = rotCenter.geometry.coordinates;
    headings[i1] = bearing(rotCenter, rotPoint);
  }

  state.rotation = {
    feature0: polygon,
    centers: rotateCenters,
    headings: headings,
  };

  const scaleCenters = [];
  const distances = [];
  for (let i = 0; i < n; i++) {
    const c1 = corners[i];
    let c0 = center0.geometry.coordinates;
    if (RotateScaleCenter.Opposite === state.scaleCenter) {
      const i2 = (i + iHalf) % n; // opposite corner
      c0 = corners[i2];
    }
    scaleCenters[i] = c0;
    distances[i] = distance(point(c0), point(c1), { units: "meters" });
  }

  state.scaling = {
    feature0: polygon,
    centers: scaleCenters,
    distances: distances,
  };
};

RotateScaleMode.onDrag = function (state, e) {
  if (state.canDragMove !== true) return;
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };
  if (state.selectedCoordPaths.length > 0 && state.rotateScaleMode) {
    switch (state.rotateScaleMode) {
      case RotateScaleModeOption.Rotate:
        this.dragRotatePoint(state, e, delta);
        break;
      case RotateScaleModeOption.Scale:
        this.dragScalePoint(state, e, delta);
        break;
    }
  } else {
    this.dragFeature(state, e, delta);
  }

  state.dragMoveLocation = e.lngLat;
};

RotateScaleMode.dragRotatePoint = function (state, e, delta) {
  if (state.rotation === undefined || state.rotation == null) {
    console.error("state.rotation required");
    return;
  }

  const m1 = point([e.lngLat.lng, e.lngLat.lat]);

  const n = state.rotation.centers.length;
  const cIdx = (this.coordinateIndex(state.selectedCoordPaths) + 1) % n;
  const cCenter = state.rotation.centers[cIdx];
  const center = point(cCenter);

  const heading1 = bearing(center, m1);

  const heading0 = state.rotation.headings[cIdx];
  let rotateAngle = heading1 - heading0; // in degrees
  if (CommonSelectors?.isShiftDown(e)) {
    rotateAngle = 5.0 * Math.round(rotateAngle / 5.0);
  }

  const rotatedFeature = transformRotate(state.rotation.feature0, rotateAngle, {
    pivot: center,
    mutate: false,
  });

  state.feature.incomingCoords(rotatedFeature.geometry.coordinates);
  this.fireUpdate();
};

RotateScaleMode.dragScalePoint = function (state, e, delta) {
  if (state.scaling === undefined || state.scaling == null) {
    console.error("state.scaling required");
    return;
  }

  const cIdx = this.coordinateIndex(state.selectedCoordPaths);

  const cCenter = state.scaling.centers[cIdx];
  const center = point(cCenter);
  const m1 = point([e.lngLat.lng, e.lngLat.lat]);

  const dist = distance(center, m1, { units: "meters" });
  let scale = dist / state.scaling.distances[cIdx];

  if (CommonSelectors.isShiftDown(e)) {
    scale = 0.05 * Math.round(scale / 0.05);
  }

  const scaledFeature = transformScale(state.scaling.feature0, scale, {
    origin: cCenter,
    mutate: false,
  });

  state.feature.incomingCoords(scaledFeature.geometry.coordinates);
  this.fireUpdate();
};

RotateScaleMode.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  state.dragMoveLocation = e.lngLat;
  this.fireUpdate();
};

RotateScaleMode.fireUpdate = function () {
  this.map.fire(Constants?.events?.UPDATE, {
    action: Constants?.updateActions?.CHANGE_COORDINATES,
    features: this.getSelected().map((f) => f.toGeoJSON()),
  });
};

RotateScaleMode.onMouseOut = function (state) {
  if (state.dragMoving) {
    this.fireUpdate();
  }
};

RotateScaleMode.onTouchEnd = RotateScaleMode.onMouseUp = function (state) {
  if (state.dragMoving) {
    this.fireUpdate();
  }
  this.stopDragging(state);
};

RotateScaleMode.clickActiveFeature = function (state) {
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  state.feature.changed();
};

RotateScaleMode.onClick = function (state, e) {
  if (CommonSelectors?.noTarget(e)) return this.clickNoTarget(state, e);
  if (CommonSelectors?.isActiveFeature(e))
    return this.clickActiveFeature(state, e);
  if (CommonSelectors?.isInactiveFeature(e))
    return this.clickInactive(state, e);
  this.stopDragging(state);
};

RotateScaleMode.clickNoTarget = function (state, e) {
  if (state.canSelectFeatures) this.changeMode(Constants?.modes?.SIMPLE_SELECT);
};

RotateScaleMode.clickInactive = function (state, e) {
  if (state.canSelectFeatures)
    this.changeMode(Constants?.modes?.SIMPLE_SELECT, {
      featureIds: [e.featureTarget.properties.id],
    });
};

RotateScaleMode.onTrash = function () {
  this.deleteFeature(this.getSelectedIds());
};
