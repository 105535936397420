import { useEffect, useState } from "react";

import { RatesForm } from "./RatesForm";
import { RateCard } from "./RateCard";
import { useIsMobile } from "../../hooks";

import "./RatesManagement.scss";

export const RatesManagement = ({
  schedule,
  index,
  handleInsertScheduleOrRate,
  handleScheduleOrRateChange,
  handleRemoveScheduleOrRate,
  handleScheduleSave,
  handleUndoScheduleChanges,
  handleRemoveExclusiveDate,
}: {
  schedule: any;
  index: number;
  handleInsertScheduleOrRate: any;
  handleScheduleOrRateChange: any;
  handleRemoveScheduleOrRate: any;
  handleScheduleSave: any;
  handleUndoScheduleChanges: any;
  handleRemoveExclusiveDate?: any;
}) => {
  const isMobile = useIsMobile();
  const [editingSchedule, setEditingSchedule] = useState(false);

  // Should set editing to true if the schedule is newly created
  useEffect(() => {
    if (schedule?.isNewlyCreated) {
      setEditingSchedule(true);
      schedule.isNewlyCreated = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelChanges = () => {
    handleUndoScheduleChanges();
    setEditingSchedule(!editingSchedule);
  };

  return (
    <div
      className={`${
        isMobile ? "mobile-rates-management-container" : null
      } rates-management-container`}
      data-testid="rates-management"
    >
      {editingSchedule ? (
        <div key={`schedule-form-${index}`}>
          <RatesForm
            schedule={schedule}
            scheduleIndex={index}
            handleEditToggle={() => setEditingSchedule(!editingSchedule)}
            handleScheduleOrRateChange={handleScheduleOrRateChange}
            handleScheduleChangeCancel={handleCancelChanges}
            handleInsertScheduleOrRate={handleInsertScheduleOrRate}
            handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
            handleRemoveExclusiveDate={handleRemoveExclusiveDate}
            handleScheduleSave={handleScheduleSave}
          />
        </div>
      ) : (
        <RateCard
          schedule={schedule}
          handleScheduleDelete={() =>
            handleRemoveScheduleOrRate({
              objectType: "schedule",
              index,
            })
          }
          handleEditToggle={() => setEditingSchedule(!editingSchedule)}
        />
      )}
    </div>
  );
};
