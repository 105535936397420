import { RatesManagement } from "./RatesManagement";
import { ConnectOneButton } from "../../components";

import "./RatesManagement.scss";

export const DistanceRatesManagement = ({
  geofence,
  entryPoint,
  entryPointSchedules,
  handleInsertScheduleOrRate,
  handleScheduleOrRateChange,
  handleRemoveScheduleOrRate,
  handleScheduleSave,
  handleUndoScheduleChanges,
}: {
  geofence: any;
  entryPoint: any;
  entryPointSchedules: any[];
  handleInsertScheduleOrRate: any;
  handleScheduleOrRateChange: any;
  handleRemoveScheduleOrRate: any;
  handleScheduleSave: any;
  handleUndoScheduleChanges: any;
}) => {
  return (
    <div className="distance-rate-entry-container">
      {entryPoint && (
        <>
          <h1>Entered at Point {entryPoint.entryPointOrder}: {entryPoint.name}</h1>
          {entryPointSchedules?.length > 0 && (
            <>
              {entryPointSchedules.map((schedule: any, index: number) => {
                return (
                  <div key={`schedule-edit-form-${index}`}>
                    <RatesManagement
                      schedule={schedule}
                      index={schedule.scheduleIndex}
                      handleInsertScheduleOrRate={handleInsertScheduleOrRate}
                      handleScheduleOrRateChange={handleScheduleOrRateChange}
                      handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
                      handleScheduleSave={handleScheduleSave}
                      handleUndoScheduleChanges={handleUndoScheduleChanges}
                    />
                  </div>
                );
              })}
            </>
          )}
          <div className="distance-button-container">
            <ConnectOneButton
              label={`Add New Rate`}
              handleClick={() =>
                handleInsertScheduleOrRate({
                  objectType: "schedule",
                  entryPointId: entryPoint.id,
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
