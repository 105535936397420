import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getUserAccessToken,
  refreshCognitoToken,
  trimStringEndWhiteSpace,
} from "../utils";

type Agency = {
  _id: string;
  label: string;
  value: string;
  name: string;
};

export interface AgencyState {
  isLoading: boolean;
  statusesLoading: boolean;
  hasError: boolean;
  agenciesData: Agency[];
  agencyServicesData: any[];
  agencyStatusesData: any[];
}

const initialState: AgencyState = {
  isLoading: true,
  statusesLoading: true,
  hasError: false,
  agenciesData: [],
  agencyServicesData: [],
  agencyStatusesData: [],
};

export const getAgencies = createAsyncThunk(
  "agencies/getAgencies",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/agencies`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAgencyServices = createAsyncThunk(
  "agencies/getAgencyServices",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/AgencyServices`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAgencyStatuses = createAsyncThunk(
  "agencies/getAgencyStatuses",
  async () => {
    try {
      const accessToken = await getUserAccessToken();
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/systemSettings/AgencyStatuses`,
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const putAgency = createAsyncThunk(
  "agencies/putAgencies",
  async (params: any, thunkAPI) => {
    try {
      const accessToken = await getUserAccessToken();
      await refreshCognitoToken();
      const agency: any = {};
      Object.entries(params.editData).forEach(
        ([key, value]: [key: string, value: any]) => (agency[key] = value.value)
      );

      agency["name"] = trimStringEndWhiteSpace(agency["name"]);
      agency["country"] = trimStringEndWhiteSpace(agency["country"]);
      agency["currency"] = trimStringEndWhiteSpace(agency["currency"]);
      agency["timeZone"] = trimStringEndWhiteSpace(agency["timeZone"]);
      agency["charge"] = {
        ...agency["charge"],
        type: trimStringEndWhiteSpace(agency["chargeType"]),
        maxHov: agency["chargeHov"],
      };

      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/agencies/${params.editData._id.value}`,
        method: "PUT",
        headers: {
          Authorization: accessToken,
        },
        data: agency,
      });
      return response.data;
    } catch (err) {
      console.log("Put Agency Err: ", err.message);
    }
  }
);

export const createAgencyShape = (agency: any) => {
  const statusString: { [key: string]: string } = {
    "6610071c88e2f70ebe9c0b96": "Active",
    "6610075588e2f70ebe9c0b97": "Inactive",
    "6610076788e2f70ebe9c0b98": "Removed",
  };

  return {
    ...agency,
    label: agency.name,
    value: agency._id,
    chargeType: agency.charge.type,
    chargeHov: agency.charge.maxHov,
    servicesString: agency.services.join(", "),
    statusString: statusString[agency.status],
  };
};

export const agenciesSlice: any = createSlice({
  name: "agenciesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Agencies
      .addCase(getAgencies.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      // Shaping the data after fetching
      .addCase(getAgencies.fulfilled, (state, action) => {
        state.agenciesData = action?.payload
          ?.map((e: any) => createAgencyShape(e))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getAgencies.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      // Get Agency Services
      .addCase(getAgencyServices.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getAgencyServices.fulfilled, (state, action) => {
        state.agencyServicesData = action?.payload?.data
          ?.map((e: any) => ({
            ...e,
            label: e.value,
            value: e.value,
          }))
          .sort((a: any, b: any) =>
            a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
          );
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getAgencyServices.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      // Get Agency Statuses
      .addCase(getAgencyStatuses.pending, (state, action) => {
        state.statusesLoading = true;
        state.hasError = false;
      })
      .addCase(getAgencyStatuses.fulfilled, (state, action) => {
        state.agencyStatusesData = action?.payload?.data?.map((e: any) => ({
          ...e,
          label: e.name,
          value: e._id,
        }));
        state.statusesLoading = false;
        state.hasError = false;
      })
      .addCase(getAgencyStatuses.rejected, (state, action) => {
        state.hasError = true;
        state.statusesLoading = false;
      })
      // PUT
      .addCase(putAgency.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(putAgency.fulfilled, (state, action) => {
        const updatedAgencyIndex = state.agenciesData.findIndex(
          (agency: any) => agency._id === action.payload._id
        );

        const agencyObj = createAgencyShape(action?.payload);

        if (updatedAgencyIndex === -1) {
          state.agenciesData.push(agencyObj);
        } else {
          state.agenciesData[updatedAgencyIndex] = agencyObj;
        }

        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(putAgency.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default agenciesSlice.reducer;
