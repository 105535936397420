export const vehicleStatusesReportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          Vehicle Status Report provides the Activated/Deactivated status for
          all vehicles in our ConnectOne system.
        </li>
        <li>
          A vehicle enrolled in AutoKinex system will trigger the activation
          process for the same in our ConnectOne system.
        </li>
        <li>Vehicle can be in one of these statuses :</li>
        <ul>
          <li>Activated</li>
          <li>Deactivated</li>
          <li>Pending Activation</li>
          <li>Pending Deactivation</li>
          <li>Failed Activation</li>
          <li>Failed Deactivation</li>
        </ul>
        <li>
          Status Notes provides the error message description for the all the
          different Vehicle status mentioned above.
        </li>
        <table className="report-table">
          <tr>
            <th>Status Notes/Error Message</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>Vehicle not compatible </td>
            <td>Not a Stellantis vehicle</td>
          </tr>
          <tr>
            <td>No Device Found for Vehicle </td>
            <td>Device is not “VP4r” or “r1”</td>
          </tr>
          <tr>
            <td>Last check-in date not found </td>
            <td>Not eligible</td>
          </tr>
          <tr>
            <td>Pending Activation/Failed activation </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Pending Deactivation /Failed Deactivation </td>
            <td>N/A</td>
          </tr>
        </table>
      </ul>
    </>
  );
};
