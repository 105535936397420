import { SelectedUser } from "../../../hooks";
import {
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneOverlay,
} from "../../../components";

import "./Users.scss";

export const UserEditForm = ({
  selectedUser,
  isCreating,
  userTypes,
  handleChange,
  onAdd,
  onUpdate,
  onClose,
  onUserStatusChange,
}: {
  selectedUser: SelectedUser;
  isCreating: boolean;
  userTypes: any[];
  handleChange: any;
  onAdd: any;
  onUpdate: any;
  onClose: any;
  onUserStatusChange: any;
}) => {
  // Save should be disabled until username is at least 6 characters (if creating), email meets email format, and role is selected
  const isSaveDisabled = () => {
    const isEmailValid =
      selectedUser.email && /\S+@\S+\.\S+/.test(selectedUser.email);
    const isRoleSelected = !!selectedUser.role;
    let isUsernameValid = isCreating
      ? selectedUser.username && selectedUser.username.length >= 6
      : true;
    return !(isUsernameValid && isEmailValid && isRoleSelected);
  };

  return (
    <>
      <ConnectOneOverlay />
      <div className="user-edit-form-container" data-testid="user-edit-form">
        <div className="user-edit-headers">
          {isCreating ? (
            <h1>Create New User</h1>
          ) : (
            <h1>Edit User: {selectedUser.username}</h1>
          )}
          <span className="close" aria-hidden="true" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="user-edit-body">
          <div className="user-edit-form">
            {isCreating && (
              <>
                <ConnectOneInput
                  handleChange={handleChange}
                  label="Username (min 6 characters)"
                  type="text"
                  name="username"
                  placeholder={""}
                  value={selectedUser.username}
                  required={true}
                />
              </>
            )}
            <ConnectOneInput
              handleChange={handleChange}
              label="Email"
              type="text"
              name="email"
              placeholder={selectedUser.email || ""}
              value={selectedUser.email}
              required={true}
            />
            <ConnectOneDropdown
              handleChange={handleChange}
              label="Role"
              name="role"
              options={userTypes}
              required={true}
              value={selectedUser.role}
            />
            <ConnectOneInput
              handleChange={handleChange}
              label="Phone"
              type="tel"
              name="phone"
              placeholder={selectedUser.phone || ""}
              value={selectedUser.phone}
              required={false}
            />
          </div>

          {!isCreating && (
            <div className="edit-only-flows">
              <div className="user-edit-status-change">
                <h2>
                  {`${selectedUser.enabled ? "Disable" : "Enable"} Access for ${selectedUser.username}?`}
                </h2>
                <p>
                  {selectedUser.enabled
                    ? "Disabling will prevent user from logging in."
                    : "Enabling will allow user to log in."}
                </p>{" "}
                <ConnectOneButton
                  label={selectedUser.enabled ? "Disable User" : "Enable User"}
                  handleClick={onUserStatusChange}
                  color={selectedUser.enabled ? "red" : null}
                />
              </div>
            </div>
          )}
        </div>

        <div className="user-edit-footers">
          <ConnectOneButton
            label={isCreating ? "Add User" : "Save Changes"}
            handleClick={isCreating ? onAdd : onUpdate}
            disabled={isSaveDisabled()}
          />
        </div>
      </div>
    </>
  );
};
