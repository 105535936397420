/* eslint-disable array-callback-return */
import {
  ConnectOneCheckboxForm,
  ConnectOneOverlay,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneTextBox,
} from "../../../components";
import { useIsMobile } from "../../../hooks";

import "./Services.scss";

export const ServicesEditForm = ({
  data,
  label = "",
  handleChange,
  handleCheckbox,
  handleGoBack,
  handleSubmit,
  isLoading,
  isCreating = false,
  isReadOnly = false,
}: {
  data: any;
  label: string;
  handleChange: any;
  handleCheckbox: any;
  handleGoBack: any;
  handleSubmit: any;
  isLoading: boolean;
  isCreating?: boolean;
  isReadOnly?: boolean;
}) => {
  const isMobile = useIsMobile();

  const saveDisabled = Object.values(data).some((data: any) => {
    return data.required && data.value === "";
  });

  return (
    <>
      {!isMobile && <ConnectOneOverlay />}
      <div
        className={`edit-service-container ${
          isMobile ? "mobile-edit-service-container" : null
        }`}
        data-testid={`${label}-edit-form`}>
        <div className="edit-form-headers">
          <div
            className="x-container"
            onClick={() =>
              handleGoBack(`${isCreating ? "results" : "editing"}`)
            }
            data-testid="search-modal-close-btn">
            <span aria-hidden="true">&times;</span>
          </div>
          <h1>
            {isCreating
              ? `Create New Service`
              : `Update ${data.prevName.value} Service`}
          </h1>
          <span />
        </div>

        <div className="edit-forms">
          <div className="edit-form-inputs">
            <div className="top-inputs">
              <span key={"services-name"}>
                <ConnectOneInput
                  handleChange={handleChange}
                  label={data.name.label}
                  type={data.name.type}
                  name={data.name.name}
                  placeholder={data.name.label}
                  value={data.name.value}
                  required={data.name.required}
                  readOnly={isReadOnly || data.name.readOnly}
                />
              </span>

              <span key={"services-status"}>
                <ConnectOneDropdown
                  handleChange={handleChange}
                  label={data.status.label}
                  name={data.status.name}
                  options={data.status.options}
                  required={data.status.required}
                  value={data.status.value}
                  readOnly={isReadOnly || data.status.readOnly}
                  alwaysEditable={data.status.alwaysEditable}
                />
              </span>
            </div>

            <span key={"services-description"}>
              <ConnectOneTextBox
                handleChange={handleChange}
                label={data.description.label}
                type={data.description.type}
                name={data.description.name}
                placeholder={data.description.label}
                value={data.description.value}
                required={data.description.required}
                readOnly={isReadOnly || data.description.readOnly}
              />
            </span>
          </div>

          <div className="location-types-container">
            <h2>Location Types:</h2>
            {Object.entries(data).map(
              ([key, value]: [key: string, value: any]) => {
                if (value?.type?.includes("checkboxes")) {
                  return (
                    <span key={key}>
                      <ConnectOneCheckboxForm
                        type={value.type}
                        checkboxData={value}
                        handleCheckbox={handleCheckbox}
                        isReadOnly={isReadOnly}
                      />
                    </span>
                  );
                }
              }
            )}
          </div>
        </div>

        <div className="button-container">
          {isMobile && (
            <div className="close-button">
              <ConnectOneButton
                label="Close"
                handleClick={() =>
                  handleGoBack(`${isCreating ? "results" : "editing"}`)
                }
              />
            </div>
          )}
          <div className="save-button">
            <ConnectOneButton
              label="Save"
              handleClick={handleSubmit}
              isLoading={isLoading}
              disabled={saveDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};
