/* eslint-disable array-callback-return */
import { Expander, ExpanderItem } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../hooks";

import "./Route.scss";

const ParentRouteTitle = ({
  label,
  icon,
  isMobile,
}: {
  label: string;
  icon: any;
  isMobile: boolean;
}) => {
  return (
    <div className={`parent-route-title ${isMobile && "parent-mobile-route"}`}>
      {icon}
      <h1>{label}</h1>
    </div>
  );
};

export const ConnectOneRoute = ({
  onClick,
  route,
  activeRoute,
  isAdmin,
  openedRoute,
  minimizedNav = false,
}: {
  onClick: (path: string) => void;
  route: any;
  activeRoute: string;
  isAdmin?: boolean;
  openedRoute?: string;
  minimizedNav?: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`route ${isMobile && "mobile-route"}`}
      key={`${route.name}-nav-text`}
    >
      {((route?.adminOnly && isAdmin) || !route?.adminOnly) && (
        <>
          {!minimizedNav && route?.children?.length ? (
            <div
              className={`${
                minimizedNav ? "minimized-parent-route" : "parent-route"
              }`}
            >
              <Expander
                type="multiple"
                defaultValue={[
                  `${
                    activeRoute.includes(route.basePath) ? route.basePath : ""
                  }`,
                  `${openedRoute === route.basePath ? route.basePath : ""}`,
                ]}
              >
                <ExpanderItem
                  title={
                    <ParentRouteTitle
                      label={route.name}
                      icon={<route.icon />}
                      isMobile={isMobile}
                    />
                  }
                  value={route.basePath}
                >
                  {route.children.map((child: any) => {
                    if ((child?.adminOnly && isAdmin) || !child?.adminOnly) {
                      return (
                        <span
                          key={child.path}
                          onClick={() => onClick(child.path)}
                          // Handle when nav text is too long
                          style={{
                            height: `${
                              child.name.length >= 18 ? "64px" : null
                            }`,
                          }}
                          className={`${
                            activeRoute === child.path && "active"
                          } child-nav-text parent-route-option`}
                        >
                          <p data-testid={`${child.name}-nav-text`}>
                            {child.name}
                          </p>
                        </span>
                      );
                    }
                  })}
                </ExpanderItem>
              </Expander>
            </div>
          ) : (
            <div
              className={`${
                minimizedNav ? "minimized-parent-route" : "parent-route"
              }`}
            >
              <div className="map-route">
                <span
                  onClick={() => onClick(route.path || route.basePath)}
                  className={`${
                    minimizedNav
                      ? (activeRoute.includes(route.basePath) && "active") ||
                        (activeRoute === route.path && "active")
                      : activeRoute === route.path && "active"
                  }`}
                >
                  <route.icon />

                  <h1 data-testid={`${route.name}-nav-text`}>{route.name}</h1>
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
