// Custom hook to handle related functionality and state of geofences throughout the connectone platform
import { useEffect, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// @ts-ignore
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";

import { ModalData } from "../types";
import { AppDispatch } from "../store";
import { daysOfWeek } from "../assets";
import {
  putGeofence,
  toggleGeofenceView,
  deleteGeofence,
  insertTempGeofence,
  removeTempGeofence,
} from "../redux";
import {
  geofenceToGeoJSON,
  convertTwentyFourHourTime,
  convertUTCDateString,
  generateRectangle,
  scheduleSorting,
  shiftCloneGeofence,
  findAllEntryPoints,
} from "../utils";

export const emptyCharge: any = {
  isRegistered: true,
  avi: null,
  plateRead: null,
  equityRate: null,
  cashRate: null,
  hovRate: null,
  negotiatedRate: null,
};

export const emptySchedule: any = {
  vehicleClass: "Class1",
  dayOfWeekStart: 1,
  dayOfWeekEnd: 7,
  timeOfDayStart: "12:00:00 AM",
  timeOfDayEnd: "11:59:59 PM",
  isAccountRate: true,
  startEffectiveDate: "2001-01-01T00:00:00.000Z",
  endEffectiveDate: "9999-12-31T23:59:59.999Z",
  charges: [emptyCharge],
  isNewlyCreated: true,
};

export const useGeofence = ({
  rawGeofenceData,
  queryParamsLocation = undefined,
  isOnAdminPage = false,
}: {
  rawGeofenceData: any;
  queryParamsLocation?: string;
  isOnAdminPage?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  // Geofence Data State
  const [modalData, setModalData] = useState<ModalData>({} as ModalData); // Handles all changes to geofence property values
  const [geofenceShapeData, setGeofenceShapeData] = useState<any>({}); // Handles all changes to geofence shape
  // Generated Polygon State
  const [generatePolygonData, setGeneratePolygonData] = useState({
    displayGeneratePolygonModal: false,
    lonLat: "",
    width: 500,
    height: 40,
  });

  // Query Params State
  const [queryParamsGeofence, setQueryParamsGeofence] = useState(
    !!queryParamsLocation
  );

  // View Toggles State
  const [resetView, setResetView] = useState(false); // Used to reset the draw control view
  const [displayGeofenceModal, setDisplayGeofenceModal] = useState(false); // Check if user should see geofence modal
  const [realTimeDataToggle, setRealTimeDataToggle] = useState({
    label: "Real Time Refresh",
    value: false,
  });
  const [mapView, setMapView] = useState({
    latitude: 39,
    longitude: -91.5,
    zoom: 4,
  });

  // Misc. State
  const [isSaving, setIsSaving] = useState(false); // Check if user is saving a geofen e
  const [isEditingGeofence, setIsEditingGeofence] = useState(false); // Check if user is currently editing a geofence
  const [geofenceToggles, setGeofenceToggles] = useState({} as any); // Handles all changes to geofence view toggles

  // Redux State
  const locationTypes = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );
  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData
  );

  // UseEffects
  useEffect(() => {
    // Handle default toggles
    const defaultOffToggles = ["RUC", "NETWORK"];
    const toggleState = {} as any;

    if (isEmpty(locationTypes)) return;

    for (let [locationType] of locationTypes) {
      toggleState[locationType] = {
        label: `${locationType} Zones`,
        value: !defaultOffToggles.includes(locationType),
        geofenceType: locationType,
      };
    }

    setGeofenceToggles(toggleState);
  }, [locationTypes]);

  useEffect(() => {
    // Do not toggle type filtering on admin page
    if (isOnAdminPage) return;

    // Handle toggle changes
    if (rawGeofenceData?.features?.length) {
      const activeGeofenceTypes = Object.values(geofenceToggles)
        .filter((toggle: any) => !!toggle?.geofenceType && toggle.value)
        .map((toggle: any) => toggle.geofenceType);

      dispatch(toggleGeofenceView(activeGeofenceTypes));
    }
  }, [dispatch, geofenceToggles, isOnAdminPage, rawGeofenceData]);

  useEffect(() => {
    // Handle 'View on Map' button click from Locations Admin Page
    if (queryParamsGeofence && rawGeofenceData?.features?.length) {
      const selectedGeofence = rawGeofenceData.features.find(
        (geofence: any) => geofence._id === queryParamsLocation
      );
      handleGeofenceSelection({ features: [selectedGeofence] });
      setGeofenceToggles((prevToggleState: any) => ({
        ...prevToggleState,
        [selectedGeofence.locationType]: {
          ...prevToggleState[selectedGeofence.locationType],
          value: true,
        },
      }));
      setMapView({
        latitude: selectedGeofence?.center[1],
        longitude: selectedGeofence?.center[0],
        zoom: 17,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawGeofenceData.features, queryParamsLocation]);

  // Reset Geofence Data State
  const handleStateReset = ({
    retainModalData = false,
  }: {
    retainModalData?: boolean;
  }) => {
    setGeofenceShapeData({});
    setIsEditingGeofence(false);
    setQueryParamsGeofence(false);
    if (retainModalData) {
      const selectedGeofence = rawGeofenceData.features.find(
        (geofence: any) => geofence._id === modalData.id
      );

      handleSetModalData(selectedGeofence);
    } else {
      setModalData({} as ModalData);
    }
  };

  // Set the Modal Data on Geofence Selection
  const handleSetModalData = (feature: any) => {
    setModalData({} as ModalData); // Clear Modal Data
    setModalData({
      ...feature,
      name: feature.properties.name || "",
      id: feature.id || feature.properties.id || "",
      properties: {
        ...feature.properties,
        name: feature.properties.name || "",
        eventActions: feature.properties.eventActions || [],
        locationType: feature.properties.locationType,
        agencyName: feature.properties.agencyName,
        schedules: scheduleSorting(feature.properties.schedules),
        lockStatus: feature.properties.lockStatus,
        notes: feature.properties.notes,
        verified: feature.properties.verified,
        status: feature.properties.status,
      },
      schedules: scheduleSorting(feature.properties.schedules),
    });
  };

  const handleGeofenceSelection = async (e: any) => {
    if (e.features.length || e.points.length) {
      setIsEditingGeofence(true);
      setDisplayGeofenceModal(true);
      handleSetModalData(e.features[0]);
      return;
    }
  };

  // Handle Geofence Changes based on Geofence Shape
  const createCircleChange = async (e: any) => {
    const geoData = e?.features?.length ? e?.features[0] : modalData;
    const center = MapboxDrawGeodesic.getCircleCenter(geoData);
    const radius = MapboxDrawGeodesic.getCircleRadius(geoData);

    return geofenceToGeoJSON(
      {
        id: modalData?.id || e?.features[0].id,
        geometry: {
          type: "Polygon",
          center: center,
          radius: radius,
        },
        properties: geoData.properties,
      },
      modalData?.properties?.name || ""
    );
  };

  const createPolygonChange = async (e: any) => {
    const coordinates = e?.features?.length
      ? e.features[0].geometry.coordinates[0]
      : modalData?.geometry?.coordinates;

    return geofenceToGeoJSON(
      {
        id: modalData?.id || e.features[0].id,
        geometry: {
          type: "Polygon",
          coordinates,
        },
        properties: modalData?.properties || e.features[0].properties,
      },
      modalData?.properties?.name || ""
    );
  };

  // Handle Geofence Creation/Updates
  const handleGeofenceUpdate = async (e: any) => {
    if (
      !!e?.features?.length &&
      !e?.features[0]?.geometry?.coordinates?.length
    ) {
      return;
    }

    const { type } = !isEmpty(modalData)
      ? modalData?.geometry
      : e.features[0].geometry;

    const isCircle = !isEmpty(modalData)
      ? modalData.isCircle || MapboxDrawGeodesic.isCircle(modalData)
      : MapboxDrawGeodesic.isCircle(e?.features[0]);

    let change: any;
    if (isCircle) {
      change = await createCircleChange(e);
    } else if (type === "Polygon") {
      change = await createPolygonChange(e);
    }

    setGeofenceShapeData(change);
    return;
  };

  // Handle Generate Polygon
  const handleDisplayGeneratePolygonModal = (lon: number, lat: number) => {
    setGeneratePolygonData(() => ({
      ...generatePolygonData,
      displayGeneratePolygonModal: true,
      lonLat: `${lon},${lat}`,
    }));
  };

  const handleChangeGeneratePolygon = (e: any) => {
    const { name, value } = e.target;

    if (name === "lonLat") {
      setGeneratePolygonData((prevState: any) => ({
        ...prevState,
        lonLat: value,
      }));
    } else {
      setGeneratePolygonData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleGeneratePolygon = async () => {
    const [lon, lat] = generatePolygonData.lonLat?.split(",");
    const generatedPolygon = generateRectangle({
      lon: lon,
      lat: lat,
      width: generatePolygonData.width,
      height: generatePolygonData.height,
    });

    setGeneratePolygonData({
      displayGeneratePolygonModal: false,
      lonLat: "",
      width: 500,
      height: 40,
    });

    await handleGeofenceSelection(generatedPolygon);

    // Insert the clonedGeofence to show on the map before saved in DB
    dispatch(insertTempGeofence(generatedPolygon.features[0]));
  };

  const handleCloseGeneratePolygonModal = () => {
    setGeneratePolygonData({
      displayGeneratePolygonModal: false,
      lonLat: "",
      width: 500,
      height: 40,
    });
  };

  // Handle Geofence Clone
  const handleGeofenceClone = async () => {
    if (window.confirm(`Create a clone of ${modalData.name}?`)) {
      let clonedGeofence = cloneDeep(modalData);

      // Replace Ids or else mapbox will think it's the same geofence and derender the original
      const cloneId = uuidv4();
      clonedGeofence.id = cloneId;
      clonedGeofence._id = cloneId;

      // Add "(Clone)"" to the name and plazaId
      clonedGeofence.name = `${clonedGeofence.name} (Clone)`;
      clonedGeofence.properties.name = `${clonedGeofence.properties.name} (Clone)`;
      if (clonedGeofence.properties.plazaId)
        clonedGeofence.properties.plazaId = `${clonedGeofence.properties.plazaId} (Clone)`;

      // Reset the verified and lockStatus status to default (false)
      clonedGeofence.properties.verified = false;
      clonedGeofence.properties.lockStatus = false;

      // Clear any notes
      clonedGeofence.properties.notes = "";

      // Shift the coordinates
      clonedGeofence = shiftCloneGeofence(clonedGeofence);

      setDisplayGeofenceModal(false);
      await handleGeofenceSelection({ features: [clonedGeofence] });

      setGeofenceShapeData(clonedGeofence);

      // Insert the clonedGeofence to show on the map before saved in DB
      dispatch(insertTempGeofence(clonedGeofence));
      return true;
    } else {
      return false;
    }
  };

  // Handle Geofence Property Changes
  const handleGeofencePropertyChange = async (e: any) => {
    let { name, value, checked, type } = e.target;

    // If user changes the locationType we clear the locationSubType
    // If user has a change to locationSubType we clear the eventActions
    if (name === "locationType") {
      modalData.properties.locationSubType = undefined;
      modalData.properties.eventActions = [];
    } else if (name === "locationSubType") {
      modalData.properties.eventActions = [];
    }

    // Handle changes to eventActions
    if (name === "EXIT" || name === "ENTER") {
      const eventActionsClone: any[] = [...modalData?.properties?.eventActions];
      const eventActionIndex = eventActionsClone?.findIndex(
        (e: any) => e.event === name
      );

      if (eventActionIndex >= 0) {
        if (value === "None") {
          eventActionsClone.splice(eventActionIndex, 1);
        } else {
          eventActionsClone[eventActionIndex] = {
            event: name,
            action: value,
          };
        }
      } else {
        eventActionsClone.push({
          event: name,
          action: value,
        });
      }

      setModalData((modalData: any) => {
        return {
          ...modalData,
          properties: {
            ...modalData.properties,
            eventActions: eventActionsClone,
          },
        };
      });
      return;
    }

    if (name === "course") {
      if (value > 359) {
        value = 359.99;
      }
    }

    setModalData((modalData: any) => {
      return {
        ...modalData,
        properties: {
          ...modalData.properties,
          [name]: type !== "checkbox" ? value : checked,
        },
      };
    });
  };

  // Handle Geofence Modal Actions
  const handleGeofenceModalClose = ({
    isCreating = false,
  }: {
    isCreating?: boolean;
  }) => {
    if (isCreating) dispatch(removeTempGeofence({}));
    handleStateReset({ retainModalData: false });
    setDisplayGeofenceModal(false);
    setResetView(true);
  };

  const handleGeofenceModalBack = () => {
    handleStateReset({ retainModalData: true });
  };

  const handleLockGeofence = async (lockValue: boolean) => {
    setModalData((modalData: any) => {
      return {
        ...modalData,
        properties: {
          ...modalData.properties,
          lockStatus: lockValue,
        },
      };
    });
    await dispatch(
      putGeofence({
        ...modalData,
        properties: {
          ...modalData.properties,
          lockStatus: lockValue,
        },
        geometry: !!geofenceShapeData?.geometry
          ? geofenceShapeData.geometry
          : modalData.geometry,
      })
    );
    setResetView(true);
  };

  const handleGeofenceUndo = async ({
    isCreating = false,
  }: {
    isCreating?: boolean;
  }) => {
    if (
      window.confirm(`${isCreating ? "Cancel Creation?" : "Undo Changes?"}`)
    ) {
      setResetView(true);
      setGeofenceShapeData({});
      if (isCreating) {
        handleGeofenceModalClose({ isCreating });
        dispatch(removeTempGeofence({}));
      }
    }
  };

  const handleGeofenceSave = async ({
    closeModal = true,
  }: {
    closeModal?: boolean;
  }) => {
    setIsSaving(true);
    await dispatch(
      putGeofence({
        ...modalData,
        geometry: !!geofenceShapeData?.geometry
          ? geofenceShapeData.geometry
          : modalData.geometry,
      })
    );
    if (closeModal) {
      setDisplayGeofenceModal(false);
    }
    handleStateReset({ retainModalData: !closeModal });
    setIsSaving(false);
    setResetView(true);
  };

  const handleGeofenceDelete = async (e: any, deleteMessage?: string) => {
    const geofenceId = !isEmpty(modalData) ? modalData.id : e.features[0].id;

    const geofenceName = !isEmpty(modalData)
      ? modalData.name
      : e.features[0].properties.name;

    if (
      window.confirm(
        deleteMessage
          ? deleteMessage
          : `Confirm deletion of geofence: ${geofenceName}?`
      )
    ) {
      await dispatch(deleteGeofence(geofenceId));

      setDisplayGeofenceModal(false);
      handleStateReset({ retainModalData: false });
    }
  };

  // Handle Geofence Schedule/Rate Changes
  const handleScheduleOrRateChange = async ({
    e,
    objectType,
    index,
    parentIndex,
    type,
  }: {
    e: any;
    objectType: string;
    index: number;
    parentIndex?: number;
    type?: string;
  }) => {
    let { name, value } = e.target;

    const schedulesClone = cloneDeep([...modalData.properties.schedules]);

    if (objectType === "schedule") {
      if (type === "dayOfWeek") value = daysOfWeek.indexOf(value) + 1;
      if (type === "boolean") value = value === "true";
      if (type === "time") value = convertTwentyFourHourTime(value, name);
      if (type === "dateTime") value = convertUTCDateString(value);
      if (type === "exclusiveDates") {
        // Do not add duplicate date strings
        if (schedulesClone[index][name].includes(value)) return;
        value = schedulesClone[index][name].concat(value);
        value = value.sort();
      }

      schedulesClone[index][name] = value;
    }

    if (objectType === "charge") {
      if (type === "boolean") value = value === "true";

      schedulesClone[parentIndex].charges[index][name] = value;
    }

    setModalData((modalData: any) => {
      return {
        ...modalData,
        schedules: schedulesClone,
        properties: {
          ...modalData.properties,
          schedules: schedulesClone,
        },
      };
    });
  };

  const handleInsertScheduleOrRate = async ({
    objectType,
    scheduleType,
    index,
    entryPointId,
  }: {
    objectType: string;
    scheduleType?: string;
    index?: number;
    entryPointId?: string;
  }) => {
    const schedulesClone = cloneDeep([...modalData.properties.schedules]);
    if (objectType === "schedule") {
      let emptyScheduleClone = cloneDeep(emptySchedule);

      if (entryPointId)
        emptyScheduleClone.startOfSegmentLocationId = entryPointId;

      if (scheduleType === "exclusiveDates") {
        emptyScheduleClone.exclusiveDates = [];
        delete emptyScheduleClone.dayOfWeekStart;
        delete emptyScheduleClone.dayOfWeekEnd;
      }

      schedulesClone.push({
        ...emptyScheduleClone,
        vehicleClass: classTypes[0]._id,
      });
    }

    if (objectType === "charge") {
      const emptyChargeClone = cloneDeep(emptyCharge);
      schedulesClone[index].charges.push({ ...emptyChargeClone });
    }

    setModalData((modalData: any) => {
      return {
        ...modalData,
        schedules: schedulesClone,
        properties: {
          ...modalData.properties,
          schedules: schedulesClone,
        },
      };
    });
  };

  const handleRemoveExclusiveDate = ({
    dateToRemoveIndex,
    scheduleIndex,
  }: {
    dateToRemoveIndex: number;
    scheduleIndex: number;
  }) => {
    const schedulesClone = cloneDeep([...modalData.properties.schedules]);

    // Remove the date using dateToRemove index
    schedulesClone[scheduleIndex].exclusiveDates.splice(dateToRemoveIndex, 1);

    setModalData((modalData: any) => {
      return {
        ...modalData,
        schedules: schedulesClone,
        properties: {
          ...modalData.properties,
          schedules: schedulesClone,
        },
      };
    });
  };

  const handleRemoveScheduleOrRate = async ({
    objectType,
    index,
    parentIndex,
  }: {
    objectType: string;
    index: number;
    parentIndex?: number;
  }) => {
    if (window.confirm(`Delete selected ${objectType}?`)) {
      const schedulesClone = cloneDeep([...modalData.properties.schedules]);
      if (objectType === "schedule") {
        schedulesClone.splice(index, 1);
      }

      if (objectType === "charge") {
        schedulesClone[parentIndex].charges.splice(index, 1);
      }

      setModalData((modalData: any) => {
        return {
          ...modalData,
          schedules: schedulesClone,
          properties: {
            ...modalData.properties,
            schedules: schedulesClone,
          },
        };
      });

      await dispatch(
        putGeofence({
          ...modalData,
          schedules: schedulesClone,
          properties: {
            ...modalData.properties,
            schedules: schedulesClone,
          },
          geometry: !!geofenceShapeData?.geometry
            ? geofenceShapeData.geometry
            : modalData.geometry,
        })
      );
    }
  };

  const handleUndoScheduleChanges = () => {
    let selectedGeofence = rawGeofenceData.features.find(
      (geofence: any) => geofence.id === modalData.id
    );

    // On undo we need to set the entry points
    if (selectedGeofence?.properties?.locationSubType === "DISTANCEEXIT") {
      const entryPoints = findAllEntryPoints({
        rawGeofenceData,
        selectedGeofence,
      });

      selectedGeofence = { ...selectedGeofence, entryPoints };
    }

    setModalData(selectedGeofence);
  };

  const handleScheduleSave = async () => {
    await dispatch(
      putGeofence({
        ...modalData,
        geometry: !!geofenceShapeData?.geometry
          ? geofenceShapeData.geometry
          : modalData.geometry,
      })
    );
  };

  // Handle Distance Based Geofences
  const handleGetAllEntryPoints = () => {
    const entryPoints = findAllEntryPoints({
      rawGeofenceData,
      selectedGeofence: modalData,
    });
    modalData.entryPoints = entryPoints;
  };

  return {
    mapView,
    modalData,
    displayGeofenceModal,
    isSaving,
    geofenceShapeData,
    realTimeDataToggle,
    geofenceToggles,
    generatePolygonData,
    isEditingGeofence,
    resetView,
    queryParamsGeofence,
    setResetView,
    setRealTimeDataToggle,
    setGeofenceToggles,
    setDisplayGeofenceModal,
    handleGeofenceModalBack,
    handleRemoveScheduleOrRate,
    setModalData,
    handleGeofenceUpdate,
    handleGeofenceDelete,
    handleGeofenceSelection,
    handleGeofenceModalClose,
    handleGeofenceUndo,
    handleGeofenceSave,
    handleGeofencePropertyChange,
    handleScheduleOrRateChange,
    handleInsertScheduleOrRate,
    handleDisplayGeneratePolygonModal,
    handleChangeGeneratePolygon,
    handleGeneratePolygon,
    handleCloseGeneratePolygonModal,
    handleScheduleSave,
    handleUndoScheduleChanges,
    handleLockGeofence,
    handleGeofenceClone,
    handleGetAllEntryPoints,
    handleRemoveExclusiveDate,
  };
};
