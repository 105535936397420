import { useEffect, useState } from "react";
import { addDays, format } from "date-fns";

import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTableControls, ConnectOneTable } from "../../../components";
import { getTCAMatchingAPI } from "../../../axios";
import { formatDateToAPI, shapeMatchingTollCSV } from "../../../utils";
import { TCAMatchingReportDescription } from "./TCAMatchingReportDescription";

import "./TCAMatching.scss";

export const TCAMatchingReport = () => {
  const isMobile = useIsMobile();
  const [exportData, setExportData] = useState(undefined);

  const {
    reportData: gnssTollActivityData,
    setReportData: setGNSSTollActivityData,
    currentPageIndex: gnssTollActivityCurrentPageIndex,
    setCurrentPageIndex: setGNSSTollActivityCurrentPageIndex,
    dataToDisplay: gnssTollActivityDataToDisplay,
    userFriendlyDataInsert,
    totalPages,
    isLoading,
    setIsLoading,
    headers: gnssTollActivityDataHeaders,
    exportFileName,
  } = useReport({ isMobile, reportType: "gnssToll" });

  const {
    reportData: agencyTollActivityData,
    setReportData: setAgencyTollActivityData,
    dataToDisplay: agencyTollActivityDataToDisplay,
    setCurrentPageIndex: setAgencyTollActivityCurrentPageIndex,
    headers: agencyTollActivityDataHeaders,
  } = useReport({ isMobile, reportType: "agencyToll" });

  const [filterValues, setFilterValues] = useState({
    agencyId: {
      type: "autocomplete",
      label: "agency",
      value: "",
      options: [] as any,
      placeholderText: "Select an Agency",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
    },
  });

  useEffect(() => {
    setExportData(
      shapeMatchingTollCSV(
        gnssTollActivityDataHeaders,
        agencyTollActivityDataHeaders,
        gnssTollActivityData,
        agencyTollActivityData
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gnssTollActivityData, agencyTollActivityData]);

  const handlePagination = (value: number) => {
    setGNSSTollActivityCurrentPageIndex(value);
    setAgencyTollActivityCurrentPageIndex(value);
  };

  const submitButtonDisabled = Object.values(filterValues).some(
    (filterValue: any) => !filterValue.value
  );

  const getTableData = async () => {
    setIsLoading(true);
    setGNSSTollActivityCurrentPageIndex(1);
    setAgencyTollActivityCurrentPageIndex(1);
    try {
      const res: any = await getTCAMatchingAPI({
        agencyId: filterValues.agencyId.value,
        startDate: formatDateToAPI(filterValues.dateFrom.value),
        endDate: formatDateToAPI(filterValues.dateTo.value),
      });

      if (res.length) {
        // If a row would be empty, color it red
        const rowColor = "#ffdad6";

        const gnssTollActivities = res.map((e: any) => {
          if (Object.keys(e.gnssTollActivities).length === 0) {
            return {
              ...e.gnssTollActivities,
              rowColor,
            };
          } else {
            return e.gnssTollActivities;
          }
        });
        const gnssTollActivitiesData = userFriendlyDataInsert({
          data: gnssTollActivities,
          localDate: "tollDateTimeLocal",
          utcDate: "tollDateTime",
          timeZone: "timeZone",
        });

        const agencyTollActivities = res.map((e: any) => {
          if (Object.keys(e.agencyTollActivities).length === 0) {
            return {
              ...e.agencyTollActivities,
              rowColor,
            };
          } else {
            return e.agencyTollActivities;
          }
        });
        const agencyTollActivitiesData = userFriendlyDataInsert({
          data: agencyTollActivities,
          localDate: "tran_Time_Local",
          utcDate: "tran_Time",
          timeZone: "timeZone",
        });

        setGNSSTollActivityData(gnssTollActivitiesData);
        setAgencyTollActivityData(agencyTollActivitiesData);
      } else {
        setGNSSTollActivityData([]);
        setAgencyTollActivityData([]);
      }
    } catch (err) {
      setGNSSTollActivityData([]);
      setAgencyTollActivityData([]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="tca-matching-container">
      <ConnectOneTableControls
        fullData={exportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={gnssTollActivityCurrentPageIndex}
        setCurrentPageIndex={handlePagination}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={submitButtonDisabled}
        fileName={exportFileName("TCA", filterValues)}
        exportData={exportData}
        reportTitle="TCA Matching Report"
        reportDescription={<TCAMatchingReportDescription />}
      />
      {!!gnssTollActivityDataToDisplay.length && (
        <div className="matching-table-container">
          <ConnectOneTable
            data={gnssTollActivityDataToDisplay}
            headers={gnssTollActivityDataHeaders}
            caption="ConnectOne Toll Activities"
            isMatching={true}
          />
          <ConnectOneTable
            data={agencyTollActivityDataToDisplay}
            headers={agencyTollActivityDataHeaders}
            caption={"TCA Toll Activities"}
            isMatching={true}
          />
        </div>
      )}
    </div>
  );
};
