// These config values are what we use to determine the AUTH resource for cognito user pool
// Also for the different GEO resources we use to display maps and the geocoder location search

export const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_identityPoolId,
    region: process.env.REACT_APP_region,
    userPoolId: process.env.REACT_APP_userPoolId,
    userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          "GNSS_Tolling_UI-Explore": {
            style: "VectorHereExplore",
          },
          "GNSS_Tolling_UI-Hybrid": {
            style: "HybridHereExploreSatellite",
          },
          "GNSS_Tolling_UI-Imagery": {
            style: "RasterHereExploreSatellite",
          },
        },
        default: "GNSS_Tolling_UI-Hybrid",
      },
      search_indices: {
        items: ["ConnectOne-UI"],
        default: "ConnectOne-UI",
      },
      region: process.env.REACT_APP_region,
    },
  },
};
