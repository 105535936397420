import { useEffect, useRef, useState } from "react";
import { Marker, Popup } from "react-map-gl";

import { VehicleTraceIcon } from "../../assets";
import { getVehicleTraceColor } from "../../utils";
import { ConnectOneVehicleTraceModal } from "./VehicleTraceModal";

import "./VehicleTrace.scss";

export const ConnectOneVehicleTrace = ({
  tracePoint,
  startPoint = false,
  endPoint = false,
  map = null,
  querySelectedTrace = null,
}: {
  tracePoint: any;
  startPoint?: boolean;
  endPoint?: boolean;
  map?: any;
  querySelectedTrace?: string | null;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    longitude: tracePoint.longitude,
    latitude: tracePoint.latitude,
  });
  const popupRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(
    tracePoint._id === querySelectedTrace
  );

  const showBalloonMarker = startPoint || endPoint;

  const handleMarkerClick = ({ originalEvent }: { originalEvent: any }) => {
    originalEvent.stopPropagation();
    setShowPopup(!showPopup);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent text selection
    setIsDragging(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging && map.current) {
      // Getting the map container and calculating the position of the cursor to determine latitude and longitude
      const mapContainer = map.current.getContainer();
      const rect = mapContainer.getBoundingClientRect();
      // Doing some math to handle the offset of the popup so it acts as user is grabbing and dragging by the icon
      const x = e.clientX - rect.left + 185;
      const y = e.clientY - rect.top + 345;
      const { lng, lat } = map.current.unproject([x, y]);
      setPosition({ longitude: lng, latitude: lat });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    <div
      data-testid={
        showBalloonMarker
          ? `${tracePoint.vin}-balloon-marker`
          : `${tracePoint.vin}-marker`
      }
      className="map-marker-container"
    >
      {!showBalloonMarker && (
        <Marker
          latitude={tracePoint.latitude}
          longitude={tracePoint.longitude}
          onClick={handleMarkerClick}
          color={getVehicleTraceColor({
            startPoint,
            endPoint,
            traceData: tracePoint,
          })}
        >
          <div
            className="map-marker"
            style={{
              transform: `rotate(${tracePoint.course - 45}deg)`, // -45 to account for askew svg
              WebkitTransform: `rotate(${tracePoint.course - 45}deg)`, // Handles ios safari
            }}
          >
            <VehicleTraceIcon
              traceData={tracePoint}
              startPoint={startPoint}
              endPoint={endPoint}
              selected={showPopup}
            />
          </div>
        </Marker>
      )}
      {showBalloonMarker && (
        <div
          className="map-marker"
          style={{
            transform: `rotate(${tracePoint.course - 45}deg)`, // -45 to account for askew svg
            WebkitTransform: `rotate(${tracePoint.course - 45}deg)`, // Handles ios safari
          }}
        >
          <Marker
            latitude={tracePoint.latitude}
            longitude={tracePoint.longitude}
            onClick={handleMarkerClick}
            color={getVehicleTraceColor({
              startPoint,
              endPoint,
              traceData: tracePoint,
            })}
          />
        </div>
      )}

      {showPopup && (
        <div ref={popupRef} style={{ position: "absolute" }}>
          <Popup
            latitude={position.latitude}
            longitude={position.longitude}
            offset={{ bottom: [0, -40] }}
            onClose={() => setShowPopup(false)}
            maxWidth={"100%"}
            data-testid={`${tracePoint._id}-popup`}
            closeOnClick={false}
            anchor="bottom"
          >
            <ConnectOneVehicleTraceModal
              tracePoint={tracePoint}
              handleMouseDown={handleMouseDown}
            />
          </Popup>
        </div>
      )}
    </div>
  );
};
