// @ts-nocheck
export const GeneratePolyMode = {};

GeneratePolyMode.onSetup = function (opts) {};

GeneratePolyMode.toDisplayFeatures = function (state, geojson, push) {
  push(geojson);
};

GeneratePolyMode.onClick = function (e, state) {
  this.map.getCanvas().style.cursor = "default";
};
