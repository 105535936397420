import {
  EventBridgeClient,
  PutEventsCommand,
} from "@aws-sdk/client-eventbridge";

import { getCognitoCredentials } from "./cognitoService";

export const handleTriggerTestMsgEventBus = async ({
  data,
  eventBusName,
  source,
  detailType,
  resources,
}: {
  data: any;
  eventBusName: string;
  source: string;
  detailType: string;
  resources: string[];
}) => {
  const { vin, provider } = data;
  const vehicleVin = vin.value;
  const providerName = provider.value;
  const currentUTC = new Date().toISOString();
  const credentials = await getCognitoCredentials();

  const client = new EventBridgeClient({
    region: process.env.REACT_APP_region,
    credentials: credentials,
  });

  const response = await client.send(
    new PutEventsCommand({
      Entries: [
        {
          Detail: JSON.stringify({
            vin: `${vehicleVin}`,
            provider: `${providerName}`,
            policy: 0,
            requestDateTime: `${currentUTC}`,
            requestType: "CUSTOMMESSAGE",
            dynamicData: { message: "This is a test" },
            category: 1,
          }),
          EventBusName: eventBusName,
          DetailType: detailType,
          Resources: resources,
          Source: source,
        },
      ],
    })
  );

  return response;
};
