import { Button } from "@aws-amplify/ui-react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useIsMobile } from "../../../hooks";

import "./Button.scss";

export const ConnectOneButton = ({
  label,
  handleClick,
  disabled = false,
  isLoading = false,
  color = "",
  toolTipMessage = null,
  icon = null,
  backgroundColor = "",
}: {
  label: string;
  handleClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  color?: string;
  toolTipMessage?: string | null;
  icon?: IconProp;
  backgroundColor?: string;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`${isMobile ? "mobile-button" : null} button `}
      data-testid={isLoading ? `loading-button` : null}
      title={toolTipMessage}
    >
      {icon && (
        <span className="icon">
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: disabled ? "#c5c6c7" : color,
              maxWidth: 20,
            }}
          />
        </span>
      )}
      <Button
        onClick={handleClick}
        data-testid={disabled ? `${label}-disabled-button` : `${label}-button`}
        className={disabled ? `disabled-button` : null}
        disabled={disabled}
        isFullWidth={isMobile}
        isLoading={isLoading}
        style={{
          color: disabled ? null : color,
          backgroundColor:
            backgroundColor && disabled ? "#667dff" : backgroundColor,
        }}
      >
        {label}
      </Button>
    </div>
  );
};
