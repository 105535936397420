import { useEffect, useState } from "react";
import { addDays, format } from "date-fns";

import { useIsMobile, useReport } from "../../../hooks";
import { formatDateToAPI, shapeMatchingTollCSV } from "../../../utils";
import { getTollTransactionReconcileAPI } from "../../../axios";
import { ConnectOneTable, ConnectOneTableControls } from "../../../components";
import { TollTransactionReconcileReportDescription } from "./TollTransactionReconcileReportDescription";

import "./TollTransactionReconcile.scss";

export const TollTransactionReconcileReport = () => {
  const isMobile = useIsMobile();
  const [exportData, setExportData] = useState(undefined);

  const {
    reportData: gnssTollActivityData,
    setReportData: setGNSSTollActivityData,
    currentPageIndex: gnssTollActivityCurrentPageIndex,
    setCurrentPageIndex: setGNSSTollActivityCurrentPageIndex,
    dataToDisplay: gnssTollActivityDataToDisplay,
    userFriendlyDataInsert,
    totalPages,
    isLoading,
    setIsLoading,
    headers: gnssTollActivityDataHeaders,
    exportFileName,
  } = useReport({ isMobile, reportType: "gnssToll" });

  const {
    reportData: tollTransactionReconcileData,
    setReportData: setTollTransactionReconcileData,
    dataToDisplay: tollTransactionReconcileDataToDisplay,
    setCurrentPageIndex: setTollTransactionReconcileCurrentPageIndex,
    headers: tollTransactionReconcileDataHeaders,
  } = useReport({ isMobile, reportType: "tollTransactionReconcile" });

  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "autocomplete",
      label: "Vin",
      value: "",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
    },
  });

  useEffect(() => {
    setExportData(
      shapeMatchingTollCSV(
        gnssTollActivityDataHeaders,
        tollTransactionReconcileDataHeaders,
        gnssTollActivityData,
        tollTransactionReconcileData
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gnssTollActivityData, tollTransactionReconcileData]);

  const handlePagination = (value: number) => {
    setGNSSTollActivityCurrentPageIndex(value);
    setTollTransactionReconcileCurrentPageIndex(value);
  };

  const submitButtonDisabled = Object.values(filterValues).some(
    (filterValue: any) => !filterValue.value
  );

  const getTableData = async () => {
    setIsLoading(true);
    setGNSSTollActivityCurrentPageIndex(1);
    setTollTransactionReconcileCurrentPageIndex(1);
    try {
      const res: any = await getTollTransactionReconcileAPI({
        vin: filterValues.vin.value,
        startDate: formatDateToAPI(filterValues.dateFrom.value),
        endDate: formatDateToAPI(filterValues.dateTo.value),
      });

      if (res.length) {
        // If a row would be empty, color it red
        const rowColor = "#ffdad6";

        const gnssTollActivities = res.map((e: any) => {
          if (Object.keys(e.gnssTollActivities).length === 0) {
            return {
              ...e.gnssTollActivities,
              rowColor,
            };
          } else {
            return e.gnssTollActivities;
          }
        });
        const gnssTollActivitiesData = userFriendlyDataInsert({
          data: gnssTollActivities,
          localDate: "tollDateTimeLocal",
          utcDate: "tollDateTime",
          timeZone: "timeZone",
        });

        const reconcileGNSSTollActivities = res.map((e: any) => {
          if (Object.keys(e.reconcileGNSSTollActivities).length === 0) {
            return {
              ...e.reconcileGNSSTollActivities,
              rowColor,
            };
          } else {
            return e.reconcileGNSSTollActivities;
          }
        });
        const reconcileGNSSTollActivitiesData = userFriendlyDataInsert({
          data: reconcileGNSSTollActivities,
          localDate: "tollTransactionDateTimeLocal",
          utcDate: "tollTransactionDateTime",
          timeZone: "timeZone",
        });

        setGNSSTollActivityData(gnssTollActivitiesData);
        setTollTransactionReconcileData(reconcileGNSSTollActivitiesData);
      } else {
        setGNSSTollActivityData([]);
        setTollTransactionReconcileData([]);
      }
    } catch (err) {
      setGNSSTollActivityData([]);
      setTollTransactionReconcileData([]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="toll-matching-container">
      <ConnectOneTableControls
        fullData={exportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={gnssTollActivityCurrentPageIndex}
        setCurrentPageIndex={handlePagination}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={submitButtonDisabled}
        fileName={exportFileName("TOLL_RECONCILE", filterValues)}
        exportData={exportData}
        reportTitle="Toll Transaction Reconcile Report"
        reportDescription={<TollTransactionReconcileReportDescription />}
      />
      {true && (
        <div className="matching-table-container">
          <ConnectOneTable
            data={gnssTollActivityDataToDisplay}
            headers={gnssTollActivityDataHeaders}
            caption="ConnectOne Toll Activities"
            isMatching={true}
          />
          <ConnectOneTable
            data={tollTransactionReconcileDataToDisplay}
            headers={tollTransactionReconcileDataHeaders}
            caption="AutoKinex Toll Activities"
            isMatching={true}
          />
        </div>
      )}
    </div>
  );
};
