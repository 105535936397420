// ConnectOneModal is a basic modal that displays children and a close button
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import "./Modal.scss";

export const ConnectOneModal = ({
  children,
  title,
  handleClose,
  userCanGoBack = false,
}: {
  children: any;
  title: string;
  handleClose: () => void;
  userCanGoBack?: boolean;
}) => {
  return (
    <div className="co-modal">
      <span className="co-modal-backdrop" />
      <div className="co-modal-container">
        <div className="co-modal-header">
          <h1 className="co-modal-title">{title}</h1>
          <div
            className="x-container"
            onClick={handleClose}
            data-testid="co-modal-close-btn"
          >
            <FontAwesomeIcon icon={userCanGoBack ? faChevronLeft : faX} />
          </div>
        </div>
        <div className="co-modal-content">{children}</div>
      </div>
    </div>
  );
};
