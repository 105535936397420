import { TextField, Text } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./TextInput.scss";

export const ConnectOneInput = ({
  handleChange,
  type,
  name,
  placeholder = "",
  label = undefined,
  value = "",
  required = false,
  readOnly = false,
  min = undefined,
  max = undefined,
  maxLength = 255,
}: {
  handleChange: any;
  label?: string;
  type: string;
  name: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  readOnly?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
}) => {
  const isMobile = useIsMobile();

  const requiredStatus = required && !value;

  const defaultMaxDate: { [key: string]: string } = {
    date: "9999-12-31",
    "datetime-local": "9999-12-31T23:59",
  };

  return (
    <div
      className={`${isMobile ? "mobile-input" : null} ${type}-input input ${
        !label ? "input-with-no-label" : ""
      }`}
      key={`${name}-${type}-input`}
    >
      <TextField
        label={
          <Text>
            {label}
            {requiredStatus && (
              <Text as="span" color="red">
                {" "}
                *
              </Text>
            )}
          </Text>
        }
        type={type}
        name={name}
        onChange={handleChange}
        data-testid={`${label}-input`}
        placeholder={placeholder}
        value={value}
        isRequired={required}
        disabled={readOnly}
        min={min ? min : null}
        // Any date pickers should not allow greater than 4 digit year inputs
        max={
          type?.includes("date")
            ? max
              ? max
              : defaultMaxDate[`${type}`]
            : null
        }
        maxLength={maxLength}
      />
    </div>
  );
};
