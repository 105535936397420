import { configureStore } from "@reduxjs/toolkit";

import {
  agenciesReducer,
  classTypesReducer,
  fuelTypesReducer,
  geofenceReducer,
  servicesReducer,
  vehiclesReducer,
  locationStatusesReducer,
  locationHierarchyReducer,
} from "./redux";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: {
    agencies: agenciesReducer,
    classTypes: classTypesReducer,
    fuelTypes: fuelTypesReducer,
    geofences: geofenceReducer,
    locationHierarchy: locationHierarchyReducer,
    locationStatuses: locationStatusesReducer,
    services: servicesReducer,
    vehicles: vehiclesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
