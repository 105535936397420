import { Button } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./SwitchButtons.scss";

export const ConnectOneSwitchButtons = ({
  values,
  active,
  handleClick,
  header = "",
}: {
  values: string[];
  active: string;
  handleClick: any;
  header?: string;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`switch-container ${
        isMobile ? "mobile-switch-container" : null
      }`}
    >
      <h1>{header}</h1>
      <div
        className="switch-buttons-container"
        data-testid="switch-buttons-container"
      >
        {values.map((value) => {
          return (
            <span key={value}>
              <Button
                onClick={handleClick}
                value={value}
                className={
                  value === active
                    ? "active-switch-button"
                    : "inactive-switch-button"
                }
                data-testid={`${value}-switch-button`}
              >
                {value}
              </Button>
            </span>
          );
        })}
      </div>
    </div>
  );
};
