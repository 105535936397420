import { addDays, format } from "date-fns";

import { DeleteIcon } from "../../assets";

import "./RatesManagement.scss";

export const ExclusiveDateList = ({
  exclusiveDates,
  scheduleIndex,
  handleRemoveExclusiveDate,
}: {
  exclusiveDates: string[];
  scheduleIndex: number;
  handleRemoveExclusiveDate: any;
}) => {
  return (
    <div className="exclusive-date-list-form-container">
      {exclusiveDates.map((date: string, index: number) => {
        // Format the date to be more readable
        const formattedDate = format(addDays(new Date(date), 1), "MM/dd/yyyy");
        return (
          <div
            key={`exclusive-date-${index}`}
            className="exclusive-date-list-form"
          >
            <p>{formattedDate}</p>
            <span
              onClick={() =>
                handleRemoveExclusiveDate({
                  dateToRemoveIndex: index,
                  scheduleIndex,
                })
              }
              data-testid="schedule-delete-button"
              title="Remove Date"
            >
              <DeleteIcon className="delete-icon" />
            </span>
          </div>
        );
      })}
    </div>
  );
};
