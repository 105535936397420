export const servicesEditState = {
  status: {
    type: "dropdown",
    label: "Status",
    value: "Active",
    name: "status",
    options: [] as any,
    required: true,
  },
  name: {
    label: "Name",
    value: "",
    name: "name",
    required: true,
  },
  prevName: {
    label: "Name",
    value: "",
    name: "name",
  },
  description: {
    label: "Description",
    value: "",
    name: "description",
    type: "text-area",
    required: true,
  },
  LocationTypes: {
    label: "Location Types",
    name: "LocationTypes",
    type: "checkboxes-string",
    value: [] as string[],
    options: [] as any,
  },
  _id: {},
};

export const servicesFilterState = {
  name: {
    label: "Name",
    value: "",
    name: "name",
  },
  status: {
    type: "dropdown",
    label: "Status",
    value: "",
    name: "status",
    options: [] as any,
  },
  LocationTypes: {
    label: "Location Type",
    name: "LocationTypes",
    type: "dropdown",
    value: "",
    options: [] as any,
  },
};
