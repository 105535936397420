import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button } from "@aws-amplify/ui-react";
import { FileTypes } from "../../../assets";
import { uploadToS3 } from "../../../utils";

export const BulkUpload = ({
  exportHeaders,
  show,
  setShow,
}: {
  exportHeaders?: any;
  show: boolean;
  setShow: any;
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const uploadBucket = `basicgeofenceprocessor-${process.env.REACT_APP_USER_BRANCH}`;
  const inputFile = useRef(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleUploadFile = async (event: any) => {
    setButtonDisabled(true);
    setLoading(true);
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      await uploadToS3({
        fileBody: file,
        fileName: file.name,
        bucketName: uploadBucket,
      });
      setSuccessMessage("Upload successful");
      event.target.files = null;
    }
    setButtonDisabled(false);
    setLoading(false);
  };

  const handleUploadClick = () => {
    setSuccessMessage("");
    inputFile.current.click();
  };
  return (
    <>
      {/* onHide={handleClose} */}
      {show && (
        <div className="locations-modal border-0">
          <div className="content">
            <span className="close" aria-hidden="true" onClick={handleClose}>
              &times;
            </span>
            <div className="text-center">
              <h2 className="fw-bold ">
                Geofence <span className="text-primary">Bulk Upload</span>
              </h2>
              <p>
                <span className="fw-bold">
                  Download the bulk upload template{" "}
                </span>{" "}
                to ensure your records import correctly.
              </p>
              <p className="text-primary fw-bold">
                <CSVLink
                  filename={"GeoFence-Bulk-Upload"}
                  headers={exportHeaders}
                  data={[]}
                  className="csv-text"
                >
                  <i className="bi bi-download px-1" /> Download Template
                </CSVLink>
              </p>
              <input
                type="file"
                id="fileUpload"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={handleUploadFile}
                // Clear the target value so user can upload same file
                onClick={(event: any) => {
                  event.target.value = null;
                }}
                accept={
                  // @ts-ignore
                  FileTypes["CSV"]
                }
              />
              <Button
                onClick={handleUploadClick}
                disabled={loading}
                className="bt-upload"
              >
                {loading ? "Loading ..." : "Choose File"}
              </Button>
              <div className="fw-bold">{successMessage && successMessage}</div>
            </div>
            <div className="border-0">
              <Button
                className="bt-close"
                onClick={handleClose}
                disabled={buttonDisabled}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
