import { useIsMobile } from "../../hooks";

import "./Flyout.scss";

export const ConnectOneFlyout = ({
  children,
  displayFlyout,
}: {
  children: any;
  displayFlyout: boolean;
}) => {
  const isMobile = useIsMobile();

  if (displayFlyout) {
    return (
      <div
        className={`flyout-container ${
          isMobile ? "mobile-flyout-container" : null
        }`}
        data-testid={"flyout"}
      >
        {children}
      </div>
    );
  }
};
