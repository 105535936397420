import {
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneModal,
} from "../../../components";

import "./Locations.scss";

export const LocationsAdvancedSearch = ({
  searchData,
  isLoading,
  handleChange,
  handleClose,
  handleSubmit,
}: {
  searchData: any;
  isLoading: boolean;
  handleChange: any;
  handleClose: any;
  handleSubmit: any;
}) => {
  return (
    <ConnectOneModal title={"Advanced Search"} handleClose={handleClose}>
      <div className="advanced-search-container">
        <h2>Plaza Data</h2>
        <div className="inputs-container">
          {/* Plaza Name */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Plaza Name"}
            type={"text"}
            name={"name"}
            placeholder={"Plaza Name"}
            value={searchData.name.value}
          />
          {/* Plaza ID */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Plaza ID"}
            type={"text"}
            name={"plazaId"}
            placeholder={"Plaza ID"}
            value={searchData.plazaId.value}
          />
        </div>

        <h2>Agency Data</h2>
        <div className="inputs-container">
          {/* Agency */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Agency"}
            name={"agencyId"}
            options={searchData.agencyId.options}
            value={searchData.agencyId.value}
          />
          {/* State */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"State"}
            name={"state"}
            options={searchData.state.options}
            value={searchData.state.value}
          />
        </div>

        <h2>Location Data</h2>
        <div className="inputs-container">
          {/* Type */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Type"}
            name={"locationType"}
            options={searchData.locationType.options}
            value={searchData.locationType.value}
          />
          {/* Status */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Status"}
            name={"status"}
            options={searchData.status.options}
            value={searchData.status.value}
          />
          {/* Verified */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Verified"}
            name={"verified"}
            options={searchData.verified.options}
            value={searchData.verified.value}
          />
        </div>
        <div className="advanced-search-submit">
          <ConnectOneButton
            label={"Search"}
            handleClick={handleSubmit}
            disabled={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ConnectOneModal>
  );
};
