export const VerraLogo = () => {
  return (
    <>
      <svg width="60px" height="56px" fill="none" data-testid="verra-logo">
        <g clipPath="url(#clip0_6948_25840)">
          <path
            d="M8.74641 23.6735L5.5182 31.4702H3.22821L0 23.6735H2.89888L4.37321 27.7365L5.84753 23.6735H8.74641V23.6735ZM49.5987 31.4702L51.0731 27.4072L52.5474 31.4702H55.4463L52.2181 23.6735H49.9281L46.6999 31.4702H49.5987V31.4702ZM7.73544 37.2756L8.93022 41.4306L5.34971 39.0104L3.0061 40.7145L10.2016 45.126L12.055 43.778L10.0829 35.5715L7.73927 37.2756H7.73544ZM47.7147 17.8681L46.5199 13.7132L50.1004 16.1334L52.444 14.4293L45.2485 10.0178L43.3951 11.3657L45.3672 19.5722L47.7108 17.8681H47.7147ZM17.2554 47.1709L20.6636 49.8285L16.344 49.9778L15.4479 52.735L23.8612 52.0764L24.5696 49.8974L18.1515 44.4175L17.2554 47.1747V47.1709ZM38.1909 7.97287L34.7827 5.31524L39.1023 5.1659L39.9984 2.40871L31.5851 3.06737L30.8767 5.24631L37.2948 10.7262L38.1909 7.96904V7.97287ZM30.7733 49.5834L35.0929 49.7328L31.6847 52.3904L32.5808 55.1476L38.9989 49.6677L38.2904 47.4887L29.8772 46.8301L30.7733 49.5872V49.5834ZM24.673 5.56416L20.3534 5.41481L23.7616 2.75719L22.8655 0L16.4474 5.47991L17.1558 7.65885L25.5691 8.31751L24.673 5.56033V5.56416ZM43.1308 43.5865L46.7113 41.1663L45.5166 45.3213L47.8602 47.0254L49.8323 38.8189L47.9789 37.4709L40.7834 41.8824L43.127 43.5865H43.1308ZM12.3193 11.561L8.73875 13.9812L9.93353 9.82631L7.58992 8.12221L5.61777 16.3287L7.47121 17.6766L14.6667 13.2651L12.3231 11.561H12.3193Z"
            fill="#A9C23F"
          />
        </g>
      </svg>
    </>
  );
};
