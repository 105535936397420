import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import { App } from "./App";
import { amplifyConfig } from "./assets";

import "@aws-amplify/ui-react/styles.css";
import "./index.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// Pass in amplify env vars to allow access to below resources
Amplify.configure(amplifyConfig);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AmplifyProvider>
    <App />
  </AmplifyProvider>
);
