export const HamburgerMenuIcon = () => {
  return (
    <>
      <svg
        width="48px"
        height="48px"
        viewBox="0 0 24 24"
        fill="none"
        data-testid="hamburger-menu-icon"
      >
        <path
          d="M4 18L20 18"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M4 12L20 12"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M4 6L20 6"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};
