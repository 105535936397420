import { ConnectOneCheckbox } from "../..";
import { useIsMobile } from "../../../hooks";

import "./CheckboxForm.scss";

export const ConnectOneCheckboxForm = ({
  type,
  checkboxData,
  handleCheckbox,
  isReadOnly = false,
}: {
  type: string;
  checkboxData: any;
  handleCheckbox: any;
  isReadOnly?: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`${
        isMobile ? "mobile-checkbox-form-container" : null
      } checkbox-form-container`}>
      {checkboxData.options.map((checkbox: any, index: number) => {
        return (
          <span key={checkbox.label}>
            <ConnectOneCheckbox
              handleChange={(e: any) =>
                handleCheckbox({ e, type, parentData: checkboxData })
              }
              label={checkbox?.label}
              name={checkbox?.name}
              value={
                type === "checkboxes-string"
                  ? checkboxData.value.includes(checkbox?.name)
                  : checkboxData.value.find(
                      (e: any) => e.service === checkbox?.name
                    )
              }
              readOnly={isReadOnly}
              labelPosition="end"
            />
          </span>
        );
      })}
    </div>
  );
};
