import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { useAdmin } from "../../../hooks";
import { putService } from "../../../redux";
import { ServicesEditForm } from "./ServicesEditForm";
import { ServicesAdvancedSearch } from "./ServicesAdvancedSearch";
import {
  ServicesHeaders,
  servicesEditState,
  servicesFilterState,
} from "../../../assets";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";

export const AdminControlServices = ({ user }: { user?: any }) => {
  const [userSearched, setUserSearch] = useState(false);

  const {
    initialLoad,
    servicesData: services,
    serviceStatusData: statusTypes,
  } = useSelector((state: any) => state.services);
  const locationTypes = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  useEffect(() => {
    if (!isEmpty(locationTypes)) {
      let locationTypesOptions = [];
      for (let [locationType] of locationTypes) {
        locationTypesOptions.push({
          label: locationType,
          value: locationType,
          name: locationType,
        });
      }
      servicesEditState.LocationTypes.options = locationTypesOptions;
      servicesFilterState.LocationTypes.options = locationTypesOptions.map(
        (option) => ({
          label: option.label,
          value: option.value,
        })
      );
    }
    if (statusTypes?.length) {
      servicesEditState.status.options = statusTypes;
      servicesFilterState.status.options = statusTypes;
    }
  }, [locationTypes, statusTypes]);

  const {
    isEditing,
    isLoading,
    isSearching,
    responseData,
    editData,
    isCreating,
    sortedValue,
    sortAscending,
    searchData,
    displayingFilteredData,
    handleAdd,
    setIsViewing,
    handleSorting,
    handleGoBack,
    handleEditInputChange,
    setResponseData,
    setIsSearching,
    handleRowSelection,
    handleEditFormSubmit,
    handleCheckboxChange,
    handleFilterInputChange,
    handleResetSearch,
  } = useAdmin({
    editState: servicesEditState,
    filterState: servicesFilterState,
    handleSubmit: putService,
    user: user,
  });

  useEffect(() => {
    setIsSearching(false);
    setResponseData(services);
    setIsViewing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsSearching, setResponseData, setIsViewing, services]);

  const handleFilterData = ({
    resetFilters = false,
  }: {
    resetFilters?: boolean;
  }) => {
    const filterParams: any = {};

    if (resetFilters) {
      setSelectedOption(null);
      setAutoCompleteValue("");
      setResponseData(services);
      setUserSearch(false);
      return;
    }

    if (!resetFilters) {
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            filterParams[key] = value.value;
          }
        }
      );
    }

    const filteredData = [...services].filter((service: any) => {
      const { name, status, LocationTypes } = filterParams;
      return (
        (name === undefined ||
          service.name.toLowerCase().includes(name.toLowerCase())) &&
        (status === undefined || service.status === status) &&
        (LocationTypes === undefined ||
          service.LocationTypes.includes(LocationTypes))
      );
    });

    setResponseData(filteredData);
    setIsSearching(false);
  };

  // AutoComplete Logic
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const autoCompleteOptions = responseData
    .map((item: any) => ({
      _id: item._id || "",
      label: item.name || "",
    }))
    .sort((a: { label: string }, b: { label: any }) =>
      a.label.localeCompare(b.label)
    );

  const onAutoCompleteSelect = (option: any) => {
    setAutoCompleteValue(option.label);
    setSelectedOption(option);
  };

  const handleSearch = () => {
    setUserSearch(true);
    if (selectedOption) {
      const selectedRecord = responseData.find(
        (record: any) => record._id === selectedOption._id
      );
      if (selectedRecord) {
        setResponseData([selectedRecord]);
      }
    }
  };

  return (
    <>
      {initialLoad && <ConnectOneLoader />}

      <div>
        <ConnectOneClickableTable
          data={responseData}
          label={"Services"}
          headers={ServicesHeaders}
          sortGridData={handleSorting}
          handleRowSelection={handleRowSelection}
          actionButtonLabel={"Add New Service"}
          handleActionButton={handleAdd}
          tableBodyHeight={isMobile ? "240px" : undefined}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
          autoCompleteOptions={autoCompleteOptions}
          onAutoCompleteSelect={onAutoCompleteSelect}
          autoCompleteLabel={"Service"}
          autCompletePlaceholder={"Select a Service"}
          description="List of aggregated services that are supported by ConnectOne to our customers."
          handleSearch={handleSearch}
          advancedSearchLabel={"Advanced"}
          handleAdvancedSearch={() => setIsSearching(true)}
          resetButtonLabel={"Clear Search"}
          handleReset={
            displayingFilteredData || userSearched
              ? () => {
                  handleResetSearch();
                  handleFilterData({ resetFilters: true });
                }
              : undefined
          }
          searchButtonDisabled={!autoCompleteValue}
        />
      </div>

      {isSearching && (
        <ServicesAdvancedSearch
          searchData={searchData}
          isLoading={isLoading}
          handleChange={handleFilterInputChange}
          handleClose={() => setIsSearching(false)}
          handleSubmit={handleFilterData}
        />
      )}

      {isEditing && (
        <ServicesEditForm
          data={editData}
          label={"Service Type"}
          handleChange={handleEditInputChange}
          handleGoBack={handleGoBack}
          handleSubmit={handleEditFormSubmit}
          handleCheckbox={handleCheckboxChange}
          isLoading={isLoading}
          isCreating={isCreating}
        />
      )}
    </>
  );
};
