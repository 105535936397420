import { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { Autocomplete } from "@aws-amplify/ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ConnectOneButton, ConnectOneUploadButton } from "../..";
import { ArrowIcon, CSVDownloadIcon } from "../../../assets";
import { useIsMobile } from "../../../hooks";

import "./ClickableTable.scss";

export const ConnectOneClickableTable = ({
  data,
  label = "",
  headers,
  sortGridData,
  handleRowSelection,
  handleGoBack = undefined,
  actionButtonLabel = undefined,
  exportData = undefined,
  handleActionButton = undefined,
  handleSearch = undefined,
  handleReset = undefined,
  resetButtonLabel = undefined,
  uploadButtonLabel = undefined,
  uploadBucket = undefined,
  uploadFileType = undefined,
  tableBodyHeight = "282px",
  sortedValue,
  sortAscending,
  autoCompleteValue = undefined,
  setAutoCompleteValue = undefined,
  autoCompleteOptions = undefined,
  onAutoCompleteSelect = undefined,
  autoCompleteLabel = "",
  autCompletePlaceholder = "",
  searchLabel = "Search",
  vehicleLookUp = false,
  tableHasHeaders = true,
  isLoading = false,
  description = "",
  advancedSearchLabel = undefined,
  handleAdvancedSearch = undefined,
  searchButtonDisabled = false,
  overwriteOptionValue = "",
}: {
  data: any;
  label?: string;
  headers: any[];
  sortGridData: any;
  handleRowSelection: any;
  handleGoBack?: any;
  actionButtonLabel?: string;
  exportData?: any;
  handleActionButton?: any;
  handleSearch?: any;
  handleReset?: any;
  resetButtonLabel?: string;
  uploadButtonLabel?: string;
  uploadBucket?: string;
  uploadFileType?: string;
  tableBodyHeight?: string;
  sortedValue?: string;
  sortAscending?: boolean;
  autoCompleteValue?: string;
  setAutoCompleteValue?: any;
  autoCompleteOptions?: any[];
  onAutoCompleteSelect?: any;
  autoCompleteLabel?: string;
  autCompletePlaceholder?: string;
  searchLabel?: string;
  vehicleLookUp?: boolean;
  tableHasHeaders?: boolean;
  isLoading?: boolean;
  description?: string;
  advancedSearchLabel?: string;
  handleAdvancedSearch?: any;
  searchButtonDisabled?: boolean;
  overwriteOptionValue?: string;
}) => {
  const isMobile = useIsMobile();

  const [dataToDisplay, setDataToDisplay] = useState([] as any);

  const chunkSize = 250;

  useEffect(() => {
    setDataToDisplay([...data].splice(0, chunkSize));
  }, [data]);

  const renderMoreData = () => {
    const newData = [...data].splice(dataToDisplay?.length, chunkSize);
    setDataToDisplay([...dataToDisplay, ...newData]);
  };

  useEffect(() => {
    if (overwriteOptionValue) {
      setOptionValue(overwriteOptionValue);
      setAutoCompleteOptionsState(
        overwriteOptionValue === "vin" ? autocompleteVIN : autocompletePlate
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overwriteOptionValue]);

  // AutoComplete Logic
  const [optionValue, setOptionValue] = useState("plate");
  const onAutoCompleteChange = (event: any) => {
    setAutoCompleteValue(event.target.value);
  };
  const onAutoCompleteClear = () => {
    setAutoCompleteValue("");
  };
  const [autocompleteVIN, setAutocompleteVIN] = useState<any[]>([]);
  const [autocompletePlate, setAutocompletePlate] = useState<any[]>([]);
  const [autoCompleteOptionsState, setAutoCompleteOptionsState] = useState([]);
  const [autocompletePlaceholder, setAutocompletePlaceholder] = useState<any>();

  const searchOptions = [
    { value: "plate", label: "Plate" },
    { value: "vin", label: "VIN" },
  ];

  const placeholderTextOverWrite: { [key: string]: string } = {
    plate: "Enter Vehicle Plate Number",
    vin: "Enter Vehicle VIN Number",
  };

  const filterAndMapData = (data: any[], key: string, state: boolean) => {
    return data
      .filter((item) => item[key])
      .map((item) => ({
        _id: item._id || "",
        label: !state
          ? item[key]
          : `${item[key]} - ${item.licensePlateState || ""}`,
        vin: item.vin || "",
      }));
  };

  const getVinPlateInfo = () => {
    const vinData = filterAndMapData(data, "vin", false);
    const plateData = filterAndMapData(data, "licensePlate", true);
    setAutocompleteVIN(vinData);
    setAutocompletePlate(plateData);
    setAutoCompleteOptionsState(plateData);
    const placeholderText = placeholderTextOverWrite["plate"];
    setAutocompletePlaceholder(placeholderText);
  };

  const handleSelectChange = (e: any) => {
    const { value } = e.target;
    setOptionValue(value);
    setAutoCompleteOptionsState(
      value === "vin" ? autocompleteVIN : autocompletePlate
    );
    const placeholderText = placeholderTextOverWrite[value];
    setAutoCompleteValue("");
    setAutocompletePlaceholder(placeholderText);
  };

  useEffect(() => {
    if (vehicleLookUp) {
      getVinPlateInfo();
    }
    // eslint-disable-next-line
  }, [vehicleLookUp]);

  const Rows = ({
    data,
    headers,
    handleRowSelection,
  }: {
    data: any;
    headers: any[];
    handleRowSelection: any;
  }) => {
    return data?.map((row: any, index: number) => {
      return (
        <TableRow key={row._id || `row-${index}`} as="tr">
          {headers.map((header: any) => {
            let cellValue: any = "";

            if (
              header.parentValue &&
              row.hasOwnProperty(header.parentValue) &&
              row[header.parentValue].hasOwnProperty(header.value)
            ) {
              cellValue = row[header.parentValue][header.value];
            } else if (
              row.hasOwnProperty(header.value) &&
              row[header.value] !== null
            ) {
              cellValue = row[header.value];
            }

            if (typeof cellValue === "object" && cellValue !== null) {
              cellValue = cellValue.label;
            }

            if (typeof cellValue === "boolean") {
              cellValue = cellValue ? "True" : "False";
            }

            // Handle empty cells
            if (cellValue === "") {
              cellValue = "N/A";
            }

            return (
              <TableCell
                onClick={() => handleRowSelection(row, index)}
                key={`row-${header.name}-data-${index}`}
                data-testid={`${cellValue}-cell-data`}
                as="td">
                {cellValue}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <div
      className={`clickable-table-container ${
        isMobile ? "mobile-clickable-table-container" : null
      }`}
      data-testid={`${label}-clickable-table`}>
      <div
        className={`${
          actionButtonLabel ? "clickable-table-with-button" : null
        }`}>
        {tableHasHeaders && (
          <div
            className={`${
              handleGoBack || handleSearch || !!autoCompleteOptions
                ? "headers-with-button"
                : null
            } clickable-table-headers headers-with-button`}>
            <span />
            {(autoCompleteOptions || vehicleLookUp) && (
              <div className="autocomplete-bar">
                {vehicleLookUp ? (
                  <div className="position-relative">
                    <select
                      className="autocomplete-dropdown"
                      onChange={handleSelectChange}
                      value={optionValue}>
                      {searchOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <span className="autoCompleteLabel">{autoCompleteLabel}</span>
                )}

                <span>
                  <Autocomplete
                    label="Default Autocomplete"
                    options={
                      vehicleLookUp
                        ? autoCompleteOptionsState
                        : autoCompleteOptions
                    }
                    value={autoCompleteValue}
                    placeholder={
                      vehicleLookUp
                        ? autocompletePlaceholder
                        : autCompletePlaceholder
                    }
                    onChange={onAutoCompleteChange}
                    onClear={onAutoCompleteClear}
                    onSelect={onAutoCompleteSelect}
                  />
                </span>
                {/* {vehicleLookUp && (
                  <div className="position-relative">
                    <button
                      className="vehicle-search"
                      onClick={handleAdvancedSearch}>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faMagnifyingGlass}
                      />
                    </button>
                  </div>
                )} */}
              </div>
            )}
            {searchLabel && (
              <div className="label-with-button">
                {handleReset && (
                  <span className="reset-button">
                    <ConnectOneButton
                      label={resetButtonLabel}
                      handleClick={handleReset}
                    />
                  </span>
                )}
                {handleSearch && (
                  <ConnectOneButton
                    label={searchLabel}
                    handleClick={handleSearch}
                    disabled={searchButtonDisabled}
                  />
                )}
                {advancedSearchLabel && handleAdvancedSearch && (
                  <ConnectOneButton
                    label={advancedSearchLabel}
                    handleClick={handleAdvancedSearch}
                  />
                )}
              </div>
            )}
            {handleGoBack && (
              <ConnectOneButton
                label="Back"
                handleClick={() => handleGoBack("results")}
              />
            )}
          </div>
        )}
        <div className="label-with-button">
          {!!exportData?.cellHeaders && !!exportData?.cellData && (
            <div className="csv-button">
              <CSVLink
                filename={`${label}-Export-${format(
                  new Date(),
                  "MM/dd/yyyy hh:mm"
                )}`}
                headers={exportData.cellHeaders}
                data={exportData.cellData}
                className="csv-text">
                <CSVDownloadIcon className={"csv-icon"} />
              </CSVLink>
            </div>
          )}
          <h1>{label}</h1>
          <div className="description">
            <p>{description}</p>
          </div>
        </div>
        {!isLoading && !dataToDisplay?.length ? (
          <div className="no-data-screen">
            <p>
              No data has been found for the selected filters. Please try again.
            </p>
          </div>
        ) : (
          <div className="clickable-table-table">
            <InfiniteScroll
              dataLength={dataToDisplay?.length}
              next={renderMoreData}
              hasMore={dataToDisplay?.length < data?.length}
              loader={null}
              scrollableTarget="scrollable-rows"
              height={`calc(100vh - ${tableBodyHeight})`}>
              <Table highlightOnHover={true}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableCell
                          as="th"
                          onClick={() => sortGridData(header.value)}
                          key={`header-${header.value}`}>
                          <div
                            className="cell-header tooltip"
                            data-testid={`${header.value}-header`}>
                            <p>{header.name}</p>
                            {header.helpText && (
                              <span className="tooltipText">
                                {header.helpText}
                              </span>
                            )}
                            {sortedValue === header.value && (
                              <span
                                className={
                                  sortAscending
                                    ? "arrow-icon-ascending"
                                    : "arrow-icon-descending"
                                }>
                                <ArrowIcon />
                              </span>
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody as="tbody" className="scrollable-rows">
                  <Rows
                    data={dataToDisplay}
                    headers={headers}
                    handleRowSelection={handleRowSelection}
                  />
                </TableBody>
              </Table>
            </InfiniteScroll>
          </div>
        )}

        <div className="button-container">
          {uploadButtonLabel && !isMobile && (
            <span className="upload-button">
              <ConnectOneUploadButton
                label={uploadButtonLabel}
                uploadBucket={uploadBucket}
                fileType={uploadFileType}
              />
            </span>
          )}

          {actionButtonLabel && (
            <span className="action-button">
              <ConnectOneButton
                label={actionButtonLabel}
                handleClick={handleActionButton}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
