import { Layer, LayerProps, Source, SourceProps } from "react-map-gl";

export const Clusters = ({
  clusterFeatures,
  mapZoom,
  maxZoom,
}: {
  clusterFeatures: any;
  mapZoom: number;
  maxZoom: number;
}) => {
  if (clusterFeatures.length > 0) {
    const clusterSource: SourceProps = {
      id: "geofenceClusters",
      type: "geojson",
      data: {
        type: "FeatureCollection",
        // If the map is zoomed in, hide the clusters
        features: mapZoom < maxZoom ? clusterFeatures : [],
      },
      cluster: true,
      clusterMaxZoom: maxZoom, // Max zoom to cluster points on before breaking into smaller clusters
      clusterRadius: 100, // Radius value used to determine how many points are in a cluster
    };

    const clusterLayer: LayerProps = {
      id: "clusters",
      type: "circle",
      source: "geofenceClusters",
      paint: {
        "circle-color": "#0D23A8",
        "circle-radius": [
          "step",
          ["get", "point_count"],
          24, // Default value if no steps are matched
          80, // First step threshold
          36, // Value if point_count >= 80
          120, // Second step threshold
          48, // Value if point_count >= 120
          240, // Third step threshold
          60, // Value if point_count >= 240
        ],
        "circle-stroke-color": "#2746f2",
        "circle-stroke-width": [
          "step",
          ["get", "point_count"],
          8, // Default value if no steps are matched
          80, // First step threshold
          12, // Value if point_count >= 80
          120, // Second step threshold
          18, // Value if point_count >= 120
          240, // Third step threshold
          24, // Value if point_count >= 240
        ],
        "circle-stroke-opacity": 0.4,
      },
      beforeId: "gl-draw-polygon-fill-inactive.hot", // Set to render higher than the draw control fences
    };

    const clusterCountLayer: LayerProps = {
      id: "cluster-count",
      type: "symbol",
      source: "geofenceClusters",
      layout: {
        "text-field": "{point_count}", // Display the cluster count
        "text-font": ["Fira GO Regular"], // Use default fonts for HERE provider
        "text-size": [
          "step",
          ["get", "point_count"],
          14, // Default value if no steps are matched
          80, // First step threshold
          16, // Value if point_count >= 80
          120, // Second step threshold
          18, // Value if point_count >= 120
          240, // Third step threshold
          24, // Value if point_count >= 240
        ],
        "text-letter-spacing": 0.05,
      },
      paint: {
        "text-color": "#fff",
      },
      beforeId: "gl-draw-polygon-fill-inactive.hot", // Set to render higher than the draw control fences
    };

    // Handling single points
    const singlePointLayer: LayerProps = {
      id: "single-point",
      type: "circle",
      source: "geofenceClusters",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#0D23A8",
        "circle-radius": 14,
        "circle-stroke-color": "#2746f2",
        "circle-stroke-width": 6,
        "circle-stroke-opacity": 0.4,
      },
      beforeId: "gl-draw-polygon-fill-inactive.hot", // Set to render higher than the draw control fences
    };

    const singlePointCountLayer: LayerProps = {
      id: "single-point-count",
      type: "symbol",
      source: "geofenceClusters",
      filter: ["!", ["has", "point_count"]],
      layout: {
        "text-field": "1", // Display the cluster count
        "text-font": ["Fira GO Regular"], // Use default fonts for HERE provider
        "text-size": 12,
      },
      paint: {
        "text-color": "#fff",
      },
      beforeId: "gl-draw-polygon-fill-inactive.hot", // Set to render higher than the draw control fences
    };

    return (
      <Source {...clusterSource}>
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        {/* Since single points are not "clusters" we will style them to look like a cluster */}
        <Layer {...singlePointLayer} />
        <Layer {...singlePointCountLayer} />
      </Source>
    );
  }
};
