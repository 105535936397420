import { ConnectOneButton, ConnectOneInput } from "../..";

import "./GeneratePolygonModal.scss";

export const ConnectOneGeneratePolygonModal = ({
  data,
  handleChange,
  handleSubmit,
  handleClose,
}: {
  data: any;
  handleChange: any;
  handleSubmit: any;
  handleClose: any;
}) => {
  return (
    <div
      className="generate-polygon-modal-container"
      data-testid="generate-polygon-modal">
      <span className="co-modal-backdrop" />

      <div className="generate-polygon-modal">
        <div className="generate-polygon-modal-headers">
          <span />
          <h1>Generate a Geofence</h1>
          <div
            className="x-container"
            onClick={handleClose}
            data-testid="x-container">
            <span aria-hidden="true">&times;</span>
          </div>
        </div>

        <div className="generate-polygon-modal-inputs">
          <ConnectOneInput
            handleChange={handleChange}
            type={"text"}
            label={"Lon,Lat"}
            name={"lonLat"}
            value={data.lonLat}
            required={true}
          />
          <span>
            <ConnectOneInput
              handleChange={handleChange}
              type={"number"}
              label={"Width"}
              name={"width"}
              value={data.width}
              required={true}
            />
            <p>ft.</p>
          </span>
          <span>
            <ConnectOneInput
              handleChange={handleChange}
              type={"number"}
              label={"Height"}
              name={"height"}
              value={data.height}
              required={true}
            />
            <p>ft.</p>
          </span>
        </div>

        <div className="generate-polygon-modal-footers">
          <ConnectOneButton label="Generate" handleClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};
