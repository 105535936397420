// Hanldle adding custom buttons to the draw controls overlay
import { RotateScaleCenter } from "./RotateScaleMode";

export class RotateButton {
  draw: any;

  constructor(draw: any) {
    this.draw = draw;
  }

  onAdd(map: any) {
    const div = document.createElement("div");
    div.className = "mapboxgl-ctrl mapboxgl-ctrl-group custom-ctrl-rotate";
    div.title = "Rotate Tool";
    div.innerHTML = `<button></button>`;
    div.addEventListener("contextmenu", (e) => e.preventDefault());
    div.addEventListener("click", (e) => {
      this.draw.changeMode("RotateScaleMode", {
        canScale: false,
        canRotate: true,
        canTrash: false,
        rotatePivot: RotateScaleCenter.Center,
        scaleCenter: RotateScaleCenter.Opposite,
        singleRotationPoint: true,
        rotationPointRadius: 1.2,
        canSelectFeatures: true,
      });
    });

    return div;
  }

  onRemove() {}
}

export class ScaleButton {
  draw: any;

  constructor(draw: any) {
    this.draw = draw;
  }

  onAdd(map: any) {
    const div = document.createElement("div");
    div.className = "mapboxgl-ctrl mapboxgl-ctrl-group custom-ctrl-scale";
    div.title = "Scale Tool";
    div.innerHTML = `<button>
      </button>`;
    div.addEventListener("contextmenu", (e) => e.preventDefault());
    div.addEventListener("click", (e) => {
      this.draw.changeMode("RotateScaleMode", {
        canScale: true,
        canRotate: false,
        canTrash: false,
        rotatePivot: RotateScaleCenter.Center,
        scaleCenter: RotateScaleCenter.Opposite,
        singleRotationPoint: true,
        rotationPointRadius: 1.2,
        canSelectFeatures: true,
      });
    });

    return div;
  }

  onRemove() {}
}

export class CircleButton {
  draw: any;

  constructor(draw: any) {
    this.draw = draw;
  }

  onAdd(map: any) {
    const div = document.createElement("div");
    div.className = "mapboxgl-ctrl mapboxgl-ctrl-group custom-ctrl-circle";
    div.title = "Circle Tool";
    div.innerHTML = `<button>
      </button>`;
    div.addEventListener("contextmenu", (e) => e.preventDefault());
    div.addEventListener("click", (e) => {
      this.draw.changeMode("draw_circle");
    });

    return div;
  }

  onRemove() {}
}
