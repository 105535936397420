import axios from "axios";

import { refreshCognitoToken, getUserAccessToken } from "../../utils";

export const getVehicleTripsAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/traces/trips`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });

    return res.data;
  } catch (err) {
    console.log("Trips Error: ", err.message);
  }
};

export const getCurrentVehicleTripAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/traces/current`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });

    return res.data;
  } catch (err) {
    console.log("Current Traces Error: ", err.message);
  }
};

export const getVehicleTracesAPI = async (params: any, page: number) => {
  try {
    // If no page sent return early
    if (!page) {
      return;
    }

    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();

    const chunkSize = 1000;

    // Skip in mongo expects 1 as the first page
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/traces/chunk/${page}/${chunkSize}`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });

    return res.data;
  } catch (err) {
    console.log("Traces Error: ", err.message);
  }
};

export const getVehicleTracesCountAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();

    // Get count of all traces in params range
    const tracesCount = await axios({
      url: `${process.env.REACT_APP_API_URL}/traces/count`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });

    return tracesCount.data;
  } catch (err) {
    console.log("Traces Error: ", err.message);
  }
};
