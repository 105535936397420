import {
  ConnectOneModal,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
} from "../../../components";

import "./Services.scss";

export const ServicesAdvancedSearch = ({
  searchData,
  isLoading,
  handleChange,
  handleSubmit,
  handleClose,
}: {
  searchData?: any;
  isLoading?: boolean;
  handleChange?: any;
  handleSubmit?: any;
  handleClose?: any;
}) => {
  return (
    <ConnectOneModal title={"Advanced Search"} handleClose={handleClose}>
      <div className="advanced-search-container">
        <h2>Services Data</h2>
        <div className="inputs-container">
          {/* Name */}
          <ConnectOneInput
            handleChange={handleChange}
            label={"Name"}
            type={"text"}
            name={"name"}
            placeholder={"Service Name"}
            value={searchData.name.value}
          />
          {/* Status */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Status"}
            name={"status"}
            placeholder={"Status"}
            value={searchData.status.value}
            options={[
              { label: "Any", value: "" },
              ...searchData.status.options,
            ]}
          />
          {/* Location Types */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Location Type"}
            name={"LocationTypes"}
            options={[
              { label: "Any", value: "" },
              ...searchData.LocationTypes.options,
            ]}
            value={searchData.LocationTypes.value}
          />
        </div>

        <div className="advanced-search-submit">
          <ConnectOneButton
            label={"Search"}
            handleClick={handleSubmit}
            disabled={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ConnectOneModal>
  );
};
