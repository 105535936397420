// Custom hook created to handled related functionality and state of admin pages
import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store";
import { sortGridData } from "../utils";

export const useAdmin = ({
  user,
  filterState = {},
  editState = {},
  handleSubmit = undefined,
}: {
  user: any;
  filterState?: any;
  editState?: any;
  handleSubmit?: (e: any, user: any) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isSearching, setIsSearching] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortedValue, setSortedValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);

  const [searchData, setSearchData] = useState<any>(filterState);
  const [responseData, setResponseData] = useState<any>([]);
  const [editData, setEditData] = useState<any>(editState);

  const displayingFilteredData = Object.values(searchData).some(
    (value: any) => !!value.value
  );

  const handleResetSearch = () => {
    setSearchData(filterState);
  };

  const handleAdd = () => {
    setIsCreating(true);
    setIsEditing(true);
  };

  const handleGoBack = (page: string) => {
    setIsViewing(true);
    setIsEditing(false);
    setIsCreating(false);
    setEditData(editState);
  };

  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedResponseData = sortGridData({
        unsortedData: responseData,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      setResponseData(sortedResponseData);
      return;
    }
    const sortedResponseData = sortGridData({
      unsortedData: responseData,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    setResponseData(sortedResponseData);
    return;
  };

  const handleFilterInputChange = (e: any) => {
    const { name, value } = e.target;

    setSearchData((prevSearchData: any) => {
      return {
        ...prevSearchData,
        // @ts-ignore
        [name]: { ...searchData[name], value: value },
      };
    });
  };

  const handleFilterCheckboxChange = (e: any) => {
    const { name, checked } = e.target;

    setSearchData((prevSearchData: any) => {
      return {
        ...prevSearchData,
        // @ts-ignore
        [name]: { ...searchData[name], value: checked },
      };
    });
  };

  const handleRowSelection = (row: any) => {
    Object.entries(row).forEach(([key, value]: [key: string, value: any]) =>
      setEditData((prevEditData: any) => {
        if (key === "name") {
          return {
            ...prevEditData,
            // @ts-ignore
            [key]: { ...editData[key], value: value },
            prevName: { ...editData[key], value: value },
          };
        }

        return {
          ...prevEditData,
          // @ts-ignore
          [key]: { ...editData[key], value: value },
        };
      })
    );
    setIsViewing(true);
    setIsEditing(true);
  };

  const handleEditInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    const isNestedValue = name.includes(".");

    if (isNestedValue) {
      const splitNestedValues = name.split(".");

      setEditData((prevEditData: any) => {
        return {
          ...prevEditData,
          // @ts-ignore
          [splitNestedValues[0]]: {
            value: {
              ...editData[splitNestedValues[0]],
              [splitNestedValues[1]]: type !== "checkbox" ? value : checked,
            },
          },
        };
      });

      return;
    }

    setEditData((prevEditData: any) => {
      return {
        ...prevEditData,
        // @ts-ignore
        [name]: {
          ...editData[name],
          value: type !== "checkbox" ? value : checked,
        },
      };
    });
  };

  const handleCheckboxChange = ({
    e,
    type,
    parentData,
  }: {
    e: any;
    type: string;
    parentData: any;
  }) => {
    const { name, checked } = e.target;

    setEditData((prevEditData: any) => {
      if (type === "checkboxes-string") {
        const updatedValue = [...prevEditData[parentData.name].value];
        if (checked) updatedValue.push(name);
        if (!checked)
          updatedValue.splice(
            updatedValue.findIndex((valueName: string) => valueName === name),
            1
          );

        return {
          ...prevEditData,
          [parentData.name]: {
            ...prevEditData[parentData.name],
            value: updatedValue,
          },
        };
      }

      if (type === "checkboxes-object") {
        const updatedValue = [...prevEditData[parentData.name].value];
        if (checked)
          updatedValue.push({
            service: name,
            startEffectivDate: new Date(),
            endEffectiveDate: new Date("9999-12-31T23:59:59.999Z"),
            modifiedBy: user.username,
          });
        if (!checked)
          updatedValue.splice(
            updatedValue.findIndex((e: any) => e.service === name),
            1
          );

        return {
          ...prevEditData,
          [parentData.name]: {
            ...prevEditData[parentData.name],
            value: updatedValue,
          },
        };
      }
    });
  };

  const handleEditFormSubmit = async () => {
    setIsLoading(true);
    //@ts-ignore
    await dispatch(handleSubmit({ editData, user: user }));
    handleGoBack(isCreating ? "results" : "editing");
    setIsLoading(false);
  };

  return {
    isSearching,
    isEditing,
    isLoading,
    searchData,
    responseData,
    editData,
    isViewing,
    isCreating,
    displayingFilteredData,
    sortedValue,
    sortAscending,
    setEditData,
    handleAdd,
    setIsViewing,
    sortGridData,
    handleGoBack,
    handleFilterInputChange,
    handleEditInputChange,
    setIsLoading,
    setSearchData,
    setResponseData,
    setIsSearching,
    setIsEditing,
    handleRowSelection,
    handleEditFormSubmit,
    handleCheckboxChange,
    handleFilterCheckboxChange,
    handleResetSearch,
    handleSorting,
    setSortedValue,
    setSortAscending,
  };
};
