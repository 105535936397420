import { SwitchField } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./Toggle.scss";

export const ConnectOneToggle = ({
  checked,
  disabled,
  label,
  handleChange,
}: {
  checked: boolean;
  disabled: boolean;
  label: string;
  handleChange: any;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`toggle ${isMobile ? "mobile-toggle" : null}`}
      data-testid={`${label}-toggle`}
    >
      <SwitchField
        isChecked={checked}
        isDisabled={disabled}
        label={label}
        labelPosition={isMobile ? "start" : "end"}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
        trackCheckedColor={"#3956ff"}
        size={isMobile ? "large" : null}
        data-testid={`${label}-toggle-btn`}
      />
    </div>
  );
};
