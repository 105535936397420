// Custom hook created to determine if user is on a mobile device or desktop
import { useState, useEffect } from "react";
import { isMobile as isMobileDeivce } from "react-device-detect";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isMobileDeivce);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(isMobileDeivce);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
};
