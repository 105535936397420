import { ConnectOneButton } from "../..";

import "./MeasurementModal.scss";

export const ConnectOneMeasurementModal = ({
  data,
  handleClose,
}: {
  data: any;
  handleClose: any;
}) => {
  return (
    <div
      className="measurement-modal-container"
      data-testid="measurement-modal"
    >
      <div className="measurement-modal">
        <h1>Measurements</h1>
        <p>
          <strong>Length:</strong> {data.distance} feet
        </p>
        <p>
          <strong>Course:</strong> {data.course} degrees
        </p>

        <div className="measurement-modal-footers">
          <ConnectOneButton label="Clear" handleClick={handleClose} />
        </div>
      </div>
    </div>
  );
};
