export const TollTransactionReconcileReportDescription = () => {
  return (
    <>
      <p>
        The Toll Transaction Reconcile Report provides a detailed analysis by
        comparing the toll transactions recorded by ConnectOne with those
        recorded by AutoKinex and the Toll Agencies.
      </p>
      <p>
        The intent of this report is to match each toll transaction based on the
        following fields: Date and time (<strong>Note:</strong> A +/- 30 seconds
        delta is considered while matching the time on the transactions). The
        report highlights the missing transactions in red from both ConnectOne
        and AutoKinex Toll Transactions.
      </p>
      <p>
        <strong>Note:</strong> Data is updated every 3 hours in the ConnectOne
        database. Please account for the delay in toll transactions received
        from toll agencies while providing a date range.
      </p>
      <p>
        <strong>ConnectOne Toll Transactions:</strong>
        <br />
        Any time an activated vehicle crosses an active geofence, ConnectOne
        records the toll transactions (refer to the Toll Activity Report). Based
        on the type of toll (Point/Distance), each transaction can have one of
        the following statuses:
        <ul>
          <li>
            <strong>Complete:</strong> Toll calculated successfully
          </li>
          <li>
            <strong>Incomplete:</strong> If entry is recorded and no exit after
            8 hours, or if there is an exit but no entry recorded
          </li>
          <li>
            <strong>In Progress:</strong> If there is an entry but no exit
            recorded, or loss of connectivity error
          </li>
        </ul>
      </p>
      <p>
        <strong>AutoKinex Toll Transactions:</strong>
        <br />
        Toll transactions received from toll agencies for the plates enrolled in
        AutoKinex through the PlatePass system. This data will be available
        after 24-48 hours. Provider charge is the actual amount charged by the
        agency. Surcharge is the transaction fee.
      </p>
    </>
  );
};
