export const vehicleCommunicationReportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          Vehicle Messages report provides a list of all the messages that are
          sent by ConnectOne to the Provider with timestamp.
        </li>
        <li>
          There are two Message categories:
          <ul>
            <li>
              <b>InVehicle Toll Message</b>
              <ul>
                <li>
                  These are messages that are sent by ConnectOne to be displayed
                  on the Vehicle Infotainment and can be as follows:
                  <table className="report-table">
                    <tr>
                      <th>Provider</th>
                      <th>Name</th>
                      <th>Message</th>
                    </tr>
                    <tr>
                      <td>Stellantis</td>
                      <td>Toll Calculation Error</td>
                      <td>
                        We were unable to calculate the cost of your toll at
                        this time. Please check your invoice.
                      </td>
                    </tr>
                    <tr>
                      <td>Stellantis</td>
                      <td>Abandoned Toll Distance Exit Calculated Charge</td>
                      <td>
                        We were unable to calculate the cost of your toll at
                        this time. Please check your invoice.
                      </td>
                    </tr>
                    <tr>
                      <td>Stellantis</td>
                      <td>Network Send InVehicle Approach Message</td>
                      <td>
                        You have entered a Toll Network. Would you like to
                        enroll your vehicle for toll payment services?
                      </td>
                    </tr>
                    <tr>
                      <td>Stellantis</td>
                      <td>InVehicle Toll Message</td>
                      <td>Toll Charge: "amount"\n Plaza: "location"</td>
                    </tr>
                    <tr>
                      <td>Stellantis</td>
                      <td>TollPoint Send InVehicle Approach Message</td>
                      <td>You are entering a Toll Zone.</td>
                    </tr>
                    <tr>
                      <td>Stellantis</td>
                      <td>
                        Toll Distance Entry Send InVehicle Approach Message
                      </td>
                      <td>
                        You entered a toll road. Charges will be calculated
                        based on the distance traveled.
                      </td>
                    </tr>
                  </table>
                </li>
                <li>
                  <b>Event Messages</b>
                  <ul>
                    <li>
                      Event Messages are the messages sent by ConnectOne to the
                      Provider to change the tolling speed (Fast/Slow mode) on
                      the Vehicle GNSS Stream data depending on the vehicle
                      vicinity around geofence as shown below:
                      <ul>
                        <li>
                          <li>Event Message for moving vehicle to Fast Mode</li>
                          <li>Event Message for moving vehicle to Slow Mode</li>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Test Messages</b>
                  <ul>
                    <li>
                      These are the messages that are sent from the ConnectOne
                      UI to the Provider to check if the messages are being
                      displayed on the Vehicle Infotainment.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          The status tells us if the above messages sent by ConnectOne to the
          provider were successfully delivered or not.
        </li>
        <table className="report-table">
          <tr>
            <th>Status</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>OK </td>
            <td>
              A status of "OK" means that outbound message api call from
              <br></br>
              ConnectOne to provider( eg Stellantis) is successful but does not
              <br></br>
              confirm if message from Provider to vehicle was displayed on the
              infotainment or not.
            </td>
          </tr>
          <tr>
            <td>Bad Request </td>
            <td>Message data format error </td>
          </tr>
          <tr>
            <td>Error </td>
            <td>All other exceptions </td>
          </tr>
          <tr>
            <td>Forbidden </td>
            <td>Access denied for the api call </td>
          </tr>
        </table>
      </ul>
    </>
  );
};
