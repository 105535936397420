import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../store";
import { sortGridData } from "../utils";
import { getDataProvidersAPI } from "../axios";
import { vinLookupFilterState } from "../assets";
import { getAllVehicles, getVehiclesByFilter } from "../redux";

export const useVinLookup = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [searchData, setSearchData] = useState({ ...vinLookupFilterState });
  const [responseData, setResponseData] = useState([]);
  const [isSearching, setIsSearching] = useState(true);
  const [isViewing, setIsViewing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { vehiclesData: vehicles, vehicleStatusData: vehicleStatuses } =
    useSelector((state: any) => state.vehicles);
  const fuelTypes = useSelector((state: any) => state.fuelTypes.fuelTypesData);
  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData
  );
  const [dataProviderData, setDataProviderData] = useState<any>([]);

  if (classTypes?.length) {
    vinLookupFilterState.class.options = classTypes;
  }
  if (fuelTypes?.length) {
    vinLookupFilterState.fuelType.options = fuelTypes;
  }
  if (vehicleStatuses?.length) {
    vinLookupFilterState.status.options = vehicleStatuses;
  }

  const getDataProviders = async () => {
    try {
      const dataProvidersOptions = await getDataProvidersAPI();
      setDataProviderData(dataProvidersOptions);
    } catch (err) {
      console.log(err);
    }
  };

  if (dataProviderData?.length) {
    vinLookupFilterState.provider.options = dataProviderData;
  }

  useEffect(() => {
    getDataProviders();
  }, []);

  // Any dropdown type should be given an Any option
  Object.entries(vinLookupFilterState).forEach(
    ([key, value]: [key: string, value: any]) => {
      if (value.type === "dropdown") {
        // Do not add a second Any option
        if (value?.options[0] && value.options[0].value !== "") {
          value.options = [{ label: "Any", value: "" }, ...value.options];
        }
      }
    }
  );

  const handleGoBack = () => {
    setIsViewing(false);
    setResponseData([]);
    setSearchData({ ...vinLookupFilterState });
    setIsSearching(true);
  };

  const [sortedValue, setSortedValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);

  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedResponseData = sortGridData({
        unsortedData: responseData,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      setResponseData(sortedResponseData);
      return;
    }
    const sortedResponseData = sortGridData({
      unsortedData: responseData,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    setResponseData(sortedResponseData);
    return;
  };

  useEffect(() => {
    if (vehicles?.length) {
      setResponseData(vehicles);
    }
  }, [vehicles]);

  const getSearchResponse = async () => {
    setIsLoading(true);
    try {
      let params: any = {};
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            params[key] = value.value;
          }
        }
      );
      // If params are empty, return all vehicles
      if (Object.keys(params).length === 0) {
        await dispatch(getAllVehicles({}));
      } else {
        await dispatch(getVehiclesByFilter(params));
      }
      setIsSearching(false);
      setIsViewing(true);
    } catch (err) {
      console.log("Get Lookup Modal Search Response Error: ", err);
    }
    setIsLoading(false);
  };

  const handleFilterInputChange = (e: any) => {
    const { name, value } = e.target;

    setSearchData((prevSearchData: any) => {
      return {
        ...prevSearchData,
        // @ts-ignore
        [name]: { ...searchData[name], value: value },
      };
    });
  };

  return {
    searchData,
    responseData,
    isViewing,
    isSearching,
    isLoading,
    sortedValue,
    sortAscending,
    handleGoBack,
    handleSorting,
    getSearchResponse,
    handleFilterInputChange,
  };
};
