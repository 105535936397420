import { useState } from "react";
import { CSVLink } from "react-csv";
import { Loader, Pagination } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";
import { CSVDownloadIcon } from "../../../assets";
import { ConnectOneFilterBar, VinLookup } from "../..";

import "./Table.scss";

export const ConnectOneTableControls = ({
  fullData,
  dataIsLoading,
  totalPages,
  currentPageIndex,
  setCurrentPageIndex,
  filterValues,
  setFilterValues,
  handleFilterSubmit,
  handleAdvancedSearch,
  submitButtonDisabled,
  buttonToolTipMessage = null,
  fileName,
  exportData,
  exportDataLoading = false,
  reportTitle,
  reportDescription,
}: {
  fullData: any;
  dataIsLoading: boolean;
  totalPages: number;
  currentPageIndex: number;
  setCurrentPageIndex: any;
  filterValues: any;
  setFilterValues: any;
  handleFilterSubmit: any;
  handleAdvancedSearch?: any;
  submitButtonDisabled: boolean;
  buttonToolTipMessage?: string;
  fileName: string;
  exportData: any;
  exportDataLoading?: boolean;
  reportTitle?: string;
  reportDescription?: JSX.Element;
}) => {
  const isMobile = useIsMobile();
  const [showFilters, setShowFilters] = useState(!isMobile);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [vinByLookup, setVinByLookup] = useState(false);

  const handleFilterChanges = (e: any) => {
    const { name, value } = e.target || e;
    if (!name.includes("date")) {
      setFilterValues({
        ...filterValues,
        // @ts-ignore
        [name]: { ...filterValues[name], value: value },
      });
      return;
    }

    setFilterValues({
      ...filterValues,
      // @ts-ignore
      [name]: { ...filterValues[name], value: value },
    });
  };

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const showExportButton = !!exportData?.cellHeaders?.length;

  const emptyDataReturned =
    !fullData?.length && currentPageIndex === 1 && !dataIsLoading;

  return (
    <div className="controls-container" data-testid="table-controls">
      <div className="filter-and-controls">
        <ConnectOneFilterBar
          handleChange={handleFilterChanges}
          filterValues={filterValues}
          handleSubmit={handleFilterSubmit}
          handleAdvancedSearch={handleAdvancedSearch}
          dataIsLoading={dataIsLoading}
          submitButtonDisabled={submitButtonDisabled}
          showFilters={showFilters}
          handleOpenMobileMenu={() => setShowFilters(!showFilters)}
          handleSearch={() => setShowLookupModal(true)}
          buttonToolTipMessage={buttonToolTipMessage}
          vinByLookup={vinByLookup}
          setVinByLookup={setVinByLookup}
        />

        {showLookupModal && (
          <VinLookup
            handleSelect={(data: any) => {
              handleFilterChanges(data);
              setShowLookupModal(false);
            }}
            handleClose={() => setShowLookupModal(false)}
            setVinByLookup={setVinByLookup}
          />
        )}

        {showExportButton || dataIsLoading ? (
          <div
            className={`table-controls ${
              isMobile ? "mobile-table-controls" : null
            }`}>
            <div
              className={dataIsLoading ? "disabled-csv-button" : "csv-button"}>
              {exportDataLoading ? (
                <Loader filledColor="#3956ff" />
              ) : (
                <CSVLink
                  filename={fileName}
                  headers={exportData.cellHeaders}
                  data={exportData.cellData}
                  className="csv-text">
                  {isMobile ? (
                    <p>Export to CSV</p>
                  ) : (
                    <CSVDownloadIcon className={"csv-icon"} />
                  )}
                </CSVLink>
              )}
            </div>

            <Pagination
              currentPage={currentPageIndex}
              totalPages={totalPages}
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              onChange={handleOnChange}
            />
          </div>
        ) : (
          <div
            className={`table-controls ${
              isMobile ? "mobile-table-controls" : null
            }`}>
            <div className="no-results">
              <div className="report-title">{reportTitle}</div>
              <div className="report-description"></div>
              {emptyDataReturned ? (
                <p>
                  No data has been found for the selected filters. Please try
                  again.
                </p>
              ) : (
                <>{reportDescription}</>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
