import { usStates } from "../constants";
import { addYears, format } from "date-fns";

export const vehicleFilterState = {
  nickName: {
    label: "Nickname",
    value: "",
    name: "nickName",
  },
  vin: {
    label: "Vin",
    value: "",
    name: "vin",
    required: true,
  },
  licensePlate: {
    label: "Plate",
    value: "",
    name: "licensePlate",
    required: true,
  },
  licensePlateState: {
    label: "Plate State",
    value: "",
    name: "licensePlateState",
    type: "dropdown",
    options: usStates,
    required: true,
  },
  plateType: {
    label: "Plate Type",
    value: "",
    name: "plateType",
  },
  make: {
    label: "Make",
    value: "",
    name: "make",
  },
  model: {
    label: "Model",
    value: "",
    name: "model",
  },
  year: {
    type: "number",
    label: "Year",
    value: "",
    name: "year",
  },
  provider: {
    type: "dropdown",
    label: "Provider",
    value: "",
    name: "provider",
    options: [] as any,
    required: true,
  },
  class: {
    type: "dropdown",
    label: "Class",
    value: "",
    name: "class",
    options: [] as any,
    required: true,
  },
  axles: {
    type: "number",
    label: "Axles",
    value: "",
    name: "axles",
  },
  tires: {
    type: "number",
    label: "Tires",
    value: "",
    name: "tires",
  },
  fuelType: {
    type: "dropdown",
    label: "Fuel Type",
    value: "",
    name: "fuelType",
    options: [] as any,
  },
  deviceType: {
    label: "Vehicle Device Type",
    value: "",
    name: "deviceType",
    readOnly: true,
  },
  tollTransponderId: {
    label: "Toll Transponder ID",
    value: "",
    name: "tollTransponderId",
  },
  fuelChargeId: {
    label: "Fuel / Charge Card ID",
    value: "",
    name: "fuelChargeId",
  },
  obd2ID: {
    label: "Telematics ID",
    value: "",
    name: "obd2ID",
  },
  equity: {
    type: "dropdown",
    label: "Equity",
    value: "",
    name: "equity",
    options: [
      { label: "Equity", value: true },
      { label: "No Equity", value: false },
    ],
  },
  status: {
    type: "dropdown",
    label: "Status",
    value: "",
    name: "status",
    options: [] as any,
    required: true,
  },
};

export const vehicleEditState = {
  ...vehicleFilterState,
  licensePlateState: {
    label: "Plate State",
    value: "",
    name: "licensePlateState",
    type: "dropdown",
    options: [
      { label: "Select a state", value: "", disabled: true },
      ...usStates,
    ],
    required: true,
  },
  class: {
    type: "dropdown",
    label: "Class",
    value: "",
    name: "class",
    options: [] as any,
    required: true,
  },
  fuelType: {
    type: "dropdown",
    label: "Fuel Type",
    value: "",
    name: "fuelType",
    options: [] as any,
  },
  status: {
    type: "dropdown",
    label: "Status",
    value: "65777659cdb69be2659ebfed",
    name: "status",
    options: [] as any,
    required: true,
  },
  equity: {
    type: "dropdown",
    label: "Equity",
    value: false,
    name: "equity",
    options: [
      { label: "Equity", value: true },
      { label: "No Equity", value: false },
    ],
  },
  communicationPreferences: {
    receiveInfotainmentMessages: {
      type: "checkbox",
      label: "Receive Messages",
      value: true,
      name: "receiveInfotainmentMessages",
    },
  },
  provider: {
    type: "dropdown",
    label: "Provider",
    value: "",
    name: "provider",
    options: [] as any,
    required: true,
  },
  vehicleEndEffectiveDate: {
    type: "date",
    label: "End Effective Date",
    value: format(addYears(new Date(), 5), "yyyy-MM-dd"),
    name: "vehicleEndEffectiveDate",
  },
  _id: {},
};

export const vinLookupFilterState = {
  ...vehicleFilterState,
  vin: {
    label: "Last 4 of VIN",
    value: "",
    name: "vin",
  },
};

// Creating a map of vehicle status to display name and text color for display
export const vehicleStatusMap: Record<
  string,
  {
    displayName: string;
    color: string;
    actionButtonText: string;
    actionButtonColor: string;
    actionName: string;
    activatable: boolean;
  }
> = {
  "65777659cdb69be2659ebfed": {
    displayName: "Pending Activation",
    color: "green",
    actionButtonText: "Activate",
    actionButtonColor: "blue",
    actionName: "activate",
    activatable: true,
  },
  "657776a0cdb69be2659ebfef": {
    displayName: "Activated",
    color: "green",
    actionButtonText: "Deactivate",
    actionButtonColor: "red",
    actionName: "deactivate",
    activatable: false,
  },
  "65777696cdb69be2659ebfee": {
    displayName: "Failed Activation",
    color: "red",
    actionButtonText: "Retry Activation",
    actionButtonColor: "blue",
    actionName: "retry activation",
    activatable: true,
  },
  "657776d1cdb69be2659ebff5": {
    displayName: "Pending Deactivation",
    color: "red",
    actionButtonText: "Deactivate",
    actionButtonColor: "red",
    actionName: "deactivate",
    activatable: false,
  },
  "657776c7cdb69be2659ebff4": {
    displayName: "Deactivated",
    color: "red",
    actionButtonText: "Activate",
    actionButtonColor: "blue",
    actionName: "activate",
    activatable: true,
  },
  "6671aaa6fca12366c71fe5f3": {
    displayName: "Failed Deactivation",
    color: "red",
    actionButtonText: "Retry Deactivation",
    actionButtonColor: "red",
    actionName: "retry deactivation",
    activatable: false,
  },
};
