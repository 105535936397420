import { Tabs } from "@aws-amplify/ui-react";

import "./Tabs.scss";

export const ConnectOneTabs = ({ children }: { children: any }) => {
  return (
    <div className="tabs-container">
      <Tabs justifyContent="flex-start">{children}</Tabs>
    </div>
  );
};
