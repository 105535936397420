export const RUCReportDescription = (): JSX.Element => {
  return (
    <>
      <ul>
        <li>
          A Road Usage Charge (RUC) is a transportation funding model wherein
          all drivers are assessed a fee based on the number of miles they
          drive, rather than on how much gas they consume.
        </li>
        <li>
          A road usage charge allows electric, hybrids, and other fuel-efficient
          vehicles to pay their fair share to maintain the roads.
        </li>
        <li>
          RUC is implemented based on periodic odometer reading on the vehicle.
        </li>
        <li>
          RUC offers savings for low-income drivers and also a sustainable
          future.
        </li>
      </ul>
    </>
  );
};
