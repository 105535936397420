import { SearchField } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./SearchInput.scss";

export const ConnectOneSearchInput = ({
  name,
  label,
  value,
  handleChange,
  handleSearch,
  labelHidden = false,
}: {
  name: string;
  label?: string;
  value: string;
  handleChange: any;
  handleSearch: any;
  labelHidden?: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`${isMobile ? "mobile-search-input" : null} search-input`}
      data-testid={`${name}-search-input`}>
      <SearchField
        labelHidden={labelHidden}
        label={label}
        name={name}
        onChange={handleChange}
        data-testid={`${label}-input`}
        value={value}
        onSubmit={handleSearch}
        placeholder={label}
      />
    </div>
  );
};
