// STYLE GUIDE
// Captured base styles for the draw controls
// Editing as needed for Connect.One platform
// Cold styles are used when the map is not being interacted with
// Hot styles are used when the map is being interacted with
// Active styles are used when a feature is being drawn or edited
// Inactive styles are used when a feature is not being drawn or edited
// Static styles are used when a feature is complete and not being edited

const geofenceColors = {
  blue: "#3956ff",
  white: "#fff",
};

const vertexSizes = {
  largeHalo: 10,
  smallHalo: 8,
  largeVertex: 8,
  smallVertex: 6,
};

const lineWidth = 3;

export const DrawControlsStyles = [
  // Polygon Styling
  {
    id: "gl-draw-polygon-fill-active.cold",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": geofenceColors["blue"],
      "fill-outline-color": geofenceColors["blue"],
      "fill-opacity": 0.3,
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-polygon-stroke-active.cold",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": geofenceColors["blue"],
      "line-dasharray": [0.2, 2],
      "line-width": lineWidth,
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-polygon-fill-active.hot",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": geofenceColors["blue"],
      "fill-outline-color": geofenceColors["blue"],
      "fill-opacity": 0.3,
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-polygon-stroke-active.hot",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": geofenceColors["blue"],
      "line-dasharray": [0.2, 2],
      "line-width": lineWidth,
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-polygon-stroke-inactive.hot",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#1c1c1c",
      "line-width": 2,
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-polygon-stroke-inactive.cold",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#1c1c1c",
      "line-width": 2,
    },
    source: "mapbox-gl-draw-cold",
  },

  // Line Styling
  {
    id: "gl-draw-line-inactive.cold",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": geofenceColors["blue"],
      "line-width": lineWidth,
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-line-active.cold",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": geofenceColors["blue"],
      "line-dasharray": [0.2, 2],
      "line-width": lineWidth,
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-line-active.hot",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": geofenceColors["blue"],
      "line-dasharray": [0.2, 2],
      "line-width": lineWidth,
    },
    source: "mapbox-gl-draw-hot",
  },

  // Vertex Styling
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive.cold",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallHalo"],
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive.cold",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallVertex"],
      "circle-color": geofenceColors["blue"],
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive.hot",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallHalo"],
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive.hot",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallVertex"],
      "circle-color": geofenceColors["blue"],
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-polygon-midpoint.cold",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 4,
      "circle-opacity": 0.9,
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-point-point-stroke-inactive.cold",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": vertexSizes["largeHalo"],
      "circle-opacity": 1,
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-point-stroke-active.cold",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": vertexSizes["largeHalo"],
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-point-active.cold",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": vertexSizes["largeVertex"],
      "circle-color": geofenceColors["blue"],
    },
    source: "mapbox-gl-draw-cold",
  },
  {
    id: "gl-draw-polygon-midpoint.hot",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 4,
      "circle-opacity": 0.9,
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-point-point-stroke-inactive.hot",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallHalo"],
      "circle-opacity": 1,
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-point-stroke-active.hot",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallHalo"],
      "circle-color": geofenceColors["white"],
    },
    source: "mapbox-gl-draw-hot",
  },
  {
    id: "gl-draw-point-active.hot",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": vertexSizes["smallVertex"],
      "circle-color": geofenceColors["blue"],
    },
    source: "mapbox-gl-draw-hot",
  },
];
