import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { useSelector } from "react-redux";
import { addDays, format } from "date-fns";

import { EditIcon, DeleteIcon, RatesHeaders, daysOfWeek } from "../../assets";
import { convertToFriendlyTimeFormat } from "../../utils";

import "./RatesManagement.scss";

export const RateCard = ({
  schedule,
  handleScheduleDelete,
  handleEditToggle,
}: {
  schedule: any;
  handleScheduleDelete: any;
  handleEditToggle: any;
}) => {
  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData
  );

  let classType = "";
  if (classTypes.length) {
    classType =
      classTypes.find(
        (classType: any) => classType._id === schedule?.vehicleClass
      )?.name || "";
  }

  return (
    <div className="schedule-card-container" data-testid="schedule-card">
      <div className="schedule-details">
        <p>{classType}</p>
        {/* If dayOfWeekStart exists then show date range */}
        {schedule?.dayOfWeekStart && (
          <p>
            {schedule?.dayOfWeekStart === schedule?.dayOfWeekEnd
              ? daysOfWeek[schedule?.dayOfWeekStart - 1]
              : `${daysOfWeek[schedule?.dayOfWeekStart - 1]} - ${
                  daysOfWeek[schedule?.dayOfWeekEnd - 1]
                }`}
          </p>
        )}
        {schedule?.exclusiveDates?.length && <p>Exclusive Dates</p>}
        <p>{`${convertToFriendlyTimeFormat(
          schedule.timeOfDayStart
        )} - ${convertToFriendlyTimeFormat(schedule?.timeOfDayEnd)}`}</p>

        <div className="schedule-controls">
          <span
            className="edit-icon"
            onClick={handleEditToggle}
            data-testid="schedule-edit-button"
          >
            <EditIcon />
          </span>
          <span
            onClick={handleScheduleDelete}
            data-testid="schedule-delete-button"
          >
            <DeleteIcon className="delete-icon" />
          </span>
        </div>
      </div>

      {/* If exclusive dates then show string of Exclusive Dates */}
      {schedule?.exclusiveDates?.length ? (
        <div className="exclusive-dates">
          <div className="exclusive-dates-list">
            {schedule?.exclusiveDates?.map((date: any, index: number) => {
              // Format the date to be more readable
              const formattedDate = format(
                addDays(new Date(date), 1),
                "MM/dd/yyyy"
              );

              return (
                <div key={`exclusive-date-${index}`}>
                  <p>{formattedDate}</p>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      <div className="rates-details">
        <Table caption="" highlightOnHover={false}>
          <TableHead>
            <TableRow>
              {RatesHeaders.map((header: any) => {
                return (
                  <TableCell as="th" key={header.name}>
                    {header.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {!!schedule?.charges?.length && (
            <TableBody>
              {schedule?.charges?.map((row: any, index: number) => {
                return (
                  <TableRow key={`row-${index}`}>
                    {RatesHeaders.map((header: any) => {
                      let cellValue: any = "";

                      if (
                        row.hasOwnProperty(header.value) &&
                        row[header.value] !== null
                      ) {
                        cellValue = `${row[header.value]}`;
                      }

                      // Handle empty cells
                      if (cellValue === "") {
                        cellValue = "N/A";
                      }

                      return (
                        <TableCell key={`row-${index}-${header.name}`}>
                          {cellValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </div>
  );
};
