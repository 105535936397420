import axios from "axios";

import { refreshCognitoToken, getUserAccessToken } from "../../utils";

export const getVehicleActivityAPI = async (params: any) => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/vehicle/activity`,
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      data: params,
    });
    return res.data;
  } catch (err) {
    console.log("Get Transactions Err: ", err.message);
  }
};
