import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAdmin } from "../../../hooks";
import { putAgency } from "../../../redux";
import { shapeCSVData } from "../../../utils";
import { AgenciesEditForm } from "./AgenciesEditForm";
import {
  agencyHeaders,
  agencyEditState,
  agencyFilterState,
} from "../../../assets";
import { AgenciesAdvancedSearch } from "./AgenciesAdvancedSearch";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";

import "./Agencies.scss";

export const AdminControlAgencies = ({ user }: { user?: any }) => {
  const [userSearched, setUserSearch] = useState(false);
  const [exportData, setExportData] = useState({
    cellHeaders: null,
    cellData: null,
  });

  const {
    agenciesData: agencies,
    agencyServicesData,
    agencyStatusesData,
    isLoading: agenciesLoading,
    statusesLoading,
  } = useSelector((state: any) => state.agencies);

  useEffect(() => {
    if (agencyServicesData?.length) {
      agencyEditState.services.options = agencyServicesData;
      agencyFilterState.services.options = agencyServicesData;
    }
    if (agencyStatusesData?.length) {
      agencyEditState.status.options = agencyStatusesData;
      agencyFilterState.status.options = agencyStatusesData;
    }
  }, [agencyServicesData, agencyStatusesData]);

  const {
    isLoading,
    isEditing,
    isSearching,
    responseData,
    editData,
    isViewing,
    sortedValue,
    sortAscending,
    isCreating,
    searchData,
    displayingFilteredData,
    handleAdd,
    setIsViewing,
    setResponseData,
    setIsSearching,
    handleRowSelection,
    handleSorting,
    handleEditFormSubmit,
    handleCheckboxChange,
    handleEditInputChange,
    handleFilterInputChange,
    handleGoBack,
    handleResetSearch,
  } = useAdmin({
    editState: agencyEditState,
    filterState: agencyFilterState,
    handleSubmit: putAgency,
    user: user,
  });

  useEffect(() => {
    if (agencies.length && !statusesLoading) {
      setResponseData(
        agencies.map((agency: any) => {
          return {
            ...agency,
            statusName:
              agencyStatusesData?.find(
                (status: any) => status._id === agency?.status
              )?.name || "",
          };
        })
      );
      setIsViewing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies, agencyStatusesData, statusesLoading]);

  useEffect(() => {
    const { cellHeaders, cellData } = shapeCSVData(agencyHeaders, responseData);
    setExportData({ cellHeaders, cellData });
  }, [responseData]);

  // When creating a new agency set the status to the id for Active
  useEffect(() => {
    if (isCreating && agencyEditState.status.options.length) {
      const activeStatus = agencyEditState.status.options.find(
        (status: any) => status.name === "Active"
      );
      if (activeStatus) {
        agencyEditState.status.value = activeStatus._id;
      }
    }
  }, [isCreating]);

  // AutoComplete Logic
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const autoCompleteOptions = responseData
    .map((item: any) => ({
      _id: item._id || "",
      label: item.name || "",
    }))
    .sort((a: { label: string }, b: { label: any }) =>
      a.label.localeCompare(b.label)
    );

  const onAutoCompleteSelect = (option: any) => {
    setAutoCompleteValue(option.label);
    setSelectedOption(option);
  };

  const handleSearch = () => {
    setUserSearch(true);
    if (selectedOption) {
      const selectedRecord = responseData.find(
        (record: any) => record._id === selectedOption._id
      );
      if (selectedRecord) {
        setResponseData([selectedRecord]);
      }
    }
  };

  const handleFilterData = ({
    resetFilters = false,
  }: {
    resetFilters?: boolean;
  }) => {
    const filterParams: any = {};

    const allAgencies = agencies.map((agency: any) => {
      return {
        ...agency,
        statusName:
          agencyStatusesData?.find(
            (status: any) => status._id === agency?.status
          )?.name || "",
      };
    });

    if (resetFilters) {
      setSelectedOption(null);
      setAutoCompleteValue("");
      setResponseData(allAgencies);
      setUserSearch(false);
      return;
    }

    if (!resetFilters) {
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            filterParams[key] = value.value;
          }
        }
      );
    }

    const filteredData = [...allAgencies].filter((agency: any) => {
      const { name, status, state, services } = filterParams;
      return (
        (name === undefined ||
          agency.name.toLowerCase().includes(name.toLowerCase())) &&
        (status === undefined || agency.status === status) &&
        (state === undefined || agency.state === state) &&
        (services === undefined || agency.services.includes(services))
      );
    });

    setResponseData(filteredData);
    setIsSearching(false);
  };

  return (
    <div className="agency-page-container">
      {agenciesLoading && statusesLoading && <ConnectOneLoader />}
      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          label={"Agencies"}
          headers={agencyHeaders}
          sortGridData={handleSorting}
          exportData={exportData}
          handleRowSelection={handleRowSelection}
          actionButtonLabel={"Add New Agency"}
          handleActionButton={handleAdd}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
          autoCompleteOptions={autoCompleteOptions}
          onAutoCompleteSelect={onAutoCompleteSelect}
          autoCompleteLabel={"Agency"}
          autCompletePlaceholder={"Select an Agency"}
          description="List of participating agencies provided by TollSmart."
          handleSearch={handleSearch}
          advancedSearchLabel={"Advanced"}
          handleAdvancedSearch={() => setIsSearching(true)}
          resetButtonLabel={"Clear Search"}
          handleReset={
            displayingFilteredData || userSearched
              ? () => {
                  handleResetSearch();
                  handleFilterData({ resetFilters: true });
                }
              : undefined
          }
          searchButtonDisabled={!autoCompleteValue}
        />
      )}

      {isSearching && (
        <AgenciesAdvancedSearch
          searchData={searchData}
          isLoading={isLoading}
          handleChange={handleFilterInputChange}
          handleClose={() => setIsSearching(false)}
          handleSubmit={handleFilterData}
        />
      )}

      {isEditing && (
        <AgenciesEditForm
          data={editData}
          onClose={handleGoBack}
          handleSubmit={handleEditFormSubmit}
          handleCheckbox={handleCheckboxChange}
          handleChange={handleEditInputChange}
          isLoading={isLoading}
          isCreating={isCreating}
        />
      )}
    </div>
  );
};
